import React,{useEffect,useState} from 'react'
import './Feed.css';
import DataBox from '../DataBox/DataBox';
import EventsTable from '../EventsTable/EventsTable';
import StaleMasjidTable from '../StaleMasjidTable/StaleMasjidTable';
import { Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {getEvents} from '../../../Redux/Actions/EventsActions/FetchingEvents.js'
import {getMasjids} from '../../../Redux/Actions/MasjidActions/FetchingMasjids.js'
import {getAdminMasjids} from '../../../Redux/Actions/MasjidActions/FetchingMasjidByAdmin.js'
import {getStaleMasjids} from '../../../Redux/Actions/MasjidActions/FetchingStaleMasjids.js'
import {FetchingAdmins} from '../../../Redux/Actions/AdminActions/FetchingAdmins.js'
import { useDispatch } from 'react-redux';
import AllStaleMasjidTable from '../../../Components/PageComponents/AllStaleMasjidTable/AllStaleMasjidTable';
import EventsCarousel from '../../../Components/FeedComponents/EventsCarousel/EventsCarousel';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import {FetchingAdminByName} from '../../../Redux/Actions/AdminActions/FetchingAdminByName.js';
import {FetchingLatestUpdatedEvents} from '../../../Redux/Actions/EventsActions/FetchingLatestUpdatedEvents.js'
import {FetchingLatestUpdatedEventsById} from '../../../Redux/Actions/EventsActions/FetchingLatestUpdatedEventsById.js'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const Feed = () => {

   const masjids  = useSelector((state) => state.masjids);
   const events  = useSelector((state) => state.events);
   const admins  = useSelector((state) => state.allAdmins);
   const staleMasjids  = useSelector((state) => state.staleMasjids);
   const currentAdmin  = useSelector((state) => state.currentAdmin);
   const AdminMasjids  = useSelector((state) => state.AdminMasjids);
   const [StaleMasjids, setStaleMasjids] = useState([])
   const [AdminEvents, setAdminEvents] = useState([])
   const [Admin, setAdmin] = useState([])
   const [Table, setTable] = useState(false)
   const [Loading, setLoading] = useState(false)
   const dispatch = useDispatch();
   const admin = JSON.parse(localStorage.getItem("admin"));
   const LatestEvents  = useSelector((state) => state.LatestEvents);
   const LatestAdminEvents  = useSelector((state) => state.LatestAdminEvents);



     useEffect(() => {
    
      if( admin.role ==="superadmin" || admin.role === "dataentry"){
       masjids.length === 0 && dispatch(getMasjids("createdAt","asc"));
      }else{
        console.log(AdminMasjids);
        AdminMasjids.length === 0 && dispatch(getAdminMasjids(admin._id));
      }

      events.length === 0 && dispatch(getEvents());
      admins.length === 0 && dispatch(FetchingAdmins()); 
      staleMasjids.length === 0 && dispatch(getStaleMasjids("updatedAt","asc")); 
    }, [])


    useEffect(() => {
      const response =  dispatch(FetchingAdminByName(admin?.adminName));
      response.then(function(result) {
      if(!result.success){
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:result.message ? "Failed to Load Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
          }
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        }
        else{
         setAdmin(result.data[0]);
         let masjidDatas =  masjids.filter(item =>  result.data[0].masjids?.includes(item.masjidName));
         setStaleMasjids(masjidDatas);
         handleGetAdminEvents();
         }
       });
      }, []);


      useEffect(() => {
         if(masjids.length>0){
            let masjidDatas =  masjids.filter(item =>  Admin?.masjids?.includes(item.masjidName));
            setStaleMasjids(masjidDatas)
         }
      }, [Admin,masjids])



      const handleChangeOnClik = () => {
         setTable(!Table)
      }


      const handleGetAdminEvents = () => {
        setLoading(true);
       if( admin.role ==="superadmin" || admin.role === "dataentry"){
          const response =  dispatch(FetchingLatestUpdatedEvents());
         
          response.then(function(result) {
          if(!result.success){
            setLoading(false);
              }
              else{
                setLoading(false);
                setAdminEvents(result.data);
              }
              });
        }else{
          const response =  dispatch(FetchingLatestUpdatedEventsById(admin._id));
          response.then(function(result) {
            
          if(!result.success){
                setLoading(false);
              }
              else{
                setLoading(false);
               setAdminEvents(result.data);
               }
             });
        }
      }

  return (
    <>
       <div className="FeedCenterContainer">

      { admin.role ==="superadmin" || admin.role === "dataentry" ?(
          <>
         <div className="FeedUpperSection">
           <div className="FeedNumberData">
              <DataBox data ={ masjids } name={"Masjid"}/>
              <DataBox data ={ events } name={"Events"}/>
              <DataBox data ={ admins } name={"Admins"}/>
           </div>
           {
                  Loading ? (
                 <> 
                 <div className="skeletonCarousel">
                  <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div>
                   <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div>
                   <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div> 
                   <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div> 
                   </div>
                  </>
                  ):(
                    <> 
                      <div className="FeedEventsTable">
                        <EventsCarousel Events={AdminEvents}/>
                      </div>    
                    </>
                  )}
          </div>
          <div className="FeedLowerSection">
          <div className="FeedMasjidTable">
            <StaleMasjidTable masjids={staleMasjids} />
          </div>
          </div>
          </>
         ):
         (
          <>
                <div className={AdminEvents.length>0 || Loading ? "FeedUpperSection":"FeedUpperSectionSubAdmin"}>
                  {
                  Loading ? (
                 <> 
                 <div style={{display:'flex',flexDirection:'column'}}>
                 <h3>Fetching Events</h3>
                 <div className="skeletonCarousel">
                  <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div>
                   <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div>
                   <div className="SkeletonContainer">
                    <Stack spacing={1}>
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton variant="rectangular" width={210} height={60} />
                      <Skeleton variant="rounded" width={210} height={60} />
                    </Stack>
                   </div> 
                   </div>
                 </div>
                  </>
                  ):(
                    <> 
                    <div className="FeedEventsTableSubAdmin">
                        <EventsCarousel  Events={LatestAdminEvents}/>
                      </div>       
                    </>
                  )}
                </div>
               <div className="FeedLowerSection">
                <div className="FeedMasjidTable">
                <h5 style={{ marginLeft:'2px'}}>Stale Masjids</h5>
                 <StaleMasjidTable masjids={AdminMasjids} Admin={Admin} /> 
               </div>
              </div>
          </>
         )
        }
       </div>
    </>
  )
}

export default Feed