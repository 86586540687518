import { combineReducers } from 'redux'
import FetchMasjidReducer from './MasjidReducers/FetchMasjidReducer';
import FetchStaleMasjidReducer from './MasjidReducers/FetchStaleMasjidReducer';
import FetchMasjidByNameReducer from './MasjidReducers/FetchMasjidByNameReducer';
import FetchMasjidsByAdminReducer from './MasjidReducers/FetchMasjidsByAdminReducer';
import FetchMasjidEventReducer from './EventReducers/FetchMasjidEventReducer';
import FetchLatestEventsByAdminReducer from './EventReducers/FetchLatestEventsByAdminReducer';
import FetchEventReducer from './EventReducers/FetchEventReducer';
import AddMasjidReducer from './MasjidReducers/AddMasjidReducer';
import AddEventReducer from './EventReducers/AddEventReducer';
import FetchLatestEventsReducer from './EventReducers/FetchLatestEventsReducer';
import AddTimingReducer from './TimingReducers/AddTimingReducer';
import UpdateMasjidReducer from './MasjidReducers/UpdateMasjidReducer';
import UpdateEventReducer from './EventReducers/UpdateEventReducer';
import UpdateNamazReducer from './TimingReducers/UpdateNamazReducer';
import UpdateTimingReducer from './TimingReducers/UpdateTimingReducer';
import LoginReducer from './AuthReducers/LoginReducer';
import FetchingAdminReducer from './AdminReducers/FetchingAdminReducer';
import FetchingAdminByNameReducer from './AdminReducers/FetchingAdminByNameReducer';
import SidebarOpenReducer from './SidebarReducer/SidebarOpenReducer';
import ChangeSnackbarReducer from './SnackbarReducer/ChangeSnackbarReducer';
import FetchingTimingByMasjidReducer from './TimingReducers/FetchingTimingByMasjidReducer';
import FetchingTimingsByDateReducer from './TimingReducers/FetchingTimingsByDateReducer';


const indexReducer = combineReducers({
      masjids:FetchMasjidReducer,
      staleMasjids:FetchStaleMasjidReducer,
      events:FetchEventReducer,
      AdminMasjids:FetchMasjidsByAdminReducer,
      LatestEvents:FetchLatestEventsReducer,
      LatestAdminEvents:FetchLatestEventsByAdminReducer,
      masjidEvents:FetchMasjidEventReducer,
      newMasjid:AddMasjidReducer,
      newEvent:AddEventReducer,
      updatedMasjid:UpdateMasjidReducer,
      updatedEvent:UpdateEventReducer,
      admin:LoginReducer,
      allAdmins:FetchingAdminReducer,
      currentAdmin:FetchingAdminByNameReducer,
      masjid:FetchMasjidByNameReducer,
      sidebarState:SidebarOpenReducer,
      masjidTimings:FetchingTimingByMasjidReducer,
      snackBarState:ChangeSnackbarReducer,
      masjidTime:AddTimingReducer,
      updatedNamaaz:UpdateNamazReducer,
      updatedTiming:UpdateTimingReducer,
      dateTimings:FetchingTimingsByDateReducer,
  })


export default indexReducer