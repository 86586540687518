import { FETCH_ADMIN_BY_NAME } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const FetchingAdminByName = (adminName) => async(dispatch) => {

    try{

      const { data } = await api.getAdminByName(adminName);

      if(data.success)
      {
         dispatch({type:"FETCH_ADMIN_BY_NAME" , payload: data.data});

         return data;
       }
     return data;
      

    }    
    catch(error)
   {
    const snackbarFailureDetails = {
        snackbarOpen:true, 
         snackbarType:"error",
         snackbarMessage:error.response.data ?error.response.data.message:"Fetching Masjid Failed",
       }
  
      dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      return error.response.data;

   }
}