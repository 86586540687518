import { DELETE_MASJID } from '../../ActionType';
import * as api from '../../../APICalls/index.js';


export const FreezeMasjid = (id) => async(dispatch) => {


    try{

      const { data } = await api.freezeMasjid(id);

      if(data.success)
      {
         return data;
       }
     return data;
    }    
    catch(error)
   {
      return error.response.data;

   }
}