import React,{useState} from 'react'
import './FileUploadMasjid.scss'
import axios from 'axios'
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {ChangeSnackbar} from '../../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import imageCompression from 'browser-image-compression';
import { AiOutlineCloseCircle } from "react-icons/ai";
const API = axios.create({ baseURL: "https://droplet.connectmazjid.com/"});

API.interceptors.request.use(async (req) => {
  if (localStorage.getItem('authTokens')) {
    const token  = JSON.parse(localStorage.getItem('authTokens'));
      if(token.accessToken){
          req.headers.Authorization = `Bearer ${token.accessToken}`
        }
        else{
            req.headers.Authorization = `Bearer ${token.token}`
        }
    }
    const data = JSON.parse(localStorage.getItem('authTokens'));    
    return req;
  },
   error => {
    return Promise.reject(error);
   }
  );




export const FileUploadMasjid = ({MasjidId,setMasjidPhotos,MasjidPhotos,UploadAllow}) => {

    const [Open, setOpen] = useState(true)
    const [OpenBar, setOpenBar] = useState(false)
    const [ImageUploadMedium, setImageUploadMedium] = useState(false)
    const [selectedImage, setSelectedImage] = useState();
    const dispatch = useDispatch();
    const [State, setState] = useState({ uploadPercentage: 0});
   
     
    const ImageCompression = async () =>  {
      console.log(selectedImage);
      const imageFile = selectedImage;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        await uploadHandler(compressedFile); 
      } catch (error) {
             const snackbarFailureDetails = {
            snackbarOpen:true, 
             snackbarType:"warning",
             snackbarMessage:"Failed to Compress the Uploaded Image",
           }
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      }
    };

    const uploadHandler = (compressedFile) => {  
        const formData = new FormData();
        formData.append("image", compressedFile);
        setOpenBar(true);

        const options = {
          onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor( (loaded * 100) / total )
            if( percent < 100 ){
              setState({ uploadPercentage: percent })
            }
          }
        }

          API.post("v1/admin/masjid/addMasjidMedia/"+ MasjidId, formData, options).then(res => { 
            
            setState({ uploadPercentage: 100 }, ()=>{
              setTimeout(() => {
                setState({ uploadPercentage: 0 })
              }, 700);
            });

            setTimeout(() => {
              setMasjidPhotos(res.data.data.masjidPhotos);
              setOpenBar(false);
              setOpen(!Open);
              setSelectedImage();
            }, 3000);
        }).catch(error => {
          const snackbarFailureDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:error.response.data ?error.response.data.message:"Adding Masjid Media Failed",
           }
           setOpenBar(false);
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        });
    }
   

    const imageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
          setSelectedImage(e.target.files[0]);
          setOpen(!Open);
      }
    };
  
    const removeSelectedImage = () => {
      setSelectedImage();
      setOpen(!Open);
    };
 
    
    const getJpegFromUrl = async (url) => {
      const fetchUrl = url
      const fileName = 'myFile.jpeg'
      fetch(fetchUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], fileName , {
            type: blob.type,
          },  { contentType });
          setSelectedImage(file);
          setOpen(!Open);

        })
    }
 
    
 function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
  
 const handleUrlUpload = (e) => {
    if(isValidHttpUrl(e.target.value)){
       getJpegFromUrl(e.target.value)
      }
  }


    return (
        <>
      {
        Open ? (
        
            <div className="file-card">
             <div className="file-inputs">
                 { !ImageUploadMedium ? (
                 <> 
                 <div className="ImageUploadButtons">
                    <Button variant="outlined" disabled={UploadAllow}  style={{ marginBottom:"5px",marginTop:"5px",width:"100%",height:"30px",fontSize:"10px"}} component="label" startIcon={<PhotoCamera />}  onChange={imageChange}  >
                     Choose New  Image  
                    <input hidden accept="image/*" multiple type="file" />
                    </Button>
                    <span className="Separator">OR</span>
                    <Button variant="contained" disabled={UploadAllow}  style={{ marginBottom:"5px",marginTop:"5px",width:"100%",height:"30px",fontSize:"10px"}} component="label" onClick={(e) => setImageUploadMedium(true)}>
                       Paste Link  
                    </Button>
                 </div>
                 </>):(
                  <>
                <div className="PasteBoxForLink">
                 <input type="url" id="url-field" disabled={UploadAllow} pattern="^https?://.*$" autoFocus="true" className="InputPasteLink" onChange={(e) => { handleUrlUpload(e)}}/> <AiOutlineCloseCircle className="TextBoxRightIcon" onClick={(e)=> setImageUploadMedium(false)}/>
                </div>
                </> 
                 )}
                 <div className="ImageUploadBottomDetails">
                    { UploadAllow ? <> 
                      <p className="info"> The Masjid has reached Maximum File Upload Limit</p>
                      </> 
                      : <>
                       <p className="main">Supported files</p>
                      <p className="info"> JPG, PNG,JPEG less than 5MB</p>
                       </>}
                 </div> 
                </div>
             </div>
        ) :(
            selectedImage && (
              <>
           {OpenBar &&  <div className="progressbar-container">
                <div className="progressbar-complete" style={{width: `${State.uploadPercentage}%`}}>
                  <div className="progressbar-liquid"></div>
                </div>
                <span className="progress">{State.uploadPercentage}%</span>
              </div>}
                <div className="ImageContainer">
                 <div className="EventImagePreview">
                    <img src={URL.createObjectURL(selectedImage)} className="EventImage" alt="Thumb" />
                 </div>
                </div>
                <div className="ImageButtons">
                    <Button variant="outlined"onClick={removeSelectedImage}  className ="EventImageDelete">
                     Remove Image
                    </Button>
                    <Button  variant="outlined" onClick={ImageCompression} disabled = {OpenBar} className ="EventImageDelete">
                     Upload Image
                    </Button>
                  </div>
               </>
          )
        )
         
       }
     </>
    )
}

