import { ADD_TIMING } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const addTiming = (formData) => async(dispatch) => {


    try{
      const { data } = await api.addTimings(formData);

      dispatch({type:"ADD_TIMING" , payload: data.data});


      return data;
    }    
    catch(error)
   {
    
     if(error.message){
      const snackbarDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:`Failed To Add Timings: ${error}`
       }  
       setTimeout(() => {
          
          dispatch(ChangeSnackbar(snackbarDetails));
       }, 1000);  
     }
      return error.response.data;

   }
}