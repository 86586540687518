import { FETCH_EVENTS } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const getEvents = () => async(dispatch) => {


    try{
    
      const { data } = await api.fetchEvents();
   
      if(data.success)
      {
        dispatch({type:"FETCH_EVENTS" , payload: data.data})
      
        return data.data;
      }
      return data.data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
         snackbarType:"error",
           snackbarMessage:"Couldn't add the event : " + error.message
          }       

      dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      
      return error.response.data;

   }
}