import React,{useState,useRef,useEffect} from 'react';
import './EventsComponent.scss';
import Button from '@material-ui/core/Button';
import moment from "moment";
import { DatePicker as SingleDatePicker } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useDispatch } from 'react-redux';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import { Badge } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import {DemoData} from './DemoContent.js'
import EventCard from './EventCard.jsx'
import {FetchEventByMasjidId} from '../../../Redux/Actions/EventsActions/FetchingEventByMasjidId.js'
import {addNewEvent} from '../../../Redux/Actions/EventsActions/AddingEvents.js'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { DateTimePicker as SingleDateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import { KeyboardTimePicker } from "@material-ui/pickers";
import { TimePicker as SingleTimePicker } from "@material-ui/pickers";
import makeStyles from "@material-ui/styles/makeStyles";
import  { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import DatePicker  from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useNavigate } from "react-router-dom";
import { AiTwotoneDelete } from "react-icons/ai";

const useStyles = makeStyles({
    root: {
      "& .MuiInputBase-root": {
        "& .MuiButtonBase-root": {
          padding: 0,
        }
      }
    }
  });

const EventsComponent = ({Masjid}) => {
 
    const [valueDate, setValueDate] = useState(new Date());
    const [IsAdding, setIsAdding] = useState(false);
    const [MasjidEventsData, setMasjidEventsData] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [OpenAddEventModal, setOpenAddEventModal] = useState(false);
    const [selectedStartDate,setSelectedStartDate] = useState(new Date());
    const [selectedEndDate,setSelectedEndDate] = useState( new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());
    const [DefaultEventAddress, setDefaultEventAddress] = useState(null);
    const [DefaultLatitude, setDefaultLatitude] = useState(null);
    const [DefaultLongitude, setDefaultLongitude] = useState(null);
    let NewEventNameRef = useRef(null);
    let NewEventDescriptionRef = useRef(null);
    let NewEventAddressRef = useRef(null);
    let LatitudeRef = useRef(null);
    let LongitudeRef = useRef(null);
    const [RecurrenceType, setRecurrenceType] = React.useState('');
    const [EventNameError, setEventNameError] = useState(false);
    const [EventAddressError, setEventAddressError] = useState(false);
    const [EventLatitudeError, setEventLatitudeError] = useState(false);
    const [EventLongitudeError, setEventLongitudeError] = useState(false);
    const [EventDescError, setEventDescError] = useState(false);
    const today =   new Date();
    const endNextMonth = moment().add(1, 'M').endOf("month").format('YYYY-MM-DD');
    let navigate = useNavigate();
    const [value, setValue] = useState([])
    const [values, setValues] = useState('');

    const handleChange = (event) => {
      setRecurrenceType(event.target.value);
    };
  
    const handleAddEventModalOpen = () => {
        setOpenAddEventModal(true);
    }



    const handleAddEventNetworkCall = (formData) => {
      setIsAdding(true);
      const data =   dispatch(addNewEvent(formData,navigate));
      data.then(function(result) {
        if(result?.success){
          setIsAdding(false);
          // setMasjidEventsData(result.data);
        }
        else{
            setIsAdding(false);
           }
        })
        setIsAdding(false);

    }



    const handleAddEvent = () => {
    
      if(!NewEventNameRef.current?.value){
        setEventNameError(true);
      }else if(NewEventNameRef.current?.value){
        setEventNameError(false);
      }

      if(!NewEventDescriptionRef.current?.value){
        setEventDescError(true);
      }else if(NewEventDescriptionRef.current?.value){
        setEventDescError(false);
      }
     
      if(!NewEventAddressRef.current?.value  ){
        setEventAddressError(true);
      }else if(NewEventAddressRef.current?.value){
        setEventAddressError(false);
      }
  
      if(!LatitudeRef.current?.value){
        setEventLatitudeError(true);
      }else if(LatitudeRef.current?.value){
        setEventLatitudeError(false);
      }
  
      if(!LongitudeRef.current?.value){
        setEventLongitudeError(true);
      }else if(LongitudeRef.current?.value){
        setEventLongitudeError(false);
      }
      
      let coordinates = [parseFloat(LatitudeRef.current?.value),parseFloat(LongitudeRef.current?.value)];
      let timings;


      if( RecurrenceType === "None" ){
        timings = [{
          startDate:moment(selectedStartDate).format('MM/DD/YYYY') + "Z",
          startTime:moment(selectedStartTime).unix(),
          endTime:moment(selectedEndTime).unix(),
          endDate:moment(selectedEndDate).format('MM/DD/YYYY') + "Z",
        }]

      }else if(RecurrenceType === "Daily"){
        timings = [{
          startDate: values[0].format('MM/DD/YYYY')+ "Z",
          startTime:moment(selectedStartTime).unix(),
          endTime:moment(selectedEndTime).unix(),
          endDate:values[1].format('MM/DD/YYYY')+ "Z",
        }]
      }else{
        let MultipleTimings = [];
        value.map((item,key) => {
           let time = {
              startDate: value[key].format('MM/DD/YYYY')+ "Z",
              startTime:moment(selectedStartTime).unix(),
              endTime:moment(selectedEndTime).unix(),
              endDate:value[key].format('MM/DD/YYYY')+ "Z",
           }

           MultipleTimings.push(time);
        })
        timings =[
          ...MultipleTimings
        ]
      }

      let updatedData = {
        eventName:NewEventNameRef.current?.value,
        address:NewEventAddressRef.current?.value,
        masjidId:Masjid._id,
        masjidName:Masjid.masjidName,
        description:NewEventDescriptionRef.current?.value,
        location: { "type": "Point","coordinates": [coordinates[1],coordinates[0]]},
        timings:timings
      } 

        if(coordinates[0] && coordinates[1] && NewEventNameRef.current?.value &&  NewEventAddressRef.current?.value &&  NewEventDescriptionRef.current?.value && timings.length>0){
          handleAddEventNetworkCall(updatedData);
        }
         else{
          const snackbarDetails = {
            snackbarOpen:true, 
              snackbarType:"warning",
             snackbarMessage:"Please enter correct details before trying again"
           }   
           dispatch(ChangeSnackbar(snackbarDetails)); 
         }
    }



    const handleAddEventModalClose = () => {
       setEventNameError(false);
       setEventAddressError(false);
       setEventLatitudeError(false);
       setEventLongitudeError(false); 
       setOpenAddEventModal(false);
    }

     
    useEffect(() => {
        if(Masjid._id){
          setDefaultEventAddress(Masjid.address);
          setDefaultLatitude( Masjid.location.coordinates[1]);
          setDefaultLongitude(Masjid.location.coordinates[0])

            const data =   dispatch(FetchEventByMasjidId(Masjid._id));
            data.then(function(result) {
                if(result.success){
                    setMasjidEventsData(result.data);
                }
                else{
                setMasjidEventsData([]);
               }
            })
        }
   }, [Masjid]);




    return (
        <div className="TableHeadContainerComponent">
            <div className="TopEventsTableComponent">
               <div className="TopEventsTableLeft">
                </div>
               <div className="TopEventsTableRight">
               <div className="PoppupAuth">
                </div>
                 <div className="EventsCalendarComponent">
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    format="MM/dd/yyyy"
                    allowKeyboardControl={true}
                    value={valueDate}
                    inputVariant="outlined"
                    //   onMonthChange={(item) => {
                        //     handleMonthChange(item);
                    //   }}
                    onChange={(newValue,isInCurrentMonth) => {
                        setValueDate(newValue);
                    }}
                    //    renderDay={(day, selectedDate, isInCurrentMonth, dayComponent,days) => {
                    //     // const isSelected =  isInCurrentMonth && SelectedDays?.includes(day.getDate());
                    //     // const isIncompleteSelected =  isInCurrentMonth && FirstMonthIncompleteDays?.includes(day.getDate());
                    //     // const isIncomplete =  isInCurrentMonth && SelectedIncompleteDays?.includes(day.getDate());
                    //     // ✔
                    //   //   return <Badge key={day.toString()} overlap="'circular'" badgeContent={isSelected ?  ( isIncomplete ? <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "orange"}} /> : <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "green"}} />) : " " }>
                    //   //     {dayComponent}
                    //   //     </Badge>;
                    //   // }}
                    //   return <Badge  key={day.toString()} overlap="'circular'"  badgeContent={isSelected ?( isIncompleteSelected ? <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "orange"}} /> : <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "green"}} />) : undefined}>{dayComponent}</Badge>;
                    //    }}
                    sx={{width: '50%'}} />
               </div>

               <button className="EventAdditionButton" onClick={handleAddEventModalOpen}  >
                <span class="text">
                    {IsAdding ? (
                        <CircularProgress  size="20px" />
                        ) : ("Add Event")
                    }
                </span>
               </button>
              </div>
            </div>
            <Dialog open={OpenAddEventModal} onClose={handleAddEventModalClose} height={"xl"} >
                  <DialogTitle>Add Event</DialogTitle>
                  <span style={{ fontSize:'12px',marginLeft:'25px',color:'orange'}}>The Address and Coordinates are prefilled according to the masjid details</span>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      autoComplete="Off"
                      id="name"
                      placeholder="Enter the Event Name"
                      onChange ={ (e) => {
                        if(EventNameError){
                          if(!NewEventNameRef.current?.value){
                            setEventNameError(true);
                          }else if(NewEventNameRef.current?.value){
                            setEventNameError(false);
                          }
                        }
                      }}
                      error={EventNameError}
                      helperText={EventNameError?"Manditory field":""}
                      sx={{  marginBottom: 3}}
                      type="text"
                      fullWidth
                      inputRef={NewEventNameRef}
                      variant="outlined"/>

                      <TextField
                     id="outlined-required"
                     variant="outlined"
                     fullWidth
                     multiline
                     onChange ={ (e) => {
                      if(EventDescError){
                        if(!NewEventDescriptionRef.current?.value){
                          setEventDescError(true);
                        }else if(NewEventDescriptionRef.current?.value){
                          setEventDescError(false);
                        }                  
                      }
                    }}
                     placeholder="Enter the Event Description"
                     rows={4}
                     error={EventDescError}
                     helperText={EventDescError?"Manditory field":""}
                     inputRef={NewEventDescriptionRef}
                     type="text"
                     autoComplete="Off"
                     sx={{  marginBottom: 3,height:'50%' }}/> 

                    <TextField
                      id="outlined-required"
                      variant="outlined"
                      autoComplete="Off"
                      placeholder="Enter the Event Address"
                      onChange ={ (e) => {
                        if(EventAddressError){
                          if(!NewEventAddressRef.current?.value){
                            setEventAddressError(true);
                          }else if(NewEventAddressRef.current?.value){
                            setEventAddressError(false);
                          }                  
                        }
                      }}
                      error={EventAddressError}
                      helperText={EventAddressError?"Manditory field":""}
                      inputRef={NewEventAddressRef}
                      defaultValue={DefaultEventAddress}
                      fullWidth
                      sx={{  marginBottom: 3 }}
                      type="text"/> 

   
                  <TextField
                    id="outlined-required"
                    autoComplete="Off"
                    variant="outlined"
                    error={EventLatitudeError}
                    helperText={EventLatitudeError?" Manditory field":" "}
                    placeholder="Enter the Event Latitude"
                    onChange ={ (e) => {
                      if(EventLatitudeError){
                        if(!LatitudeRef.current?.value){
                          setEventLatitudeError(true);
                        }else if(LatitudeRef.current?.value){
                          setEventLatitudeError(false);
                        }
                      }
                    }}
                    inputRef={LatitudeRef}
                    defaultValue={DefaultLatitude}
                    type="number"
                    sx={{  marginBottom: 3}}/> 
                  
                    <TextField
                      id="outlined-required"
                      autoComplete="Off"
                      error={EventLongitudeError}
                      helperText={EventLongitudeError? "Manditory field":" "}
                      inputVariant="outlined"
                      onChange ={ (e) => {
                        if(EventLongitudeError){
                          if(!LongitudeRef.current?.value){
                            setEventLongitudeError(true);
                          }else if(LongitudeRef.current?.value){
                            setEventLongitudeError(false);
                          }
                        }
                      }}
                      placeholder="Enter the Event Longitude"
                      inputRef={LongitudeRef}
                      defaultValue={DefaultLongitude}
                      type="number"
                      sx={{  marginBottom: 3 , marginLeft: 8}}/> 

                     
                <FormControl fullWidth sx={{marginBottom:5}}>
                    <InputLabel id="demo-simple-select-label">Recurrence</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={RecurrenceType}
                          defaultValue={'None'}
                          placeholder="Choose The Recurrence type"
                          onChange={handleChange}>
                          <MenuItem value={'RandomDates'}>Choose Random Dates</MenuItem>
                          <MenuItem value={'Daily'}>Daily</MenuItem>
                          <MenuItem value={'None'}>None</MenuItem>
                        </Select>
                      </FormControl>
                      
                      {
                        RecurrenceType === "None" && (
                       <SingleDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        minDate={new Date()}
                        sx={{  marginBottom: 5,marginTop:3}}
                        label="Event Start Date "
                        fullWidth
                        value={selectedStartDate}
                        onChange={setSelectedStartDate}/>
                        )
                      }

                     {
                        RecurrenceType === "RandomDates" && (
                          <DatePicker  
                          value={value}
                          onChange={setValue}
                          rows={4}
                          placeholder="Pick the dates of  recurrence"
                          style={{ width:'35vw',height:'3rem' }}
                          minDate={new Date()}
                          // fullWidth
                          autoComplete="Off"
                          format="MM/DD/YYYY"
                          multiple
                          plugins={[
                            <DatePanel markFocused />,
                          ]} /> 
                          )
                      }

                     {
                        RecurrenceType === "Daily" && (
                          <DatePicker  
                          value={values}
                          onChange={setValues}
                          rows={4}
                          range
                          minDate={new Date()}
                          // fullWidth
                          placeholder="Select the range for recurrence"
                          style={{ width:'35vw',height:'3rem' }}
                          autoComplete="Off"
                          format="MM/DD/YYYY"
                          multiple
                          plugins={[
                            <DatePanel markFocused />,
                          ]} /> 
                          )
                      }
 

                    <div className="TimePickerCoverContainer">
                     <div className="TimepickerCoverLeft">
                      <SingleTimePicker 
                        autoOk
                        label="Start time"
                        className={classes.root}
                        sx={{  marginBottom: 3 }}
                        value={selectedStartTime}
                        onChange= {(newValue) => {
                          setSelectedStartTime(newValue);
                        }} />
                    </div>

                    <div className="TimepickerCover">
                    <SingleTimePicker 
                      autoOk
                      label="End Time"
                      className={classes.root}
                      sx={{  marginBottom: 3 }}
                      value={selectedEndTime}
                      onChange={(newValue) => {
                        setSelectedEndTime(newValue);
                      }}/> 
                     </div>
                   </div>

                   {
                        RecurrenceType === "None" && (
                       <SingleDatePicker
                        disableToolbar
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        minDate={new Date()}
                        sx={{  marginBottom: 5,marginTop:3}}
                        label="Event End Date "
                        // fullWidth
                        value={selectedEndDate}
                        onChange={setSelectedEndDate}/>
                        )
                      }

                  </DialogContent>
                   <DialogActions>
                     <Button onClick={handleAddEventModalClose}  style={{ color:"grey"}} >Cancel</Button>
                     <Button onClick = {handleAddEvent}> Add </Button>
                   </DialogActions>
                </Dialog>
            <div className="EventsCarouselContainer">
              { MasjidEventsData.length>0 && MasjidEventsData.map((item,key) => {
              return (
                <>
                <EventCard item={item} setMasjidEventsData={setMasjidEventsData} MasjidEventsData={MasjidEventsData}/>
                </>)
              })}
            </div>
        </div>
    )
}

export default EventsComponent
