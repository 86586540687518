import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const UnFreezingMasjidById = (id) => async(dispatch) => {

  try{

    const { data } = await api.unfreezeMasjid(id);

    if(data.success)
    {
      const snackbarDetails = {
          snackbarOpen:true, 
           snackbarType:"success",
           snackbarMessage:"Unfreezed Masjid",
         }
    
        dispatch(ChangeSnackbar(snackbarDetails));
       return data;
     }
   return data;
  }    
  catch(error)
 {
    const snackbarFailureDetails = {
       snackbarOpen:true, 
        snackbarType:"error",
        snackbarMessage:error.response.data ?error.response.data.message:"Unfreezing Admin Failed",
      }
 
     dispatch(ChangeSnackbar(snackbarFailureDetails)); 
    console.log(error);

 }
}