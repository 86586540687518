import { CHANGE_SNACKBAR } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
  
  export const ChangeSnackbar = (snackbarDetails) => async(dispatch) => {
   

      try{
  
        dispatch({type:"CHANGE_SNACKBAR" , payload: snackbarDetails})
        
      }    
      catch(error)
     {
      return error.message;
  
     }
  }