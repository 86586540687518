import { ADD_EVENT } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import { Navigate } from 'react-router-dom';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const addNewEvent = (formData,navigate) => async(dispatch) => {


    try{

      const { data } = await api.addEvent(formData);
     
      if(data.success)
      {

        dispatch({type:"ADD_EVENT" , payload: data.data})
        
        navigate("/events/"+ data.data._id);

        return data;
      }

      return data;

    }    
    catch(error)
   {
        const snackbarFailureDetails = {
         snackbarOpen:true, 
         snackbarType:"error",
           snackbarMessage:"Couldn't add the event : " + error.message
          }       

        dispatch(ChangeSnackbar(snackbarFailureDetails)); 

      return error.response.data;


   }
}