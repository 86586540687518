import React,{useState,useEffect,useRef} from 'react'
import "./EventProfile.scss";
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';
import PictureCarouselComponent from '../../../Components/ProfileComponents/PictureCarouselComponent/PictureCarouselComponent.jsx';
import { useParams } from "react-router-dom";
import {useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import {FetchEventById} from '../../../Redux/Actions/EventsActions/FetchingEventById.js';
import {addNewEventGuest} from '../../../Redux/Actions/EventsActions/AddingEventGuests.js';
import {deleteEventGuest} from '../../../Redux/Actions/EventsActions/DeleteingEventGuest.js';
import {updateEventGuest} from '../../../Redux/Actions/EventsActions/UpdatingEventGuest.js';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DatePicker  } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";
import makeStyles from "@material-ui/styles/makeStyles";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import * as api from '../../../APICalls/index.js';
import { TiUserAdd } from "react-icons/ti";
import { TiTimes } from "react-icons/ti";
import { TiPencil } from "react-icons/ti";
import FileUploadEvent from '../../../Components/ProfileComponents/ImageUploadComponents/FileUploadEvent/FileUploadEvent.jsx';
import { GrMapLocation } from "react-icons/gr";
import { BsFillPencilFill } from "react-icons/bs";
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@material-ui/core";
import {EventUpdateNotification} from '../../../Redux/Actions/NotificationActions/EventUpdateNotification.js';
import imageCompression from 'browser-image-compression';



const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiButtonBase-root": {
        padding: 0,
      }
    }
  }
});


const EventProfile = (props) => {

    let newEvent = useSelector((state) => state.newEvent);
    const { eventName } = useParams();
    const dispatch = useDispatch();
    const [Event, setEvent] = useState([]);
    const sidebarState = useSelector((state) => state.sidebarState);
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();
    const [AddGuest,setAddGuest] = useState(false);
    const [Guest, setGuest] = useState('')
    const [loading, setLoading] = useState(false);
    const [DeleteLoading, setDeleteLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState();
    const [EventProfileImage, setEventProfileImage] = useState('');
    const [EditProfilePictureOpen, setEditProfilePictureOpen] = useState(false);
    const [isFetching, setisFetching] = useState(false);
    const [UploadAllow, setUploadAllow] = useState(true);
    const [IsUpdating, setIsUpdating] = useState(false);
    const [UpdateModalOpen, setUpdateModalOpen] = useState(false);
    const [CompressedImage, setCompressedImage] = useState('');

    // states for loading the event data
    const [selectedStartDate,setSelectedStartDate] = useState(new Date());
    const [selectedEndDate,setSelectedEndDate] = useState( new Date());
    const [selectedStartTime, setSelectedStartTime] = useState(new Date());
    const [selectedEndTime, setSelectedEndTime] = useState(new Date());
    const [Address, setAddress] = useState('');
    const [Name, setName] = useState('');
    const [desc, setDesc] = useState('');
    const [Latitude, setLatitude] = useState('');
    const [Longitude, setLongitude] = useState('');

    // states for the modal opening and closing
    const [EditingGuest, setEditingGuest] = useState('');
    const [GuestOpen, setGuestOpen] = React.useState(false);
    const [EditGuestOpen, setEditGuestOpen] = React.useState(false);
    const [AddNewGuest, setAddNewGuest] = useState('');
   
    // ref for new and editing guest
    let NewGuestNameRef = useRef(null);
    let NewGuestDescriptionRef = useRef(null);
    let EditGuestNameRef = useRef(EditingGuest?.guestName);
    let EditGuestDescriptionRef = useRef(EditingGuest?.guestDesc);
    
    // ref for updating events
    let NameRef = useRef(null);
    let descRef = useRef(null);
    let AddressRef = useRef(null);
    let LatitudeRef = useRef(null);
    let LongitudeRef = useRef(null);

    useEffect(() => {
         if(!Event.length>0){
          setisFetching(true);
          const response =  dispatch(FetchEventById(eventName));
          response.then(function(result) {
            if(result.success){
            setisFetching(false);
            setEvent(result.data);
          }else{
            setisFetching(false);
             }
          }) 
         }
      }, [])

      const handleAddGuestSubmit = () => {
        let formData = {
          guestName:NewGuestNameRef.current?.value,
          guestDesc:NewGuestDescriptionRef.current?.value,
        };
  
        const response =  dispatch(addNewEventGuest(formData,Event._id));
        response.then(function(result) {
          
          if(result.success){
              setEvent(result.data[0]);
            setAddNewGuest(false);
           }
           else{
            setAddNewGuest(false);
           }
         })
      };

      const handleDeleteGuest = () => {
       let guestData = {
          guestId:Guest._id
       }
  
        const response =  dispatch(deleteEventGuest(guestData,Event._id));
        response.then(function(result) {
          if(result.success){
              setEvent(result.data[0]);
              setGuestOpen(false);
              setAddNewGuest(false);
           }
           else{
            setAddNewGuest(false);
           }
         })
      };

      const handleUpdateGuestSubmit = () => {
        let formData = {
          guestName:EditingGuest?.guestName,
          guestNewName:EditGuestNameRef.current.value,
          guestDesc:EditGuestDescriptionRef.current.value,
        };
  
        const response =  dispatch(updateEventGuest(formData,Event._id));
        response.then(function(result) {
          
          if(result.success){
              setEvent(result.data[0]);
              setEditGuestOpen(false);
           }
           else{
            setEditGuestOpen(false);
           }
         })
      };



    useEffect(() => {
      if(Event.eventName)
      {
         setEventProfileImage(Event?.eventProfilePhoto);
        setName(Event?.eventName);
        setAddress(Event?.address);
        setDesc(Event?.description);
        setSelectedStartDate(Event?.timings[0]?.startDate);
        setSelectedEndDate(Event?.timings[0]?.EndDate);
        setSelectedStartTime(Event?.timings[0]?.startTime);
        setSelectedEndTime(Event?.timings[0]?.endTime);
        setLatitude(Event?.location?.coordinates[1]);
        setLongitude(Event?.location?.coordinates[0]);
        }
      }, [Event])



      const updateEventNetworkCall = async (updatedData) => {
        
        setIsUpdating(true);
        const { data } = await api.updateEvent(updatedData,Event._id);
          if(data.success)
          { 
            const snackbarDetails = {
              snackbarOpen:true, 
                snackbarType:"success",
               snackbarMessage:"The Event is Successfully Updated"
             }   
           dispatch(ChangeSnackbar(snackbarDetails)); 
           setEvent(data.data);
           setIsUpdating(false);
           setUpdateModalOpen(false);

             
            let formData = {
              "title":"Event Updated",
              "body":"There was a update in Event"
            }
                    
              dispatch(EventUpdateNotification(formData,Event?._id));
             
             handleClose();
          }
          else{
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
                snackbarMessage:"Couldn't update the event : " + data.message
               }
               
            setIsUpdating(false);
            setUpdateModalOpen(false);
            
            dispatch(ChangeSnackbar(snackbarFailureDetails));
          }
      }

      const updateEvent = () => {

          console.log(selectedEndTime)
          console.log(selectedStartTime)
        const timings = [
          {
          startDate:moment(selectedStartDate).format('MM/DD/yyyy')+('Z'),  
          startTime:moment(selectedStartTime).unix(),
          endTime:moment(selectedEndTime).unix(),
          endDate:moment(selectedEndDate).format('MM/DD/yyyy')+('Z'),
          }
         ]
          
        let coordinates = [parseFloat(LatitudeRef.current.value),parseFloat(LongitudeRef.current.value)];

        let updatedData = {
          Address:AddressRef.current.value,
          Name:NameRef.current.value,
          description:descRef.current.value,
          timings,
          location: { "type": "Point","coordinates": [coordinates[1],coordinates[0]]}
        } 
        updateEventNetworkCall(updatedData);
      }

     const handleUpdateCancel = () => {
        setUpdateModalOpen(false)
     }

     const handleUpdateModalOpen = () => {
        setUpdateModalOpen(true)
     }

    const handleClickOpen = () => {
      setOpen(true);
    };
    
    const handleClose = () => {
      setOpen(false);
    };

    const handleGuest = (g) => {
      setGuest(g);
      setGuestOpen(true);
    };
  
    const handleGuestClose = () => {
      setGuestOpen(false);
    };

    const handleEditGuestOpen = (item) => {

      setEditingGuest(item);
      setEditGuestOpen(true);
    };
  
    const handleEditGuestClose = () => {
      setEditGuestOpen(false);
    }; 
    
    
    const handleAddNewGuestOpen = () => {
      setAddNewGuest(true);
    };  
    
    const handleAddNewGuestClose = () => {
      setAddNewGuest(false);
    };  

    const handleEditEventProfile = () => { 
      setEditProfilePictureOpen(true);
    };
    
    const handleEditEventProfileClose = () => {
      setEditProfilePictureOpen(false);
    };

    const OpenGoogleMaps = () => {
      window.open(`https://maps.google.com?q=${Event?.location?.coordinates[1]},${Event?.location?.coordinates[0]}`);
    };  

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
        setSelectedImage(e.target.files[0]);
        ImageCompression(e.target.files[0]);

    }
  };


  const addEventProfileImage = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", CompressedImage);

    const { data } = await api.addEventProfileMedia(Event?._id,formData);
   
      if(data.success)
      { 
       setEventProfileImage(data.data.eventProfilePhoto);
       setSelectedImage();
       setEditProfilePictureOpen(false);
       setLoading(false);

      }
      else{
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
            snackbarMessage:"Couldn't update the Masjid Profile : " + data.message
           }       
       setLoading(false);
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
   
  };


  const deleteEventProfileImage = async () => {
    setDeleteLoading(true);
    let start = EventProfileImage.indexOf("Event");
    let string = EventProfileImage.substr(start);
    let end = string.indexOf(".jpg");
    let Id = EventProfileImage.substr(start,end);
 
    const formData = {
      "imageId":Id
    }

    try{
         const { data } = await api.deleteEventProfileMedia(Event._id,formData);
   
        if(data.success)
        {
            const snackbarDetails = {
                snackbarOpen:true, 
                snackbarType:"success",
                snackbarMessage:"Deleted Masjid Profile Image Successfully"
   
               }
               setEventProfileImage('');
               setEditProfilePictureOpen(false);
               setSelectedImage();
               setDeleteLoading(false);
               dispatch(ChangeSnackbar(snackbarDetails));
         }
      }    
      catch(error)
      {
         const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:"Couldn't delete the masjid Profile : " + error.message
         }
         dispatch(ChangeSnackbar(snackbarFailureDetails)); 
         setDeleteLoading(false);           
         return error.response.data;
      }
  }

  const updateEventProfileImage = async () => {
    setLoading(true);
    const formData = new FormData();

    let start = EventProfileImage.indexOf("Event");
    let string = EventProfileImage.substr(start);
    let end = string.indexOf(".jpg");
    let Id = EventProfileImage.substr(start,end);
    formData.append("image", CompressedImage);
    formData.append("imageId",Id);

    const { data } = await api.updateEventProfileMedia(Event?._id,formData);
   
    try{

        if(data.success)
        {
            const snackbarDetails = {
                snackbarOpen:true, 
                snackbarType:"success",
                snackbarMessage:"updated Masjid Profile Image Successfully"
   
               }
               setEventProfileImage(data.data.eventProfilePhoto);
               setEditProfilePictureOpen(false);
               setSelectedImage();
               setDeleteLoading(false);
               dispatch(ChangeSnackbar(snackbarDetails));
          }
        }    
        catch(error)
        {
           const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:"Couldn't updated the masjid profile : " + error.message
           }       
           
           dispatch(ChangeSnackbar(snackbarFailureDetails)); 
           setDeleteLoading(false);           
           return error.response.data;
        }
  }

  const ImageCompression = async (img) =>  {
    const imageFile = img;
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      setCompressedImage(compressedFile);
    } catch (error) {
           const snackbarFailureDetails = {
          snackbarOpen:true, 
           snackbarType:"warning",
           snackbarMessage:"Failed to Compress and Upload the Image",
         }
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
    }
  };

  const getJpegFromUrl = async (url) => {
    const fetchUrl = url
    const fileName = 'myFile.jpeg'
    fetch(fetchUrl)
      .then(async response => {
        const contentType = response.headers.get('content-type')
        const blob = await response.blob()
        const file = new File([blob], fileName , {
          type: blob.type,
        },  { contentType });
        setSelectedImage(file);

      })
  }

  
  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }
    
   const handleUrlUpload = (e) => {
      if(isValidHttpUrl(e.target.value)){
         getJpegFromUrl(e.target.value)
        }
    }



    return (
      <div className="HomeContainer2">
      {sidebarState ?(<div className="LeftContainer">
           <SideBar/>
         </div>):
          (<div className="LeftContainerClose">
          <SideBar/>
        </div>)} 
      <div className="RightContainer2">
       <div className="RightTopContainer">
       <TopBar/>
       </div>
      <div className="single2">
        <div className="singleContainer2">
        {  isFetching && (<><span className="LoadingMasjidMessage">Getting The Event Data  <CircularProgress  size="20px" style={{marginLeft:"10px"}} /></span></>) }
          <div className="top">
          <Dialog open={UpdateModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                  { `Are you sure you want to Update the masjid ?`}
              </DialogTitle>
                <DialogActions>
                  <Button onClick={updateEvent} variant="outlined"  >  { IsUpdating ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                  <Button onClick={handleUpdateCancel} variant="outlined"  disabled={IsUpdating}>No</Button>
                </DialogActions>
            </Dialog>
            <div className="left">
                <Dialog open={open} onClose={handleClose} height={"xl"} >
                  <DialogTitle>Edit Event</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Event Name"
                      autoComplete='Off'
                      sx={{  marginBottom: 3 }}
                      type="text"
                      fullWidth
                      inputRef={NameRef}
                      defaultValue={Name} 
                      variant="standard"/>
                      <br/>

                    <TextField
                      id="outlined-required"
                      variant="standard"
                      label="Address"
                      autoComplete='Off'
                      inputRef={AddressRef}
                      defaultValue={Address}
                      fullWidth
                      sx={{  marginBottom: 3 }}
                      type="text"/> 

                   <TextField
                     id="outlined-required"
                     variant="standard"
                     label="Description"
                     autoComplete='Off'
                     fullWidth
                     inputRef={descRef}
                     type="text"
                     sx={{  marginBottom: 3 }}
                     defaultValue={desc}  /> 
   
                  <TextField
                    id="outlined-required"
                    variant="standard"
                    label="Latitude"
                    autoComplete='Off'
                    inputRef={LatitudeRef}
                    type="number"
                    sx={{  marginBottom: 3}}
                    defaultValue={Latitude} /> 
                  
                    <TextField
                      id="outlined-required"
                      variant="standard"
                      label="Longitude"
                      autoComplete='Off'
                      inputRef={LongitudeRef}
                      type="number"
                      sx={{  marginBottom: 3 , marginLeft: 10}}
                      defaultValue={Longitude} /> 

                      <DatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        sx={{  marginBottom: 3 }}
                        label="Event Start Date "
                        value={selectedStartDate}
                        onChange={setSelectedStartDate} />

                      <TimePicker 
                        autoOk
                        variant="outline"
                        label="Start time"
                        className={classes.root}
                        value={selectedStartTime}
                        onChange= {(newValue) => {
                          setSelectedStartTime(newValue);
                        }} />

                      <DatePicker
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          label="Event Date "
                          sx={{  marginTop: 3 }}
                          value={selectedEndDate}
                          onChange={setSelectedEndDate}
                        />

                    <TimePicker 
                      autoOk
                      label="End Time"
                      className={classes.root}
                      value={selectedEndTime}
                      onChange={(newValue) => {
                        setSelectedEndTime(newValue);
                      }} />

                  </DialogContent>
                   <DialogActions>
                     <Button onClick={handleClose}  style={{ color:"grey"}} >Cancel</Button>
                     <Button onClick = {handleUpdateModalOpen}> Update </Button>
                   </DialogActions>
                </Dialog>
              <h1 className="title">Event Information</h1>
              <div className="item">
              {
                Event?._id ? (
                  <>
              <img
                src={EventProfileImage?.length>0 ? EventProfileImage :"https://github.com/rehaanredkar15/SMILE-Social-Media/blob/master/public/Assets/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg?raw=true"}
                alt="eventImage"
                className="itemImg"
              />
              <BsFillPencilFill className="profilePicEdit" size={'1.2rem'} onClick={handleEditEventProfile}  style={{cursor:"pointer"}}/>
               </>):(
               <> 
                <div className="profile-img-skeleton" />
              </>)
              }
               <Dialog open={EditProfilePictureOpen} onClose={handleEditEventProfileClose} height={"xl"} >
                  <DialogTitle>Edit Event Profile Picture</DialogTitle>
                  <DialogContent>
                  <div className="editingOptions">
                  {
                  selectedImage && (
                        <>
                          <div className="ImageContainer">
                            <img src={URL.createObjectURL(selectedImage)} className="MasjidProfileSelectedImage" alt="Thumb" />
                          </div>
                        </>
                    )
                   }
                    {
                     selectedImage && (
                      <>
                      <LoadingButton variant="outlined" onClick={removeSelectedImage}  color="error"  style={{ marginBottom:"10px",marginTop:"10px",width:"66%"}} >
                        Remove Image
                      </LoadingButton>
                       <LoadingButton variant="outlined" loading={loading} style={{marginBottom:"5px",marginTop:"10px"}} component="label" startIcon={<PhotoCamera />} onClick={EventProfileImage?.length>0 ? updateEventProfileImage : addEventProfileImage}   >
                        Update Profile Image
                      </LoadingButton>
                      </>
                      )
                     }
                   {
                    !selectedImage && (<>
                     <Button variant="outlined"  style={{ color:"blue",border:"1px solid blue",marginBottom:"20px",marginTop:"10px",width:"99%",height:"40px",fontSize:"13px"}} component="label" startIcon={<PhotoCamera />}  onChange={imageChange}  >
                     Choose New  Image
                    <input hidden accept="image/*" multiple type="file" />
                    </Button>
                    <div className="PasteBoxForLinkUrl">
                     <input type="url" id="url-field" disabled={UploadAllow} placeholder="Enter the url of the image"  pattern="^https?://.*$" autoFocus="true" className="InputPasteLink" onChange={(e) => handleUrlUpload(e) }/>
                    </div>
                    <LoadingButton variant="outlined"  loading={DeleteLoading} color="error" style={{ border:"1px solid ",marginBottom:"20px"}} startIcon={<DeleteIcon />} disabled={!EventProfileImage?.length>0} onClick={deleteEventProfileImage} >
                     Delete Profile Image
                   </LoadingButton>
                   </>) 

                  }
                  </div>  
                  </DialogContent>
                   <DialogActions>
                     <Button onClick={handleEditEventProfileClose}  style={{ color:"grey",marginBottom:"10px",marginRight:"20px",fontWeight:"Bold"}}>Cancel</Button>
                   </DialogActions>
                </Dialog>
                <div className="details">
                {
                Event._id ? (
                  <>
                  <h1 className="itemTitle">{Event && Event?.eventName  ? Event?.eventName : newEvent?.eventName}
                   <Button variant="outlined"  style={{ maxWidth: "100px",maxHeight:"25px",fontSize:"11px",fontWeight:"bold",marginLeft:"1rem"}} onClick={handleClickOpen} className ="btn-edit">
                    Edit Event
                   </Button>
                  </h1>
                 {
                   Event?.description && ( <div className="detailItem">
                    <span className="itemKey">Description : </span>
                    <span className="itemValue">{Event && Event?.description ? Event?.description  :<span className="itemKey2"> {"No desc Available for Masjid"}</span>}
                    </span>
                  </div>)
                 }
                  <div className="detailItem">
                    <span className="itemKey">Address :</span>
                    <span className="itemValue">
                    {Event? Event?.address  : newEvent?.address}
                    </span>
                  </div>
                  <div className="detailItem">
                    <b><span className="itemKey">Masjid Name :</span></b>
                    <span className="itemValue">
                    {Event && Event?.masjidName}
                    </span>
                  </div>
                  <div className="detailItem">
                    <b><span className="itemKey">Latitude :</span></b>
                    <span className="itemValue">
                    {Event? Event?.location?.coordinates[1]:<span className="itemKey2"> {"Latitude is not Available"} </span>}
                    </span>
                  </div>
                  <div className="detailItem">
                  <b><span className="itemKey">Longitude :</span></b>
                    <span className="itemValue">
                    {Event? Event?.location?.coordinates[0]: <span className="itemKey2"> {"Longitude is not Available"} </span>}
                    </span>
                  </div>
                  <GrMapLocation size={'1.7rem'} style={{cursor:"pointer",marginLeft:"5px",Color:"green"}} onClick={OpenGoogleMaps}/> 
                  <span className="ViewMaps" onClick={OpenGoogleMaps} style={{cursor:"pointer"}}> View Location on Google maps</span>
                  <div className="detailItem">
                    <span className="itemKey">Timing :</span>
                    <span className="itemValue">
                    { 
                     Event?.timings?.length >0 && ( 
                       <>
                         <div>
                         <b>Event Starting Day :</b>
                         <span className="itemValue">  {moment(Event?.timings[0]?.startDate).format("DD/MM/YYYY") } </span> 
                         <br /> 
                         <b>Start time:</b>
                         <span className="itemValue">{moment(Event?.timings[0]?.startTime).format("hh:mm A")} </span>            
                         <br />  
                         <b>Event Ending Day :</b>
                         <span className="itemValue">  {moment(Event?.timings[0]?.endDate).format("DD/MM/YYYY") } </span> 
                         <br /> 
                         <b>End time:</b>
                         <span className="itemValue">  {moment(Event?.timings[0]?.endTime).format("hh:mm A")} </span>            
                        <br />  
                        </div> 
                        </>
                       )
                        }
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Guests :
                    </span>
                    { !AddGuest && <><TiUserAdd onClick={handleAddNewGuestOpen} size={'1.5rem'} className="AddGuestIcon2"/><span> Add Guest</span> </>}
                     {
                         Event?.guests?.length > 0 && Event.guests?.map((item, index) => (
                          <>
                            <div key={item._id}>
                            <b>Name :</b><span className="itemValue" > {item.guestName} </span>    
                            <TiTimes size={'1.2rem'} onClick={(e) => handleGuest(item)} className="RemoveGuest"/>
                            <TiPencil size={'1.2rem'} onClick={(e) => handleEditGuestOpen(item)} className="RemoveGuest2"/>
                             <br />
                             <b>Desc :</b> <span className="itemValue" >{item.guestDesc}</span>
                            </div>  
                            <br/>
                          </>
                          ))
                        }  
                  </div>
                </>
                  ):( <>
                  <div className="skeleton-placeholder-title" />
                  <div className="skeleton-placeholder-title" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder-title2" />
                  <div className="skeleton-placeholder-title2" />
                </>)
               }
                  <Dialog open={GuestOpen} onClose={handleGuestClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                      <DialogTitle id="alert-dialog-title">
                       { `Are you sure you want to delete the guest :- ${Guest.guestName ? Guest.guestName : " "} ?`}
                      </DialogTitle>
                      <DialogActions>
                        <Button onClick={handleGuestClose} style={{color:'grey'}}>No</Button>
                        <Button onClick={handleDeleteGuest} autoFocus>
                          Yes
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <div> 
                    <Dialog open={EditGuestOpen} onClose={handleEditGuestClose}>
                        <DialogTitle>Edit Guest</DialogTitle>
                        <DialogContent>
                              <TextField
                                  id="outlined-required"
                                  variant="standard"
                                  label="Guest Name"
                                  inputRef = {EditGuestNameRef}
                                  defaultValue={EditingGuest?.guestName}
                                  autoComplete='Off'
                                  disableunderline 
                                  sx={{  marginBottom: 1 ,width: 300 }}
                                  type="text"
                                /> 
                                <br/>
                                <TextField
                                  id="outlined-required"
                                  variant="standard"
                                  multiline
                                  inputRef ={EditGuestDescriptionRef}
                                  defaultValue={EditingGuest?.guestDesc}
                                  autoComplete='Off'
                                  sx={{ width: 300 }}
                                  label="Guest Description"
                                  disableunderline 
                                  type="text"
                                  /> 
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleEditGuestClose}  style={{ color:"grey"}}>Cancel</Button>
                          <Button onClick={handleUpdateGuestSubmit}>Edit</Button>
                        </DialogActions>
                      </Dialog>
                  
                    </div>

                    <div>
                    <Dialog open={AddNewGuest} onClose={handleAddNewGuestClose}>
                        <DialogTitle>Add New Guest</DialogTitle>
                        <DialogContent>
                              <TextField
                                  id="outlined-required"
                                  variant="standard"
                                  label="Guest Name"
                                  autoComplete='Off'
                                  inputRef={NewGuestNameRef} 
                                  sx={{  marginBottom: 1 ,width: 300 }}
                                  type="text"
                                /> 
                                <br/>
                                <TextField
                                  id="outlined-required"
                                  variant="standard"
                                  inputRef={NewGuestDescriptionRef}
                                  multiline
                                  autoComplete='Off'
                                  sx={{ width: 300 }}
                                  label="Guest Description"
                                  disableunderline 
                                  type="text"
                                  /> 
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleAddNewGuestClose}  style={{ color:"grey"}}>Cancel</Button>
                          <Button onClick={handleAddGuestSubmit}>Add</Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                </div>

                <div className="right">
                { 
                 Event?.eventPhotos ?
                 <>
                 <div className="MasjidGallery">
                 {    Event?.eventPhotos &&  <PictureCarouselComponent eventPhotos={ Event?.eventPhotos} EventId={eventName}  setUploadAllow={setUploadAllow}/> }
                </div>
                  <FileUploadEvent  EventId={eventName} setEvent={setEvent} UploadAllow={UploadAllow} /> 
                 </> 
                 :
                 <>
                  <div className="skeleton-placeholder-image" />
                 </>
                 }
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
  )
}

export default EventProfile