import { FETCH_MASJIDS } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const getFreezedAdmins = () => async(dispatch) => {
    try{
      const { data } = await api.fetchFreezeAdmins();

      if(data.success)
      {
         return data;
       }
     return data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response.data ?error.response.data.message:"Fetching Freezed Admin Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      return error.response.data;

   }
}