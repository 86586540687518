import { ADD_MASJID } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const addNewMasjid = (formData,navigate) => async(dispatch) => {


    try{
      const  { data }  = await api.addMasjid(formData);
     
      if(data.success)
      {
         
        dispatch({type:"ADD_MASJID" , payload: data.data}) ;

        navigate("/masjid/"+ data.data.masjidName);
         
         return data;
      }
      return data;

     

   }    
    catch(error)
   {
    const snackbarFailureDetails = {
      snackbarOpen:true, 
       snackbarType:"error",
       snackbarMessage:error.response.data ?error.response.data.message:"Adding Masjid Failed",
     }

    dispatch(ChangeSnackbar(snackbarFailureDetails)); 

     return error.response.data;
   }
}