import { createStore,applyMiddleware,combineReducers } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import indexReducer from './Reducers/indexReducer'

const initialState = {}
const middleware = [thunk]

const Store = createStore(
  indexReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))    
)
export default Store