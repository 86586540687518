import React,{useState,useEffect,useRef} from 'react'
import "./MasjidProfile.scss";
import * as api from '../../../APICalls/index.js';
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';
import ManageTimingsComponent from '../../../Components/ProfileComponents/MasjidTimings/ManageTimingsComponent.js';
import EventsComponent from '../../../Components/ProfileComponents/EventsComponent/EventsComponent.jsx';
import { useParams } from "react-router-dom";
import {useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import {MasjidUpdateNotification} from '../../../Redux/Actions/NotificationActions/MasjidUpdateNotification.js';
import {FetchMasjidByName} from '../../../Redux/Actions/MasjidActions/FetchMasjidByName.js';
import {FreezeMasjid} from '../../../Redux/Actions/MasjidActions/FreezingMasjid.js';
import TextField from '@mui/material/TextField';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import Button from '@material-ui/core/Button';
import MasjidPictureCarouselComponent from '../../../Components/ProfileComponents/MasjidPictureCarouselComponent/MasjidPictureCarouselComponent.jsx';
import { FileUploadMasjid } from '../../../Components/ProfileComponents/ImageUploadComponents/FileUploadMasjid/FileUploadMasjid.jsx';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { GrMapLocation } from "react-icons/gr";
import { BsFillPencilFill } from "react-icons/bs";
import DeleteIcon from '@mui/icons-material/Delete';
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {addMasjidProfileMedia} from '../../../Redux/Actions/MasjidActions/AddingProfileMedia.js';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@material-ui/core";
import PhoneInput from 'react-phone-number-input'
import { useNavigate } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import { AiOutlineCloseCircle } from "react-icons/ai";


const MasjidProfile = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {masjidName} = useParams();
    const [CurrentMasjid, setCurrentMasjid] = useState('')
    const masjid = useSelector((state) => state.masjid);
    const sidebarState = useSelector((state) => state.sidebarState);
    const MasjidTimings = useSelector((state) => state.masjidTimings);
    const [value, setValue] = useState(new Date());
    const [Open, setOpen] = useState(false);
    const[MasjidPhotos,setMasjidPhotos] = useState(CurrentMasjid && CurrentMasjid?.masjidPhotos);
    const [OpenMasjidUpload, setOpenMasjidUpload] = useState(false)
    const [EditModalOpen, setEditModalOpen] = useState(false);
    const [EditProfilePictureOpen, setEditProfilePictureOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const [CompressedImage, setCompressedImage] = useState('');
    const [MasjidProfileImage, setMasjidProfileImage] = useState();
    const [DeleteLoading, setDeleteLoading] = useState(false);
    const [UpdateLoading, setUpdateLoading] = useState(false);
    const [UploadAllow, setUploadAllow] = useState(false);
    const [DeleteModalOpen, setDeleteModalOpen] = React.useState(false);
    const [MasjidAddress, setMasjidAddress] = useState('');
    const [MasjidName, setMasjidName] = useState('');
    const [MasjidDesc, setMasjidDesc] = useState('');
    const [MasjidLatitude, setMasjidLatitude] = useState('');
    const [MasjidLongitude, setMasjidLongitude] = useState('');
    const [MasjidContact, setMasjidContact] = useState('');
    const [MasjidCountry, setMasjidCountry] = useState('');
    const [ MasjidAddressError, setMasjidAddressError] = useState('');
    const [MasjidNameError, setMasjidNameError] = useState('');
    const [MasjidDescError, setMasjidDescError] = useState('');
    const [MasjidLatitudeError, setMasjidLatitudeError] = useState('');
    const [MasjidLongitudeError, setMasjidLongitudeError] = useState('');
    const [MasjidCountryUpload, setMasjidCountryUpload] = useState('');
    const [isFetching, setisFetching] = useState(false);
    const [isFreezing, setisFreezing] = useState(false);
    const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
    const [IsUpdating, setIsUpdating] = useState(false);
    const [UpdateModalOpen, setUpdateModalOpen] = useState(false);
    const admin = JSON.parse(localStorage.getItem("admin"));

    let MasjidNameRef = useRef(null);
    let MasjidDescRef = useRef(null);
    let MasjidAddressRef = useRef(null);
    let MasjidLatitudeRef = useRef(null);
    let MasjidLongitudeRef = useRef(null);
    let MasjidContactRef = useRef(null);
  
    
    const OpenGoogleMaps = () => {
      window.open(`https://maps.google.com?q=${MasjidLatitude},${MasjidLongitude}`);
    }; 



    useEffect(() => {
      setisFetching(true);
      const response =  dispatch(FetchMasjidByName(masjidName));
      response.then(function(result) {
       
      if(!result.success){

          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:result.message ? "Failed to Load Masjid Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
          }
              
           dispatch(ChangeSnackbar(snackbarFailureDetails)); 
           setisFetching(false);

         }else{
          if(result.data.length>0){
            setCurrentMasjid(result.data);
            setisFetching(false);
          }
         }
       })
      }, [masjidName])




      useEffect(() => {
        masjid.length>0 && setCurrentMasjid(masjid[0]);
        masjid.length>0 && setMasjidProfileImage(masjid[0].masjidProfilePhoto);
       masjid.length>0 && setMasjidPhotos(masjid[0]?.masjidPhotos);
       if( masjid.length>0)
       {
        setMasjidName(masjid[0]?.masjidName);
        setMasjidAddress(masjid[0]?.address);
        setMasjidDesc(masjid[0]?.description);
        setMasjidLatitude(masjid[0]?.location?.coordinates[1]);
        setMasjidLongitude(masjid[0]?.location?.coordinates[0]);
        setMasjidContact(masjid[0]?.contact);
       }
       }, [masjid])

    const handleEditMasjidModal = () => { 
      setEditModalOpen(true);
    };

    const handleUpdateMasjidModal = () => { 
      setUpdateModalOpen(true);
    };
    
    const handleEditMasjidModalClose = () => {
      setEditModalOpen(false);
    };
    
    const handleEditMasjidProfile = () => { 
      setEditProfilePictureOpen(true);
    };
    
    const handleEditMasjidProfileClose = () => {
      setEditProfilePictureOpen(false);
     setTimeout(() => {
       setSelectedImage();
     }, 1000); 
    };
    
  

    const handlePhoneChange = (event) => {
      setFreezeModalOpen(true);
    };

    
  const handleCountryChange = (event) => {

      if(event == "IN")
      {
        setMasjidCountryUpload('India');

      }else if(event == "US")
      {
        setMasjidCountryUpload('United State of America');
      }
      else if(event == "UK"){
        setMasjidCountryUpload('United Kingdom');
      }
    
   };

    const updateMasjidNetworkCall = async (updatedData) => {
      setIsUpdating(true);
      const { data } = await api.updateMasjid(updatedData,CurrentMasjid?._id);
      if(data.success)
      { 
        const snackbarDetails = {
          snackbarOpen:true, 
            snackbarType:"success",
           snackbarMessage:"The Masjid is Successfully Updated"
         }   

       dispatch(ChangeSnackbar(snackbarDetails)); 
       setCurrentMasjid(data.data);
        handleEditMasjidModalClose(false);
        setIsUpdating(false);
        setUpdateModalOpen(false);

        let formData = {
          "title":"Masjid Updated",
          "body":"There was a update in masjid"
        }

       dispatch(MasjidUpdateNotification(formData,CurrentMasjid?._id));

      }
      else{
        setIsUpdating(false);
        setUpdateModalOpen(false);
        
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
            snackbarMessage:"Couldn't update the Masjid : " + data.message
           }       
        dispatch(ChangeSnackbar(snackbarFailureDetails));
      }
    }





    const updateMasjid = () => {
      let coordinates = [parseFloat(MasjidLatitudeRef.current.value),parseFloat(MasjidLongitudeRef.current.value)];

      if(!MasjidAddressRef.current.value){
        setMasjidAddressError(true);
      }else if(MasjidAddressRef.current.value){
        setMasjidNameError(false);
      }

      if(!MasjidNameRef.current.value){
        setMasjidNameError(true);
      }else if(MasjidNameRef.current.value){
        setMasjidNameError(false);
      }

      if(!MasjidDescRef.current.value){
        setMasjidDescError(true);
      }else if(MasjidDescRef.current.value){
        setMasjidDescError(false);
      }

      if(!MasjidLatitudeRef.current.value){
        setMasjidLatitudeError(true);
      }else if(MasjidLatitudeRef.current.value){
        setMasjidLatitudeError(false);
      }

      if(!MasjidLongitudeRef.current.value){
        setMasjidLongitudeError(true);
      }else if(MasjidLongitudeRef.current.value){
        setMasjidLongitudeError(false);
      }
        
      let updatedData = {
        address:MasjidAddressRef.current.value,
        masjidName:MasjidNameRef.current.value,
        description:MasjidDescRef.current.value,
        country:MasjidCountryUpload,
        contact:MasjidContactRef.current.value,
        location: { "type": "Point","coordinates": [coordinates[1],coordinates[0]]}} 
            
        if(coordinates[0] && coordinates[1] && MasjidDescRef.current.value &&  MasjidNameRef.current.value && MasjidAddressRef.current.value ){
          updateMasjidNetworkCall(updatedData);
        }
         else{
          const snackbarDetails = {
            snackbarOpen:true, 
              snackbarType:"warning",
             snackbarMessage:"Please enter correct details before trying again"
           }   

         dispatch(ChangeSnackbar(snackbarDetails)); 
         }
    };



    const ImageCompression = async (img) =>  {
      const imageFile = img;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(imageFile, options);
        setCompressedImage(compressedFile);
      } catch (error) {
             const snackbarFailureDetails = {
            snackbarOpen:true, 
             snackbarType:"warning",
             snackbarMessage:"Failed to Compress and Upload the Image",
           }
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      }
    };

    const getJpegFromUrl = async (url) => {
      const fetchUrl = url
      const fileName = 'myFile.jpeg'
      fetch(fetchUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], fileName , {
            type: blob.type,
          },  { contentType });
          setSelectedImage(file);
          setOpen(!Open);

        })
    }
    
   
    const addMasjidProfileImage = () => {
      setUpdateLoading(true);
      const formData = new FormData();
      formData.append("image", CompressedImage);
        const response = dispatch(addMasjidProfileMedia(CurrentMasjid?._id,formData));
        response.then(function(result) {
        if(result.success)
        { 
         setMasjidProfileImage(result.data.masjidProfilePhoto);
         setSelectedImage();
         setEditProfilePictureOpen(false);
         setUpdateLoading(false);

        }
        else{
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
              snackbarMessage:"Couldn't update the Masjid Profile : " + response.message
             }       
             setUpdateLoading(false);
          dispatch(ChangeSnackbar(snackbarFailureDetails));
        }
      });
     
    };


    const deleteMasjidProfileImage = async () => {
      setDeleteLoading(true);
      let start = MasjidProfileImage.indexOf("Masjid");
      let string = MasjidProfileImage.substr(start);
      let end = string.indexOf(".jpg");
      let Id = MasjidProfileImage.substr(start,end);
      const formData = {
        "imageId":Id
      }

      try{
          const { data } = await api.deleteMasjidProfileMedia(CurrentMasjid._id,formData);
     
          if(data.success)
          {
              const snackbarDetails = {
                  snackbarOpen:true, 
                  snackbarType:"success",
                  snackbarMessage:"Deleted Masjid Profile Image Successfully"
                 }

                 setMasjidProfileImage(data.data.masjidProfilePhoto);
                 setEditProfilePictureOpen(false);
                 setSelectedImage();
                 setDeleteModalOpen(false);
                 setDeleteLoading(false);
                 dispatch(ChangeSnackbar(snackbarDetails));
           }
        }
        catch(error)
        {
           const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:"Couldn't delete the masjid Profile : " + error.message
           }

           dispatch(ChangeSnackbar(snackbarFailureDetails)); 
           setDeleteLoading(false);    
           setDeleteModalOpen(false);

           return error.response.data;
        }
    }

    const updateMasjidProfileImage = async () => {
      setUpdateLoading(true);
      const formData = new FormData();
      let start = MasjidProfileImage.indexOf("Masjid");
      let string = MasjidProfileImage.substr(start);
      let end = string.indexOf(".jpg");
      let Id = MasjidProfileImage.substr(start,end);
      formData.append("image", CompressedImage);
      formData.append("imageId",Id);

      const { data } = await api.updateMasjidProfileMedia(CurrentMasjid._id,formData);
     
      try{
          if(data.success)
          {
              const snackbarDetails = {
                  snackbarOpen:true, 
                  snackbarType:"success",
                  snackbarMessage:"updated Masjid Profile Image Successfully"
     
                 }
                 setMasjidProfileImage(data.data.masjidProfilePhoto);
                 setEditProfilePictureOpen(false);
                 setSelectedImage();
                //  setDeleteLoading(false);
                 setUpdateLoading(false);
                 dispatch(ChangeSnackbar(snackbarDetails));
            }
          }    
          catch(error)
          {
             const snackbarFailureDetails = {
                snackbarOpen:true, 
                snackbarType:"error",
                snackbarMessage:"Couldn't updated the masjid profile : " + error.message
             }       
             
             dispatch(ChangeSnackbar(snackbarFailureDetails)); 
            //  setDeleteLoading(false);   
             setUpdateLoading(false);
             return error.response.data;
          }
    }


    const handleDeleteModal = () => {
      setDeleteModalOpen(false);
   }

   const handleDeleteModalOpen = () => {
      setDeleteModalOpen(true);
   }

    const removeSelectedImage = () => {
      setSelectedImage();
    };

    const imageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
          setSelectedImage(e.target.files[0]);
          ImageCompression(e.target.files[0]);
      }
    };

    const handleFreeze = () => {
      setFreezeModalOpen(false)
   }

   
   const handleUpdateCancel = () => {
    setUpdateModalOpen(false)
 }

    const handleFreezeMasjid = () => {
      setisFreezing(true);
      const response = dispatch(FreezeMasjid(CurrentMasjid._id));
     
        response.then(function(result) {

        if(result.success)
        { 
          const snackbarSuccessDetails = {
            snackbarOpen:true, 
            snackbarType:"success",
            snackbarMessage:"Freezed The masjid Successfully"
          }       
          dispatch(ChangeSnackbar(snackbarSuccessDetails));
          setisFreezing(false);
          setFreezeModalOpen(false)
          navigate("/freezeMasjids");
        }
        else{

          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
              snackbarMessage:"Couldn't update the Masjid Profile : " + result.message
             }       
             setisFreezing(false);
        setFreezeModalOpen(false)
          dispatch(ChangeSnackbar(snackbarFailureDetails));
        }
      });
   }

 function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
  
 const handleUrlUpload = (e) => {
    if(isValidHttpUrl(e.target.value)){
       getJpegFromUrl(e.target.value)
      }
  }

  return (
    <div className="HomeContainer2">
    { sidebarState ? (<div className="LeftContainer">
       <SideBar/>
     </div>):(<div className="LeftContainerClose">
      <SideBar/>
    </div>)
    } 
    <div className="RightContainer2">
     <div className="RightTopContainer">
     <TopBar/>
     </div>
     <Dialog open={DeleteModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to delete the profile image ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={deleteMasjidProfileImage} variant="outlined">  { DeleteLoading ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                     <Button onClick={handleDeleteModal} variant="outlined"  disabled={DeleteLoading}>No</Button>
             </DialogActions>
     </Dialog>
    <div className="single">
    <Dialog open={FreezeModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to freeze the masjid ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={handleFreezeMasjid} variant="outlined"  >  { isFreezing ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                 <Button onClick={handleFreeze} variant="outlined"  disabled={isFreezing}>No</Button>
              </DialogActions>
     </Dialog>
     <Dialog open={UpdateModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to Update the masjid ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={updateMasjid} variant="outlined"  >  { IsUpdating ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                 <Button onClick={handleUpdateCancel} variant="outlined"  disabled={IsUpdating}>No</Button>
              </DialogActions>
     </Dialog>
      <div className="singleContainer">
         { isFetching && (<><span className="LoadingMasjidMessage">Getting The Masjid Data  <CircularProgress  size="20px" style={{marginLeft:"10px"}} /></span></>) }
        <div className="top">
          <div className="left">
            <div className="titleHead">
             <h1 className="title">Masjid Information</h1>
             {(admin.role ==="superadmin" || admin.role === "dataentry") && (<> <Button onClick={(e) => setFreezeModalOpen(true)}  style={{ border:"1px solid red",color:"red",height:"20px",fontSize:"10px",marginLeft:"10px",marginBottom:"10px",cursor:"Pointer"}}>Freeze Masjid</Button></>)}
            </div>
            <div className="item">
            {
             CurrentMasjid  ? (
             <>
             <div className="profilePic">
            {
                MasjidProfileImage ? (
                  <img src={MasjidProfileImage ? MasjidProfileImage :"https://www.maxed.in/wp-content/themes/consultix/images/no-image-found-360x250.png"} alt="" className="itemImg" />
                ):(
                  <img src={"https://www.maxed.in/wp-content/themes/consultix/images/no-image-found-360x250.png"} alt="" className="itemImg" />
                )
              }
                <BsFillPencilFill className="profilePicEdit" size={'1.2rem'} onClick={handleEditMasjidProfile}  style={{cursor:"pointer"}}/>
                </div>
               </>):(
               <>   
                <div className="profile-img-skeleton" />
              </>)
              }
                <Dialog open={EditProfilePictureOpen} onClose={handleEditMasjidProfileClose} height={"xl"} >
                  <DialogTitle>Edit Masjid Profile Picture</DialogTitle>
                  <DialogContent>
                  <div className="editingOptions">
                  {
                  selectedImage && (
                        <>
                          <div className="ImageContainer">
                            <img src={URL.createObjectURL(selectedImage)} className="MasjidProfileSelectedImage" alt="Thumb" />
                          </div>
                        </>
                    )
                   }
                    {
                     selectedImage && (
                      <>
                      <LoadingButton variant="outlined" onClick={removeSelectedImage}  color="error"  style={{ marginBottom:"10px",marginTop:"10px",width:"66%"}} >
                        Remove Image
                      </LoadingButton>
                       <LoadingButton variant="outlined" loading={UpdateLoading} style={{marginBottom:"5px",marginTop:"10px"}} component="label" startIcon={<PhotoCamera />} onClick={MasjidProfileImage?.length>0 ? updateMasjidProfileImage : addMasjidProfileImage }   >
                        Update Profile Image
                      </LoadingButton>
                      </>
                      )
                      }
                   {
                    !selectedImage && (<>
                     <Button variant="outlined"  style={{ color:"blue",border:"1px solid blue",marginBottom:"20px",marginTop:"10px",width:"99%",height:"40px",fontSize:"13px"}} component="label" startIcon={<PhotoCamera />}  onChange={imageChange}  >
                     Choose New  Image
                    <input hidden accept="image/*" multiple type="file" />
                    </Button>
                    <div className="PasteBoxForLinkUrl">
                     <input type="url" id="url-field" disabled={UploadAllow} placeholder="Enter the url of the image"  pattern="^https?://.*$" autoFocus="true" className="InputPasteLink" onChange={(e) => handleUrlUpload(e) }/>
                    </div>
                    <LoadingButton variant="outlined"  loading={DeleteLoading} color="error" style={{ border:"1px solid ",marginBottom:"20px"}} startIcon={<DeleteIcon />} disabled={!MasjidProfileImage?.length>0} onClick={handleDeleteModalOpen} >
                     Delete Profile Image
                   </LoadingButton>
                   </>) 
                  }
                  </div>  
                  </DialogContent>
                   <DialogActions>
                     <Button onClick={handleEditMasjidProfileClose}  style={{ border:"1px solid grey",color:"grey",marginBottom:"10px",marginRight:"20px",fontWeight:"Bold"}}>Cancel</Button>
                   </DialogActions>
                </Dialog>
              <div className="details">
              {
                CurrentMasjid ? (
                  <>
                <h1 className="itemTitle">{CurrentMasjid  && CurrentMasjid?.masjidName}
                 {  (admin.role ==="superadmin" || admin.role === "dataentry") && (<><Button variant="outlined"  style={{ maxWidth: "130px",maxHeight:"25px",fontSize:"11px",fontWeight:"bold",marginLeft:"1rem",border:"1px solid blue",color:"blue"}}  onClick = {handleEditMasjidModal} className ="btn-edit2">
                    Edit Masjid
                   </Button>
                   </>
                   ) }
                   </h1> 
                   {
                   (CurrentMasjid  &&  CurrentMasjid?.description?.length>0) && ( <div className="detailItem">
                  <span className="itemKey">Description :</span>
                  <span className="itemValue">{CurrentMasjid  &&  CurrentMasjid?.description?.length>0 ? CurrentMasjid?.description : <span className="itemKey2"> {"No description Available for Masjid"}</span>}</span>
                </div>) 
                   }
                <div className="detailItem">
                  <span className="itemKey">Phone :</span>
                  <span className="itemValue">{CurrentMasjid && CurrentMasjid?.contact }</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address :</span>
                  <span className="itemValue">
                  {CurrentMasjid &&  CurrentMasjid?.address}
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country :</span>
                  <span className="itemValue">{CurrentMasjid  &&  CurrentMasjid.country}</span>
                </div>
                <GrMapLocation size={'1.7rem'} style={{cursor:"pointer",marginLeft:"5px"}} onClick={OpenGoogleMaps}/> 
                  <span className="ViewMaps"  onClick={OpenGoogleMaps}  style={{cursor:"pointer"}}> View Location on Google maps</span>
              </>
              ):
              (
                <>
                  <div className="skeleton-placeholder-title" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                </>
              )
            }
            </div>
              <div>
              <Dialog open={EditModalOpen} onClose={handleEditMasjidModalClose} height={"xl"} >
                  <DialogTitle>Edit Masjid</DialogTitle>
                  <DialogContent>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      error={MasjidNameError}
                      helperText={MasjidNameError?"Manditory field":""}
                      label="Masjid Name"
                      sx={{  marginBottom: 3 }}
                      type="text"
                      fullWidth
                      inputRef={MasjidNameRef}
                      defaultValue={MasjidName} 
                      variant="standard"
                    />

                      <br/>
                    <TextField
                      id="outlined-required"
                      variant="standard"
                      error={MasjidAddressError}
                      helperText={MasjidAddressError?"Manditory field":""}
                      label="Address"
                      inputRef={MasjidAddressRef}
                      defaultValue={MasjidAddress}
                      fullWidth
                      sx={{  marginBottom: 3 }}
                      type="text"/> 

                   <TextField
                     id="outlined-required"
                     variant="standard"
                     error={MasjidDescError}
                     helperText={MasjidDescError?"Manditory field":""}
                     label="Description"
                     fullWidth
                     inputRef={MasjidDescRef}
                     type="text"
                     sx={{  marginBottom: 3 }}
                     defaultValue={MasjidDesc} 
                     /> 
   
                    <TextField
                      id="outlined-required"
                      variant="standard"
                      error={MasjidLatitudeError}
                      helperText={MasjidLatitudeError?"Manditory field":""}
                      label="Latitude"
                      inputRef={MasjidLatitudeRef}
                      type="number"
                      sx={{  marginBottom: 3}}
                      defaultValue={MasjidLatitude} 
                 
                    /> 
                  
                    <TextField
                      id="outlined-required"
                      error={MasjidLongitudeError}
                      helperText={MasjidLongitudeError?"Manditory field":""}
                      variant="standard"
                      label="Longitude"
                      inputRef={MasjidLongitudeRef}
                      type="number"
                      sx={{  marginBottom: 3 , marginLeft: 10}}
                      defaultValue={MasjidLongitude} 

                    /> 
                   <div className="phoneInputContainer">
                      <PhoneInput
                      placeholder="Enter phone number"
                      onCountryChange={(e) => handleCountryChange(e) }
                      ref={MasjidContactRef}
                      style={{'width':'50%'}}
                      value={MasjidContact} 
                      onChange={(e) => handlePhoneChange(e)
                      }
                    />
                </div>
                  </DialogContent>
                   <DialogActions>
                     <Button onClick={handleEditMasjidModalClose}  style={{ border:"1px solid grey",color:"grey",marginBottom:"10px",marginRight:"20px",fontWeight:"Bold"}}>Cancel</Button>
                     <Button onClick = {handleUpdateMasjidModal} style={{ border:"1px solid blue",color:"blue",marginBottom:"10px",marginRight:"20px",fontWeight:"Bold"}}> Update </Button>
                   </DialogActions>
                </Dialog>
              </div>
              <div className="right">
                {
                 CurrentMasjid?.masjidPhotos ?
                 <>
                 <div className="MasjidGallery">
                 {    MasjidPhotos?.length>0 && <MasjidPictureCarouselComponent MasjidPhotos={MasjidPhotos } MasjidId={CurrentMasjid?._id} setUploadAllow={setUploadAllow}/>}
                </div>
                 { <FileUploadMasjid MasjidId={CurrentMasjid?._id} setMasjidPhotos={setMasjidPhotos} MasjidPhotos={CurrentMasjid?.masjidPhotos} UploadAllow={UploadAllow}/> }
                 </> 
                 :
                 <>
                  <div className="skeleton-placeholder-image" />
                 </>
                }
             </div>
            </div>
            </div>
          </div>
          <div >
      </div>
        <div className="bottom">
           <div className="EventsOfMasjid">
           <ManageTimingsComponent Masjid={CurrentMasjid && CurrentMasjid} /> 
           {
            <>
            <div className="EventTable">
              <EventsComponent Masjid={CurrentMasjid && CurrentMasjid}/>
            </div>
            </>
            }
           </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default MasjidProfile;
