import { FETCH_ADMIN_BY_NAME } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchingAdminByNameReducer =  (currentAdmin = [] , action) => {
  switch (action.type) {

    case FETCH_ADMIN_BY_NAME:
      return action.payload;

    default:
      return currentAdmin;
  }
};

export default FetchingAdminByNameReducer;