import { FETCH_MASJIDS } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchMasjidReducer =  (masjids = [] , action) => {
  switch (action.type) {

    case FETCH_MASJIDS:

      return action.payload;

    default:
      return masjids;
  }
};

export default FetchMasjidReducer;