import { AUTH_LOGIN } from '../../ActionType';
import jwt_decode from "jwt-decode";
import moment from "moment";

// eslint-disable-next-line import/no-anonymous-default-export
const LoginReducer =  (admin = [] , action) => {

   switch (action.type) {
    

    case AUTH_LOGIN:
      localStorage.setItem("admin", JSON.stringify(action.payload));
      return action.payload;

    default:
      return admin;
  }
};

export default LoginReducer;