import { FETCH_EVENTS } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchEventReducer =  (masjids = [] , action) => {
  switch (action.type) {

    case FETCH_EVENTS:

      return action.payload;

    default:
      return masjids;
  }
};

export default FetchEventReducer;