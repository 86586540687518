import React,{useRef,useContext,useState} from 'react'
import './ResetPassword.css';
import { useNavigate,useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import {resetPassword} from '../../../Redux/Actions/AuthActions/ResetPasswordAction.js';
import { useDispatch } from 'react-redux';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { useLocation } from "react-router-dom";


const ResetPassword = () => {

    const navigate = useNavigate();
    const email = useRef();
    const password = useRef();
    const confirmPassword = useRef();
    const dispatch = useDispatch();
    const [isFetching, setisFetching] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [PasswordShow, setPasswordShow] = useState(true);
    const [ConfirmPasswordType, setConfirmPasswordType] = useState("password");
    const [ConfirmPasswordShow, setConfirmPasswordShow] = useState(true);
    const search = useLocation().search;
    const id = new URLSearchParams(search).get("token");
    const { token } = useParams();


     const handleSubmit = (e) => {
   
      e.preventDefault();

      
      if(password.current.value == confirmPassword.current.value){
       
        let formData ={
          "password" : password.current.value,
           "token": token
        }
        setisFetching(true);
        const res = dispatch(resetPassword(formData));

        res.then((result) => {
          if(result.success){
          const snackbarDetails = {
            snackbarOpen:true, 
            snackbarType:"success",
            snackbarMessage:` Password Setup Successfully `
          }  
          navigate("/login")
          dispatch(ChangeSnackbar(snackbarDetails));  
          setisFetching(false); 
            }
            else
            {
                const snackbarDetails = {
                    snackbarOpen:true, 
                    snackbarType:"error",
                    snackbarMessage:`Failed To Setup the Password`
                  }  
                  dispatch(ChangeSnackbar(snackbarDetails));
                  setisFetching(false);
              }
          });
        }
       else{
        const snackbarDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:`Password and Confirmed Password does not match`
        }  
            dispatch(ChangeSnackbar(snackbarDetails));
            setisFetching(false);
          }          
        }


      const togglePassword =()=>{
        if(passwordType==="password")
        {
        setPasswordType("text")
        setPasswordShow(true); 
        return;
        }
        setPasswordType("password");
        setPasswordShow(false); 
      }

      const ConfirmTogglePassword =()=>{
        if(ConfirmPasswordType==="password")
        {
        setConfirmPasswordType("text")
        setConfirmPasswordShow(true); 
        return;
        }
        setConfirmPasswordType("password");
        setConfirmPasswordShow(false); 
      }

    return (
         <div className="ForgotPassword">
           <div className="loginWrapper">
            <div className="loginLeft">
                <h3 className="loginLogo">Masjid App Admin Portal</h3>
                <span className="loginDesc">
                  The Admin Portal of Masjid App to work with  masjid's and Events.
                </span>
            </div>
            <div className="loginRight">
          <div className="loginBox">

          <form onSubmit={handleSubmit} className="ForgotPasswordBox">
            <div class='InputFields'>
                <input placeholder="Password"  type={passwordType}  ref={password} required className="ResetPasswordInput" />
               {PasswordShow ? <AiFillEye onClick={togglePassword} className="ShowPasswordLogin"/> : <AiFillEyeInvisible onClick={togglePassword} className="ShowPasswordLogin"/> }
            </div>

            <div class='InputFields'>
            <input placeholder="Confirm Password" type={ConfirmPasswordType} ref={confirmPassword} required className="ResetPasswordInput" />
            {ConfirmPasswordShow ? <AiFillEye onClick={ConfirmTogglePassword} className="ShowPasswordLogin"/> : <AiFillEyeInvisible onClick={ConfirmTogglePassword} className="ShowPasswordLogin"/> }
            </div>
            <button className="ForgotPasswordBtn" type="submit" disabled={isFetching}> 
                {isFetching ? (
                  <CircularProgress color="white" size="20px" />
                ) : (
                  "Reset Password"
                )
                }
             </button>
            <span className="BackToLogin"> 
            <Link to="/login">
            Back to Login 
            </Link>
            </span>
            </form>
          </div>
        </div>
          </div>  
        </div>
    )
}

export default ResetPassword;