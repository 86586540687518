import { FETCH_LATEST_UPDATED_EVENTS } from '../../ActionType';


// eslint-disable-next-line import/no-anonymous-default-export
const FetchLatestEventsReducer =  (LatestEvents = [] , action) => {
    switch (action.type) {
  
      case FETCH_LATEST_UPDATED_EVENTS:
  
        return action.payload;
  
      default:
        return LatestEvents;
    }
  };
  
  export default FetchLatestEventsReducer;