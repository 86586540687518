import axios from 'axios';
import jwt_decode from "jwt-decode";
import moment from "moment";


const API = axios.create({ baseURL: "https://droplet.connectmazjid.com/"});
const baseURL = 'https://droplet.connectmazjid.com/'

// const API = axios.create({ baseURL: "http://localhost:5500/"});
// const baseURL = 'http://localhost:5500/'


API.interceptors.request.use(async (req) => {
  
  if (localStorage.getItem('authTokens')) {
    const token  = JSON.parse(localStorage.getItem('authTokens'));
      if(token.accessToken){
          req.headers.Authorization = `Bearer ${token.accessToken}`
        }
        else{
            req.headers.Authorization = `Bearer ${token.token}`
        }
    }

    const data = JSON.parse(localStorage.getItem('authTokens'));    

    // if(data){
    //   const user = jwt_decode(data.token);
    //   const expireTime = moment.unix(user.exp).format('hh:mm A');
    //   var CurrentTime = moment().format('hh:mm');
      
      
    //   const startTime = moment(CurrentTime, "HH:mm:ss a");
    //   const endTime = moment(expireTime, "HH:mm:ss a");

    //   const duration = moment.duration(endTime.diff(startTime));
      
    //   const hours = parseInt(duration.asHours());
    //   const minutes = parseInt(duration.asMinutes()) % 60;
      
    //    const isExpired = (minutes) < 1;

    //   if(!isExpired) return req
      
    //   const refresh = data.refresh;
     
    //   const response = await axios.post(`${baseURL}v1/auth/refresh`, {
    //     refresh:refresh
    //   });   

    //   localStorage.setItem('authTokens', JSON.stringify(response.data))
      
    //   req.headers.Authorization = `Bearer ${response.data.token}`

    //   return req;
    // }
    return req;

  },
   error => {
    return Promise.reject(error);
   }
  );

export const fetchMasjids = (sortParam,sortOrder) => API.get(`v1/masjid/getMasjids`,{ params: { sortBy: sortParam,sortIn:sortOrder}
});
export const fetchStaleMasjids = () => API.get(`v1/masjid/getMasjidsByUpdatedTime`);
export const fetchAdminMasjids = (id) => API.get(`v1/masjid/getMasjidsByAdmin/` + id, { params: { sortOrder:"asc"}
});
export const fetchMasjidByName = (masjidName) => API.get(`v1/masjid/getMasjid/`+masjidName);
export const fetchMasjidById = (id) => API.get(`v1/masjid/getMasjidById/`+ id);
export const addMasjid = (formData) => API.post(`v1/admin/masjid/addMasjid`,formData);
export const deleteMasjid = (id) => API.delete(`v1/admin/masjid/deleteMasjid/`+ id);
export const updateMasjid = (formData,id) => API.put(`v1/admin/masjid/updateMasjid/` + id , formData);
export const addMasjidMedia = (formData,id) => API.put(`/v1/admin/masjid/addMasjidMedia/`+ id ,formData);
export const deleteMasjidMedia = (id,formData) => API.put(`/v1/admin/masjid/deleteMasjidMedia/`+ id,formData);
export const addMasjidProfileMedia = (id,formData) => API.post(`v1/admin/masjid/addMasjidProfileMedia/`+ id ,formData);
export const updateMasjidProfileMedia = (id,formData) => API.put(`v1/admin/masjid/updateMasjidProfileMedia/`+ id ,formData);
export const deleteMasjidProfileMedia = (id,formData) => API.put(`v1/admin/masjid/deleteMasjidProfileMedia/`+ id,formData);
export const freezeMasjid = (id) => API.post(`v1/freeze/masjid`,null,{ params: { id}
});
export const unfreezeMasjid = (id) => API.delete(`v1/freeze/masjid/`,{ params: { id }
});
export const fetchFreezeMasjids = () => API.get(`v1/freeze/masjids`);
export const fetchFreezeMasjidByName = (masjidName) => API.get(`v1/freeze/masjids`,{
    params:{
        masjidName:`${masjidName}`,
    }
});


export const fetchEvents = () => API.get(`v1/events/getAllEvents`);
export const fetchLatestUpdatedEvents = () => API.get(`v1/events/getLatestEditedEvents`);
export const fetchLatestUpdatedEventsByAdminId = (id) => API.get(`v1/events/getLatestEventsByUser/`+ id);
export const fetchMasjidEvents = (formData) => API.post(`v1/events/getEventsByMasjid`,formData);
export const fetchEventId = (id) => API.get(`v1/events/getEventById/` + id );
export const fetchEventByMasjidId = (id) => API.get(`v1/events/getEventsByMasjidId/` + id );
export const addEvent = (formData) => API.post(`/v1/admin/events/createEvent`,formData);
export const updateEvent = (formData,id) => API.put(`v1/admin/events/updateEvent/` + id , formData);
export const deleteEvent = (id) => API.delete(`/v1/admin/events/deleteEvent/`+ id);
export const addEventGuest = (formData,id) => API.put(`/v1/admin/events/updateEvent/`+ id ,formData);
export const updateEventGuest = (formData,id) => API.put(`v1/admin/events/updateEventGuest/` + id , formData);
export const deleteEventGuest = (formData,id) => API.put(`/v1/admin/events/deleteEventGuest/`+ id,formData);
export const addEventMedia = (id,formData) => API.post(`/v1/admin/events/addEventMedia/`+ id ,formData);
export const deleteEventMedia = (id,formData) => API.put(`/v1/admin/events/deleteEventMedia/`+ id,formData);
export const addEventProfileMedia = (id,formData) => API.post(`v1/admin/events/addEventProfileMedia/`+ id ,formData);
export const updateEventProfileMedia = (id,formData) => API.post(`v1/admin/events/updateEventProfileMedia/`+ id ,formData);
export const deleteEventProfileMedia = (id,formData) => API.post(`v1/admin/events/deleteEventProfileMedia/`+ id,formData);

export const RegisterAdmin = (formData) => API.post(`v1/auth/adminRegister`,formData);
export const LoginAdmin = (formData) => API.post(`v1/auth/adminLogin`,formData);
export const ForgotPassword = (formData) => API.post(`v1/auth/adminForgotPassword`,formData);
export const ResetPassword = (formData) => API.post(`v1/auth/adminResetPassword`,formData);
export const setPasswordNewUser = (formData) => API.post(`v1/auth/verifyNewUser`, formData);
export const ActivatingTwoFactorAuth = () => API.put(`v1/auth/activatingTwoFactorAuth`);
export const DeactivatingTwoFactorAuth = (id) => API.put(`v1/auth/deactivatingTwoFactorAuth/`+ id);
export const VerifyingTwoFactorAuth = (formData,id) => API.post(`v1/auth/verifyingTwoFactorAuth`,formData);
export const getAllAdmin = () => API.get(`v1/admindetails/getAllAdmins`);
export const getAdminByName = (adminName) => API.get(`v1/admindetails/getAdminByName/`+adminName);
export const updateAdminById = (formData,id) => API.put(`v1/admindetails/updateAdminById/` + id , formData);
export const updateAdminMasjidById = (formData) => API.put(`v1/admindetails/updateMasjidinAdmin`, formData);
export const deleteAdminMasjidById = (formData) => API.put(`v1/admindetails/deleteMasjidInAdmin`, formData);
export const freezeAdmin = (id) => API.post(`v1/freeze/admin/`,null,{ params: {
    id
  }});
export const unfreezeAdmin = (id) => API.delete(`v1/freeze/admin/`,{ params: {
    id
  }});
export const fetchFreezeAdmins = () => API.get(`v1/freeze/admins`);
export const fetchFreezeAdminsByAdminEmail = (email) => API.get(`v1/freeze/admins?email=rehaantestaccdatasentry@gmail.com`,{
    params:{
        email:`${email}`,
    }
});


export const getTimingsByMasjid = (id) => API.get(`v1/timings/getTimingByMasjid/`+id);
export const getTimingsById = (id) => API.get(`v1/timings/getTimingById/`);
export const getTimingByStartRange = (date,masjidId) => API.get(`v1/timings/getTimingByStartRange/` + masjidId,{ 
    params:{
        startDate:`${date}`,
    }
});
export const getTimingByDateRange = (startDate,endDate,masjidId) => API.get(`v1/timings/getTimingByDateRange/` + masjidId,{ 
    params:{
        startDate:`${new Date(startDate)}`,
        endDate:`${new Date(endDate)}`,
    }
});
export const getDatesByStartRange = (date,masjidId) => API.get(`v1/timings/getDatesByStartRange/` + masjidId,{ 
    params:{
        startDate:`${date}`,
    }
});
export const getTimingsByDate = (date,masjidId) => API.get(`v1/timings/getTimingByDate/` + masjidId ,{ 
    params:{
        date:`${date}`,
    }
});
export const addTimingRange = (formData) => API.post(`v1/admin/timings/addTimingRange`,formData);
export const updateTimingByRange = (formData,masjidId ) => API.put(`v1/admin/timings/updateTimingByRange/`+ masjidId ,formData);
export const deletingTimingsByDateRange = (startDate,endDate,masjidId,lastEditor) => API.put(`v1/admin/timings/deleteTimingByRange/` + masjidId ,null,{ 
    params:{
        startDate:`${startDate}`,
        endDate:`${endDate}`,
        lastEditor:`${lastEditor}`
    }
});
export const addTimings = (formData) => API.post(`v1/admin/timings/addTiming`,formData);
export const getTimingsByMasjidName = (masjidName) => API.get(`v1/masjid/getMasjid/`+ masjidName);
export const updateNamaz = (formData,id) => API.put(`v1/admin/timings/updateNamaz/${id}`  , formData);
export const updateTiming = (formData,id) => API.put(`v1/admin/timings/updateTiming/` + id , formData);
export const updateBulkTiming = (formData,id) => API.put(`v1/admin/timings/updateBulkTiming/` + id , formData);
export const deleteTiming = (id ) => API.delete(`/v1/admin/timings/deleteTiming/`+ id);
export const deleteNamaz = (id,formData) => API.put(`/v1/admin/timings/deleteNamaz/`+ id , formData);
export const deleteAllJummah= (formData) => API.put(`/v1/admin/timings/deleteRandomTiming` , formData);
export const updateAllJummah= (formData) => API.put(`/v1/admin/timings/updateRandomTiming` , formData);
export const addingNewNamaz = (formData) => API.post(`/v1/admin/timings/addRandomTiming`, formData);


export const triggeringAnnouncement = (formData) => API.post(`/v1/notification/announcement`, formData);
export const updateNotification = (formData,type,id) => API.post(`/v1/notification/single/${type}/${id}`, formData);
export const updateTimingNotification = (formData,timingId,type,masjidId) => API.post(`/v1/notification/timing/${masjidId}/${type}/${timingId}`, formData);



