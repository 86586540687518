import { UPDATE_NAMAAZ } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const UpdateNamazReducer =  (updatedTimings = [] , action) => {

   switch (action.type) {

    case UPDATE_NAMAAZ:

      return action.payload;;

    default:
      return updatedTimings
  }
};

export default UpdateNamazReducer;