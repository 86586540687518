import React,{useState,useEffect} from 'react'
import { DataGrid,GridToolbar} from '@mui/x-data-grid';
import moment from 'moment';
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux';
import { darken, lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {getStaleMasjids} from '../../../Redux/Actions/MasjidActions/FetchingStaleMasjids.js'
import { useDispatch } from 'react-redux';

const today = moment();

  const userColumns = [
    {
      field: "masjidName",
      headerName: "Masjid Name",
      autoComplete: 'off',
      width: 280,
      renderCell: (params) => {
        return <Link to={"/masjid/" + params.row.masjidName}>{params.row.masjidName}</Link>;
      },
    },
    {
      field: "updatedAt",
      headerName: "Last Updated On",
      autoComplete: 'off',
      width: 220,
      renderCell: (params) => {
        return <div className="dataUpdatedAt">{moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}</div>;
      },
    },
    {
      field: "Days Before",
      headerName: "Days since last update",
      autoComplete: 'off',
      width: 100,
      renderCell: (params) => {
        return <div>{ moment(today.format()).diff((moment(params.row.updatedAt)),'days')}</div>;
      },
    },
    {
      field: "lastEditor",
      headerName: "Last Updated By",
      width: 200,  
      editable:"never",
    },
    {
      field: "contact",
      headerName: "Contact",
      autoComplete: 'off',
      width: 170,
    },
    {
      field: "address",
      headerName: "Address",
      width: 240,
      autoComplete: 'off',
    },
    {
      field: "country",
      headerName: "Country",
      autoComplete: 'off',
      width: 130,
    },
  ]

  const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);


const StaleMasjidTable = ({masjids}) => {
  const staleMasjids  = useSelector((state) => state.staleMasjids);
  const currentAdmin  = useSelector((state) => state.currentAdmin);
  const [Masjids, setMasjids] = useState(staleMasjids)
  const admin = JSON.parse(localStorage.getItem("admin"));
  const dispatch = useDispatch();


  useEffect(() => {
  

    if( admin.role ==="superadmin" || admin.role === "dataentry"){
      // const response =  dispatch(getStaleMasjids());
     
      // response.then(function(result) {
      // if(result.success){
      //   setMasjids(result.data);
      //   }
      // });
    }else{
      setMasjids(masjids)
    }
  }, []);

  // console.log(masjids);


  return (
    <div className="StaleMasjidTableContainer">
     <Box
      sx={{
        height: 400,
        width: '100%',
        '& .super-app-theme--Open': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.success.main, theme.palette.mode),
        },
        '& .super-app-theme--Filled': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
        },
        '& .super-app-theme--PartiallyFilled': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.warning.main, theme.palette.mode),
        },
        '& .super-app-theme--Rejected': {
          bgcolor: (theme) =>
            getBackgroundColor(theme.palette.error.main, theme.palette.mode),
        },
      }}
    > 
    <div style={{ height: 800, width: '150%',backgroundColor:'White' }}>
      <DataGrid
        rows={(admin.role ==="superadmin" || admin.role === "dataentry")?staleMasjids:masjids}
        columns={userColumns}
        pageSize={12}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick 
        components={{ Toolbar: GridToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowClassName={(params) => {
          if(moment(today.format()).diff((moment(params.row.updatedAt)),'days')>7){
            return `super-app-theme--Filled`
          }else{
            return `super-app-theme--Open`
          }
        }}
      />
    </div>
      </Box>
    </div>
  )
}

export default StaleMasjidTable