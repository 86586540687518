import { GET_ALL_ADMIN } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const FetchingAdmins = () => async(dispatch) => {

    try{

      const { data } = await api.getAllAdmin();
      if(data.success)
      {
         dispatch({type:"GET_ALL_ADMIN" , payload: data.data})
         return data;
       }
     return data;
      
      

    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response.data ?error.response.data.message:"Fetching Masjid Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 

   }
}