import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const FetchEventByMasjidId = (eventId) => async(dispatch) => {


    try{
    
      const { data } = await api.fetchEventByMasjidId(eventId);

      
      if(data.success)
      {
         return data;
       }
     return data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response?.data ?error.response?.data.message:"Fetching Event Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 

      return error.response;

   }
}