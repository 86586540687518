import React,{useState,useEffect,useRef} from 'react'
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';                                                              
import PictureCarouselComponent from '../../../Components/ProfileComponents/PictureCarouselComponent/PictureCarouselComponent.jsx';
import { useParams } from "react-router-dom";
import {useSelector} from 'react-redux';
import { useDispatch } from 'react-redux';
import "./AdminProfile.scss";
import moment from 'moment';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { KeyboardTimePicker } from "@material-ui/pickers";
import { KeyboardDatePicker,DatePicker  } from "@material-ui/pickers";
import { TimePicker } from "@material-ui/pickers";
import makeStyles from "@material-ui/styles/makeStyles";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import * as api from '../../../APICalls/index.js';
import { TiUserAdd } from "react-icons/ti";
import { TiTimes } from "react-icons/ti";
import { TiPencil } from "react-icons/ti";
import {getMasjids} from '../../../Redux/Actions/MasjidActions/FetchingMasjids.js';
import {FreezeAdmin} from '../../../Redux/Actions/AdminActions/FreezingAdmin.js';
import {FetchingAdminByName} from '../../../Redux/Actions/AdminActions/FetchingAdminByName.js';
import {UpdatingAdminById} from '../../../Redux/Actions/AdminActions/UpdatingAdminById.js'
import {UpdateAdminMasjidById} from '../../../Redux/Actions/AdminActions/UpdatingAdminMasjidById.js'
import {DeleteAdminMasjidById} from '../../../Redux/Actions/AdminActions/DeleteAdminMasjidById.js'
import {ActivatingTwoFactorAuth} from '../../../Redux/Actions/AuthActions/ActivateTwoFactorAuth.js'
import {DeactivatingTwoFactorAuth} from '../../../Redux/Actions/AuthActions/DeactivateTwoFactorAuth.js'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import { CircularProgress } from "@material-ui/core";
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { AiOutlineInsertRowLeft } from "react-icons/ai";
import { TiUserDelete } from "react-icons/ti";
import { MdOutlineFontDownloadOff } from "react-icons/md";
import { CgPlayListRemove } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiButtonBase-root": {
        padding: 0,
      }
    }
  }
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, masjidName, theme) {
  return {
    fontWeight:
      masjidName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];



const AdminProfile = (props) => {
    let navigate = useNavigate();
    let newAdmin = useSelector((state) => state.newAdmin);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const { adminName } = useParams();
    const dispatch = useDispatch();
    const [Admin, setAdmin] = useState([]);
    const sidebarState = useSelector((state) => state.sidebarState);
    const admins  = useSelector((state) => state.allAdmins);
    let AdminNameRef = useRef(Admin? Admin?.adminName : admin?.adminName);
    let AdminEmailRef = useRef(Admin? Admin?.email : admin?.email);
    let AdminPasswordRef = useRef(null);
    const masjids  = useSelector((state) => state.masjids);
    const [OpenEditModal, setOpenEditModal] = useState(false);
    const [OpenQRModal, setOpenQRModal] = useState(false);
    const [isUpdating, setisUpdating] = useState(false);
    const [Checked, setChecked] = useState(admin && admin.isTwoFactorAuthentication);
    const [PrefillingChecked, setPrefillingChecked] = useState(admin && admin.autoPrefillingTiming);
    const [QRCode,setQRCode] = useState('');
    const [isFetching, setisFetching] = useState(false);
    const [isPrefillingFetching, setisPrefillingFetching] = useState(false);
    const theme = useTheme();
    let masjidNames = [];
    const [masjidName, setMasjidName] = React.useState([]);
    const [NewmasjidName, setNewMasjidName] = React.useState([]);
    const [InitialMasjids, setInitialMasjids] = React.useState([]);
    const [isFreezing, setisFreezing] = useState(false);
    const [FreezeModalOpen, setFreezeModalOpen] = useState(false);

    const handleChange = (event) => {
      setMasjidName(typeof value === 'string' ? event.target.value.split(',') : event.target.value);
    };


 
    useEffect(() => {
      setisFetching(true);
      const response =  dispatch(FetchingAdminByName(adminName));
      response.then(function(result) {
      if(!result.success){
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:result.message ? "Failed to Load Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
          }
          setisFetching(false);
          setMasjidName([])
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        }
        else{
          setisFetching(false);
          setAdmin(result.data[0]);
          setInitialMasjids(result.data[0].masjids)
          if(masjidName.length<1){
            result.data[0]?.masjids.length>0 && result.data[0]?.masjids.map( (item) => {
              masjidName.push(typeof value === 'string' ? item.split(',') : item);
            })
          }
         }
       });
      }, [dispatch, adminName]);


      
      const handleModalUpdate = () => {
        setOpenEditModal(true);
      }
      
      const handleModalUpdateClose = () => {
        setOpenEditModal(false);
      }

      const handleQRClose = () => {
        setOpenQRModal(false);
      }

      const handleUpdateTwoFactor = () => {
        setisFetching(true);
        let formData = {
          isTwoFactorAuthentication: "true"
        } 

        const response =  dispatch(ActivatingTwoFactorAuth(formData,admin._id));
        response.then(function(result) {
          if(!result.success){
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Change Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
            }
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            setAdmin(result.data);
            setQRCode(result.QR);
            setChecked(!Checked);
            setOpenQRModal(true);
            setisFetching(false);
           }
         })
      }

      const handleRemoveTwoFactor = () => {
        setisFetching(true);
        const response =  dispatch(DeactivatingTwoFactorAuth(admin._id));
        response.then(function(result) {
          if(!result.success){
            
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Change Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
            }
            
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            setAdmin(result.data);
            setChecked(!Checked);
            setisFetching(false)
           }
         })
      }

      const handleDeActivateAutoPrefillingTimes = () => {
        setisPrefillingFetching(true);
        let formData = {
          autoPrefillingTiming: "false"
        } 

        const response =  dispatch(UpdatingAdminById(formData,admin._id));
        response.then(function(result) {
          if(!result.success){
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Load Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
            }
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            setAdmin(result.data);
            setPrefillingChecked(!PrefillingChecked);
            setisPrefillingFetching(false)
           }
         })
      }

      const handleUpdateTwoFactorAutoPrefillingTimes = () => {
        setisPrefillingFetching(true);
        let formData = {
          autoPrefillingTiming: "true"
        } 
        const response =  dispatch(UpdatingAdminById(formData,admin._id));
        response.then(function(result) {
          if(!result.success){
            
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Load Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
            }
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            setAdmin(result.data);
            setPrefillingChecked(!PrefillingChecked);
            setisPrefillingFetching(false);
           }
         })
      }




      const handleAdmin = () => {
        let updatedData = {
          adminName:AdminNameRef.current.value,
          email:AdminEmailRef.current.value,
          role:admin.role,
        } 
        const response =  dispatch(UpdatingAdminById(updatedData,admin._id));
        response.then(function(result) {
          
          if(!result.success){
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Load Admin Details : " + result.message : "Failed to Load Masjid Details : Internet or Server Issue "
            }
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            setAdmin(result.data);
             setOpenEditModal(false);
           }
         });
      }

      const handleUpdationAdmin = () => { 
        let Ids = [];

        masjidName.map((masjidNme) => {          
          let masjid = masjids.filter((item) => item.masjidName === masjidNme);
          Ids.push(masjid[0]._id)
        });

        let UploadData = {
          "user":Admin._id,
          "masjidIds":Ids
        }

        const response =  dispatch(UpdateAdminMasjidById(UploadData));
        response.then(function(result) {

        if(!result.success){
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Update Admin Details : " + result.message : "Failed to Update Masjid Details : Internet or Server Issue "
            }
            // setisFetching(false);
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            // setisFetching(false);
          }
         });
      } 


      const handleRemovalMasjidAdmin = () => { 
        
       let removalMasjids = [];
       let Ids = [];

        InitialMasjids.filter((item) => {
          
          if(!(masjidName.includes(item))){
           removalMasjids.push(item);
          } 
        })

        let masjidDatas = masjids.filter(item => removalMasjids.includes(item.masjidName));
        masjidDatas.map((item) => Ids.push(item._id));

        
        let UploadData = {
          "user":Admin._id,
          "masjidIds":Ids
        }
        
         
        const response =  dispatch(DeleteAdminMasjidById(UploadData));
        response.then(function(result) {

        if(!result?.success){
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:result.message ? "Failed to Update Admin Details : " + result.message : "Failed to Update Masjid Details : Internet or Server Issue "
            }
            // setisFetching(false);
            dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          }
          else{
            // setisFetching(false);
          }
         });
      } 

      const handleFreeze = () => {
        setFreezeModalOpen(false)
     }
  
      const handleFreezeAdmin = () => {
        setisFreezing(true);
        const response = dispatch(FreezeAdmin(Admin._id));
       
          response.then(function(result) {
  
          if(result.success)
          { 
            const snackbarSuccessDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:"Freezed The Admin Successfully"
            }       
            dispatch(ChangeSnackbar(snackbarSuccessDetails));
            
            setisFreezing(false);
            setFreezeModalOpen(false)
            navigate("/freezeAdmins");
          }
          else{
  
            const snackbarFailureDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
                snackbarMessage:"Couldn't update the Admin Profile : " + result.message
               }
               setisFreezing(false);
          setFreezeModalOpen(false)
            dispatch(ChangeSnackbar(snackbarFailureDetails));
          }
        });
     }



    return (
      <div className="HomeContainer2">
      {
          sidebarState ?
          (<div className="LeftContainer">
            <SideBar/>
          </div>):
           (<div className="LeftContainerClose">
           <SideBar/>
         </div>)
       } 
      <div className="RightContainer2">
       <div className="RightTopContainer">
       <TopBar/>
       </div>
      <div className="single2">
        <div className="singleContainer2">
          {
             isFetching && (<><span className="LoadingMessage">Fetching The Admin Data   <CircularProgress  size="20px" style={{marginLeft:"10px",marginTop:"5px"}} /></span></>)
            }
          <div className="top">
          <Dialog open={FreezeModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to freeze the Admin ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={handleFreezeAdmin} variant="outlined"  >  { isFreezing ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                 <Button onClick={handleFreeze} variant="outlined"  disabled={isFreezing}>No</Button>
              </DialogActions>
          </Dialog>
            <div className="left">
              {(admin.role ==="superadmin") && ( <> <Button onClick={(e) => setFreezeModalOpen(true)}  style={{ border:"1px solid red",color:"red",height:"20px",fontSize:"10px",marginLeft:"3rem",marginBottom:"10px",cursor:"Pointer"}}>Freeze Admin</Button></>)}
              <div className="item">
                <div className="details">
                {
                Admin?._id ? (
                  <>
                  <h1 className="itemTitle">{Admin && Admin?.adminName}
                   {
                      admin._id ===  Admin?._id && (
                      <Button variant="outlined"  style={{ maxWidth: "100px",maxHeight:"25px",fontSize:"11px",fontWeight:"bold",marginLeft:"1rem"}} onClick={handleModalUpdate} className ="btn-edit">
                           Edit Admin
                      </Button>
                      )
                 }

            <Dialog open={OpenQRModal} height={"xl"} >
                <DialogTitle>Scan this QR CODE with Google Authenticator App</DialogTitle>
                    <DialogContent>
                    <Box
                        component="img"
                        sx={{
                          height: 260,
                          width: 270,
                          marginLeft:10,
                        }}
                        alt="Qr Code"
                        src={QRCode}
                      />
                      </DialogContent>
                    <DialogActions>
                  <Button onClick={handleQRClose} style={{color:'grey'}} >Cancel</Button>
                  </DialogActions>
                </Dialog>
                  </h1>
                  <div className="detailItem">
                    <span className="itemKey">Email : </span>
                    <span className="itemValue">{Admin && Admin?.email ? Admin?.email  :<span className="itemKey2"> {"No Email available for the Admin"}</span>}
                    </span>
                  </div>
                  <div className="detailItem">
                    <span className="itemKey">Role :</span>
                    <span className="itemValue">
                    {Admin ? Admin?.role  : Admin?.role}
                    </span>
                  </div>
                  <Dialog open={OpenEditModal} height={"xl"} >
                <DialogTitle>Edit Admin</DialogTitle>
                    <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Admin Name"
                        sx={{  marginBottom: 3 }}
                        type="text"
                        fullWidth
                        defaultValue={Admin?.adminName}
                        inputRef={AdminNameRef}
                        variant="standard"
                      />
                    <TextField
                      id="outlined-required"
                      variant="standard"
                      label="Admin Email"
                      fullWidth
                      defaultValue={Admin?.email}
                      inputRef={AdminEmailRef}
                      type="email"
                      sx={{  marginBottom: 3 }}
                      /> 
                      </DialogContent>
                    <DialogActions>

                  <Button onClick={handleModalUpdateClose} style={{color:'grey'}} >Cancel</Button>
                    <Button onClick = {handleAdmin}> Update </Button>
                  </DialogActions>
                </Dialog>

                  {/* {
                   admin._id ===  Admin?._id && ( Checked ? ( 
                    <>
                    <div className="detailItem">
                      <span className="itemKey">2 Factor Authentication : ✅</span>
                      <div className="detailItem">
                      <span className="itemKey">Disable</span>
                        <Switch
                        checked={Checked}
                        onChange={handleRemoveTwoFactor}
                        name="loading"
                        color="primary"
                        />
                        {isFetching && (
                          <CircularProgress color="white" size="20px" />) 
                        }
                      </div>
                     </div>
                     </>  ):( 
                     <>
                   <div className="detailItem">
                   <span className="itemKey">Enable Two Factor Authentication</span>
                     <Switch
                      checked={Checked}
                      onChange={handleUpdateTwoFactor}
                      name="loading"
                      color="primary"
                    />
                     {isFetching && (
                          <CircularProgress color="white" size="20px" />) 
                        }
                    </div>
                      </>
                      )
                    )
                  } */}

                  <div className="detailItem">
                  {
                   admin._id ===  Admin?._id && ( PrefillingChecked ? ( 
                    <>
                    <div className="detailItem">
                      <span className="itemKey">Auto Profiling Times : ✅</span>
                      <div className="detailItem">
                      <span className="itemKey">Disable</span>
                        <Switch
                        checked={PrefillingChecked}
                        onChange={handleDeActivateAutoPrefillingTimes}
                        name="loading"
                        color="primary"
                        />
                        { isPrefillingFetching && (
                          <CircularProgress color="white" size="20px" />) 
                        }
                      </div>
                     </div>
                     </>  ):( 
                     <>
                   <div className="detailItem">
                   <span className="itemKey">Enable Auto Profiling Times</span>
                     <Switch
                      checked={PrefillingChecked}
                      onChange={handleUpdateTwoFactorAutoPrefillingTimes}
                      name="loading"
                      color="primary"
                    />
                     {isPrefillingFetching && (
                          <CircularProgress color="white" size="20px" />) 
                        }
                    </div>
                      </>
                      )
                    )
                  }
                  </div>
                  
                  <div className="detailItem">
                    <b><span className="itemKey">Created At :</span></b>
                    <span className="itemValue">
                    {Admin ? moment(Admin?.createdAt).format("DD/MM/YYYY"):<span className="itemKey2"> {"Last Update is not Available"} </span>}
                    </span>
                  </div>
                  <div className="detailItem">
                  <b><span className="itemKey">Last Updated At :</span></b>
                    <span className="itemValue">
                    {Admin ? moment(Admin?.updatedAt).format("DD/MM/YYYY"): <span className="itemKey2">  </span>}
                    </span>
                    </div>
                    </>
                  ):
              (
                <>
                  <div className="skeleton-placeholder-title" />
                  <div className="skeleton-placeholder-title2" />
                  <div className="skeleton-placeholder-title2" />
                  <div className="skeleton-placeholder" />
                  <div className="skeleton-placeholder" />
                </>
              )
            }
                   </div>
                </div>

                <div className="right">

              </div>

              <div>

                {
                  (!(Admin.role ==="superadmin" || Admin.role === "dataentry")) && (<FormControl sx={{ m: 1, width: 300 }}>
                    {/* <InputLabel id="demo-multiple-chip-label">Masjid</InputLabel> */}
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      label="masjid"
                      value={masjidName}
                      onChange={handleChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {masjids?.map((name) => (
                        <MenuItem
                          key={name._id}
                          value={name.masjidName}
                          style={getStyles(name, masjidName, theme)}
                          >
                        
                          {name.masjidName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>)
                  }
                  {

                (!(Admin.role ==="superadmin" || Admin.role === "dataentry")) &&(<div className="operationButtons">
                  <button className="adddingMasjid" disabled={masjidName.length<1} onClick={handleUpdationAdmin}>  
                  <span class="text">
                    {
                      false  ? (
                        <CircularProgress  size="20px" />
                        ) : ("Add Masjids")
                    }
                   </span>
               </button>
                  <button className="RemovingMasjid" disabled={masjidName.length<1} onClick={handleRemovalMasjidAdmin}> 
                  {
                    false  ? (
                      <CircularProgress  size="20px" />
                      ) : ("Update Masjids")
                    }
                  </button>
                  </div>)
                    }
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
  )
}

export default AdminProfile;