import { ADD_EVENT } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const AddEventReducer =  (newEvent = [] , action) => {

   switch (action.type) {

    case ADD_EVENT:

      return action.payload;;

    default:
      return newEvent;
  }
};

export default AddEventReducer;