import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();
const baseUrl = 'https://api.locationiq.com/v1/autocomplete.php?key=pk.31d65a02fb607283b45c94780cafbbba&q=${value}&limit=6}';

export const StateContextProvider = ({ children }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const getResults = async (value) => {
    setLoading(true);

    try {
        await fetch(`https://api.locationiq.com/v1/autocomplete.php?key=pk.31d65a02fb607283b45c94780cafbbba&q=${value}&limit=6}`)
        .then(res => res.json())
        .then(json => setResults(json));
      } catch (error) {
         
          setResults([]);
        }

    // const res = await fetch(`${baseUrl}${url}`, {
    //   method: 'GET',
    //   headers: {
    //     'x-rapidapi-host': 'google-search3.p.rapidapi.com',
    //     'x-rapidapi-key': process.env.REACT_APP_API_KEY,
    //   },
    // });

    // const data = await res.json();

    setLoading(false);
  };

  return (
    <StateContext.Provider value={{ getResults, results, searchTerm, setSearchTerm, loading }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
