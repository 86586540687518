import { UPDATE_EVENT } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const UpdateEventReducer =  (updatedMasjid = [] , action) => {

   switch (action.type) {

    case UPDATE_EVENT:

      return action.payload;;

    default:
      return updatedMasjid;
  }
};

export default UpdateEventReducer;