import React from 'react';
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';
import FreezeAdminTable from '../../../Components/FeedComponents/FreezeAdminTable/FreezeAdminTable';
import {useSelector} from 'react-redux';

const FreezeAdmins = () => {
  const sidebarState = useSelector((state) => state.sidebarState);


  return (
    <>
     <div className="HomeContainer">
     {
       sidebarState ?
       (<div className="LeftContainer">
         <SideBar/>
       </div>):
        (<div className="LeftContainerClose">
        <SideBar/>
      </div>)
     }
     <div className="RightContainer">
      <div className="RightTopContainer">
      <TopBar/>
      </div>
      <div className="RightFeedContainer">
       <FreezeAdminTable/>
      </div>
     </div>
    </div>
    </>
  )
}

export default FreezeAdmins