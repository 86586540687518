import * as api from '../../../APICalls/index.js';
import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const resetPasswordInitial = (formData) => async(dispatch) => {

    try{  

      const {data}  = await api.setPasswordNewUser(formData);


      if(data.success){

        const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"success",
             snackbarMessage:`Your Password has been set`
          }  

        dispatch(ChangeSnackbar(snackbarDetails));

        localStorage.setItem("authTokens", JSON.stringify(data.data));

        return data;

      }

      return data;
    }    
    catch(error)
   {
      console.log(error);
      if(error.message){
         const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:`Failed To Add Timings: ${error}`
          }  

             dispatch(ChangeSnackbar(snackbarDetails));
      }
      return error;

   }
}