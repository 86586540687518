
import { Link } from "react-router-dom";

   export const MasjidColumns = [
      {
         field: "masjidName",
         headerName: "Masjid Name",
         autoComplete: 'off',
         width: 300,
         renderCell: (params) => {
          return <Link to={"/masjid/" + params.row.masjidName}>{params.row.masjidName}</Link>;
        },
       },
       {
         field: "contact",
         headerName: "Contact",
         autoComplete: 'off',
         width: 150,
       },
       {
         field: "lastEditor",
         headerName: "Last Updated By",
         width: 120,  
         editable:"never",
       },
       {
         field: "address",
         headerName: "Address",
         width: 350,
         autoComplete: 'off',
       },
       {
         field: "country",
         autoComplete: 'off',
         headerName: "Country",
         width: 150,
       },
       {
         field: "Latitude",
         headerName: "Latitude",
         width: 150,
         renderCell: (params) => {
          return <div className="dataUpdatedAt">{params.row.location.coordinates[1].toString().substr(0, 12)}</div>;
        },
       },
       {
         field: "Longitude",
         headerName: "Longitude",
         width: 150,
         renderCell: (params) => {
          return <div className="dataUpdatedAt">{params.row.location.coordinates[0].toString().substr(0, 12)}</div>;
        },
       },
   
  ]
