import React,{useEffect,useCallback,useRef,useState} from 'react'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux';
import './AllStaleMasjidTable.css';
import {getStaleMasjids} from '../../../Redux/Actions/MasjidActions/FetchingStaleMasjids.js';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const today = moment();



const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };


  const userColumns = [
    {
      field: "masjidName",
      title: "Masjid Name",
      autoComplete: 'off',
      width: 200,
      render: rowData => (
        <div className="cellAction">
        <Link to={"/masjid/" + rowData.masjidName} style={{ textDecoration: "none" }} className="cellAction">
          {rowData.masjidName}
        </Link>
      </div>
      ),
    },
    {
      field: "updatedAt",
      title: "Last Updated On",
      autoComplete: 'off',
      width: 200,
      render: data => (
        <div className="cellAction">
          { moment(data.updatedAt).format("DD/MM/YYYY  hh:mm:a") }
      </div>
      ),
    },
    {
      field: "updatedAt",
      title: "Days since last update",
      autoComplete: 'off',
      width: 200,
      render: data => (
        <div className="cellAction">
          { moment(today.format()).diff( (moment(data.updatedAt)) , 'days') }
      </div>
      ),
    },
    {
      field: "lastEditor",
      title: "Last Updated By",
      width: 200,  
      editable:"never",
    },
    {
      field: "contact",
      title: "Contact",
      autoComplete: 'off',
      width: 200,
      render: data => (
        <div className="cellAction">
          {data.contact}
      </div>
      ),
    },
    {
      field: "address",
      title: "Address",
      width: 100,
      autoComplete: 'off',
    },
    {
      field: "country",
      autoComplete: 'off',
      title: "Country",
      width: 30,
    },
  ]

const AllStaleMasjidTable = ({masjids}) => {

  const defaultMaterialTheme = createTheme();
  const [TableLoading, setTableLoading] = useState(false);
  const [StaleData, setStaleData] = useState([]);
  const [AdminStaleData, setAdminStaleData] = useState([]);
  const staleMasjids  = useSelector((state) => state.staleMasjids);
  const dispatch = useDispatch(); 

  let newAdmin = useSelector((state) => state.newAdmin);
  const admin = JSON.parse(localStorage.getItem("admin"));

  



  const today = moment();

  useEffect(() => {
      setTableLoading(true);
       const response = dispatch(getStaleMasjids());
       response.then(function(result) {

       if(result?.success)
       {
        setTableLoading(false);
         setStaleData(result.data); 
        }
        else 
        {
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:result.message ?result.message:"Couldn't Fetch Stale Masjids",
          }
        setTableLoading(false);
        }
      })
   }, [dispatch]);

   console.log(masjids);

  return (
    <>
    <div className="StaleDatatableTitle">
    Masjids are arranged according to time from last update in them
     </div>
    <div className="StaleMasjidTable">
    <ThemeProvider theme={defaultMaterialTheme}>
     {/* <MaterialTable title="Stale Masjids"
       icons={tableIcons}
       isLoading={TableLoading}
       data={masjids && masjids}
       columns={userColumns}
           options={{
               exportButton:true,
               pageSize: 10,
               rowStyle: rowData => ({
                   backgroundColor: (moment(today.format()).diff( (moment(rowData.updatedAt)) , 'days') < 20)  ? ( moment(today.format()).diff( (moment(rowData.updatedAt)) , 'days') > 10 ? '#EBD428':'#91e654') : '#ff7d76'
                  })
                }}
                /> */}
        </ThemeProvider>
      </div>
      
   </>
  )
}

export default AllStaleMasjidTable