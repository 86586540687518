import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const updateEventGuest = (formData,id) => async(dispatch) => {


    try{
      const { data } = await api.updateEventGuest(formData,id);
     
      if(data.success)
      {
        const snackbarDetails = {
            snackbarOpen:true, 
            snackbarType:"success",
              snackbarMessage:"Updated Guest Successfully "
             }       
   
           dispatch(ChangeSnackbar(snackbarDetails)); 
        return data;
      }

      return data;

    }    
    catch(error)
   {
        const snackbarFailureDetails = {
         snackbarOpen:true, 
         snackbarType:"error",
           snackbarMessage:"Couldn't Update the guest : " + error.message
          }       

        dispatch(ChangeSnackbar(snackbarFailureDetails)); 

      return error.response.data;


   }
}