import React,{useEffect,useState} from 'react'
import { Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {FetchingLatestUpdatedEvents} from '../../../Redux/Actions/EventsActions/FetchingLatestUpdatedEvents.js'
import {FetchingLatestUpdatedEventsById} from '../../../Redux/Actions/EventsActions/FetchingLatestUpdatedEventsById.js'
import { useDispatch } from 'react-redux';
import AllStaleMasjidTable from '../../../Components/PageComponents/AllStaleMasjidTable/AllStaleMasjidTable';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import EventCard from "../EventsCarousel/EventsCarousel";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import './EventCarousel.css';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { GrMapLocation } from "react-icons/gr";

  const EventsCarousel = () => {
    
    const admin = JSON.parse(localStorage.getItem("admin"));
    const dispatch = useDispatch();
    const LatestEvents  = useSelector((state) => state.LatestEvents);
    const LatestAdminEvents  = useSelector((state) => state.LatestAdminEvents);
   
    
    const OpenGoogleMaps = (Latitude,Longitude) => {
      window.open(`https://maps.google.com?q=${Latitude},${Longitude}`);
    }; 

   
    return (
        <div className="EventsSectionTop">
          <span className="EventSectionTitle">{ (admin.role ==="superadmin" || admin.role === "dataentry") ? "Latest Updated Events" : "Latest Updated Events By you"}</span>
            <div className="EventRoll">
            { 
          (admin.role ==="superadmin" || admin.role === "dataentry") ?
            ( LatestEvents?.length>0 ? LatestEvents.slice(0,4).map((item) => {
              return (
                <>
                    <div className="cardTop">
                    <Link to={"/events/" + item._id} style={{ textDecoration: "none" }}>
                        <span className="cardTitle"> {item.eventName}  </span>
                        </Link>
                        <span className="timings"> { moment(item.timings[0].startDate).format("DD/MM/YYYY") } - { moment(item.timings[0].endDate).format("DD/MM/YYYY")}</span>
                        <span className="timings"> { moment(item.timings[0].startTime).format("hh:mm A") } - {  moment(item.timings[0].endTime).format("hh:mm A")}</span>
                        <div className="EventsLocation"> 
                          <span className="locTitle">Location: </span>
                            <span className="masjidTitle"> {item.masjidName} </span>
                              <span className="masjidTitle"><GrMapLocation size={'1rem'} style={{cursor:"pointer",}} onClick={(e) => OpenGoogleMaps(item.location.coordinates[0],item.location.coordinates[1])}/>   </span>
                        </div>
                        <span className="addressTitle">  {item.description} </span>
                    </div>
                </>
                )
              }):(
                <>
                    <div className="EventsContainer">
                      <h5 style={{ marginLeft:'10px'}}>Sorry!! No Events Found For you</h5>
                    </div>   
                </>
              )):(
                LatestAdminEvents?.length>0 ? LatestAdminEvents.slice(0,4).map((item) => {
                  return (
                    <>
                        <div className="cardTop">
                        <Link to={"/events/" + item._id} style={{ textDecoration: "none" }}>
                            <span className="cardTitle"> {item.eventName}  </span>
                            </Link>
                            <span className="timings"> { moment(item.timings[0].startDate).format("DD/MM/YYYY") } - { moment(item.timings[0].endDate).format("DD/MM/YYYY")}</span>
                            <span className="timings"> { moment(item.timings[0].startTime).format("hh:mm A") } - {  moment(item.timings[0].endTime).format("hh:mm A")}</span>
                            <div className="EventsLocation"> 
                              <span className="locTitle">Location: </span>
                                <span className="masjidTitle"> {item.masjidName} </span>
                                  <span className="masjidTitle"><GrMapLocation size={'1rem'} style={{cursor:"pointer",}} onClick={(e) => OpenGoogleMaps(item.location.coordinates[0],item.location.coordinates[1])}/>   </span>
                            </div>
                            <span className="addressTitle">  {item.description} </span>
                        </div>
                    </>
                    )
                  }):(
                    <>
                        <div className="EventsContainer">
                          <h5 style={{ marginLeft:'10px'}}>Sorry!! No Events Found For you</h5>
                        </div>
                    </>
                  ))
            }
            </div>
        </div>
    )
 }

export default EventsCarousel
