import React from 'react';
import './Dashboard.css';
import SideBar from '../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../Components/FeedComponents/TopBar/TopBar.jsx';
import Feed from '../../Components/FeedComponents/Feed/Feed.jsx';
import {useSelector} from 'react-redux';


const Dashboard = ({admin}) => {
   
  const sidebarState = useSelector((state) => state.sidebarState);

  return (
   <>

   <div className="HomeContainer">
   {
        sidebarState ?
     
        (<div className="LeftContainer">
          <SideBar/>
        </div>):
         (<div className="LeftContainerClose">
         <SideBar/>
       </div>)
     } 
     <div className="RightContainer">
      <div className="RightTopContainer">
      <TopBar/>
      </div>
      <div className="RightFeedContainer">
       <Feed />
      </div>
     </div>
    </div>
    
   </>    
  )
}

export default Dashboard