import { ADD_TIMING } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const AddTimingReducer =  (masjidTime = [] , action) => {

   switch (action.type) {

    case ADD_TIMING:

      return action.payload;;

    default:
      return masjidTime;
  }
};

export default AddTimingReducer;