import React,{useEffect,useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux';
import { getFreezedAdmins } from '../../../Redux/Actions/AdminActions/FetchingFreezedAdmins.js';
import { UnFreezingAdminById } from '../../../Redux/Actions/AdminActions/UnFreezingAdmin.js';
import { useDispatch } from 'react-redux';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import  './FreezeAdminTable.scss';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";


const today = moment();
const FreezeAdminTable = ({masjids}) => {
  const currentAdmin  = useSelector((state) => state.currentAdmin);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [FreezedAdmins, setFreezedAdmins] = useState([])
  const [isUnFreezing, setisUnFreezing] = useState(false)
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [UnfreezingAdmin, setUnfreezingAdmin] = useState([]);


  const handleModelOpen = (adminDetails) => {
    setFreezeModalOpen(true);
    setUnfreezingAdmin(adminDetails.row)
  }


  const adminColumns = [
    {
      field: "adminName",
      title: "Admin Name",
      autoComplete: 'off',
      width: 380,
    },
    {
      field: "updatedAt",
      title: "Last Updated On",
      autoComplete: 'off',
      width: 220,
      renderCell: (params) => {
        return <div className="dataUpdatedAt">{moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}</div>;
      },
    },
    {
      field: "email",
      title: "Email",
      width: 300,
      autoComplete: 'off',
    },
    {
      field: "role",
      title: "Role",
      autoComplete: 'off',
      width: 130,
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      renderCell: (params) => {

        return <Button onClick={(e) => {
                  e.stopPropagation();
                  handleModelOpen(params);
                }} 
                 style={{ border:"1px solid green",color:"green",height:"20px",fontSize:"10px",cursor:"Pointer"}}>UnFreeze</Button>;
      }
    }
  ]
  
  const freezedAdmins = () => {
    const response =  dispatch(getFreezedAdmins());
    response.then(function(result) {
    if(!result.success){
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:result.message ? "Failed to Fetch Freezed Admins Details : " + result.message : "Failed to Fetch Freezed Admins Details : Internet or Server Issue "
        }
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      }
      else{
        setFreezedAdmins(result.admins)
       }
     });
  }


  useEffect(() => {
    const response =  dispatch(getFreezedAdmins());
    response.then(function(result) {
    if(!result.success){
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:result.message ? "Failed to Fetch Freezed Admins Details : " + result.message : "Failed to Fetch Freezed Admins Details : Internet or Server Issue "
        }
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      }
      else{
        setFreezedAdmins(result.admins)
        // freezedAdmins();
       }
     });
    }, []);

 



   const handleUnFreezeModal= () => {
    setFreezeModalOpen(false);
   }



   const handleUnFreezeAdmin = () => {
    let id ;
    setisUnFreezing(true);
    const response =  dispatch(UnFreezingAdminById(UnfreezingAdmin?._id));
    response.then(function(result) {
    if(!result.success){
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:result.message ? "Failed to  UnFreezed Admins Details : " + result.message : "Failed to UnFreezed Admins Details : Internet or Server Issue "
        }
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        setisUnFreezing(false);
      }
      else{
        setFreezeModalOpen(false);
        setisUnFreezing(false);
        navigate("/admins")
       }
     });

   }

   console.log(FreezedAdmins);

  return (
    <div className="FreezedMasjidTableContainer">
      <span className="FreezeTableTitle">Freezed Admins</span>
      <Dialog open={FreezeModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to Unfreeze the Admin ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={handleUnFreezeAdmin} variant="outlined"  >  { isUnFreezing ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                 <Button onClick={handleUnFreezeModal} variant="outlined"  disabled={isUnFreezing}>No</Button>
              </DialogActions>
      </Dialog>
     <div style={{ height: 700, width: '100%',marginTop:'1.2rem'}}>
      <DataGrid
        rows={FreezedAdmins?.length>0 ? FreezedAdmins:[]}
        columns={adminColumns}
        pageSize={10}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick 
      />
    </div>
    </div>
  )
}

export default FreezeAdminTable;