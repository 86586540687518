import React,{useState,useEffect,useRef} from 'react'
import "./AllAdminsTable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { FcPlus } from "react-icons/fc";
import { useDispatch } from 'react-redux';
import {useSelector} from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';
import {FetchingAdmins} from '../../../Redux/Actions/AdminActions/FetchingAdmins.js';
import {AddingNewAdmin} from '../../../Redux/Actions/AdminActions/AddingNewAdmin.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { CircularProgress } from "@material-ui/core";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

   const AdminColumns = [
  {
    field: "adminName",
    title: "Admin Name",
    width: 230,
    render: rowData => (
      <div className="cellAction">
      <Link to={"/admins/" + rowData.adminName} style={{ textDecoration: "none" }}>
        {rowData.adminName}
      </Link>
    </div>
    )
  },
  {
    field: "email",
    title: "Email",
    width: 230,
  },
  {
    field: "role",
    title: "Role",
    width: 400,
  },

];

const AllAdminsTable = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState('');
  const defaultMaterialTheme = createTheme();
  const admins  = useSelector((state) => state.allAdmins);
  const allAdmins = useSelector((state) => state.allAdmins);
  const [Admins, setAdmins] = useState(allAdmins);
  const [OpenModal, setOpenModal] = useState(false);
  const [AdminRole, setAdminRole] = useState('');
  let AdminNameRef = useRef(null);
  let AdminEmailRef = useRef(null);
  let AdminPasswordRef = useRef(null);
  const [passwordType, setPasswordType] = useState("password");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [isFetching, setisFetching] = useState(false);
  const [TableLoading, setTableLoading] = useState(false);

  const admin = JSON.parse(localStorage.getItem("admin"));


  const handleDelete = (id) => {
    setData(allAdmins.filter((item) => item.id !== id));
  };

  const handleAddAdminModal = () => {
    setOpenModal(true);
  };

  const handleAddAdminModalClose = () => {
    setOpenModal(false);
  };
  

  
  useEffect(() => {
    
    // if(Admins.length<=0){
     
      setTableLoading(true);
      const response = dispatch(FetchingAdmins());
      response.then(function(result) {
        if(result.success)
     {
        setAdmins(result.data);
        setTableLoading(false);
        }
        else 
        {
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:"Fetching Admins Failed",
          }
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          setTableLoading(false);
        }
      })
    // }
   }, []);


 const togglePassword =()=>{
      if(passwordType==="password")
      {
       setPasswordType("text")
       setPasswordShow(true); 
       return;
      }
      setPasswordType("password");
      setPasswordShow(false); 
    }


 const addAdmin = () => {

  let updatedData = {
    adminName:AdminNameRef.current.value,
    email:AdminEmailRef.current.value,
    password:AdminPasswordRef.current.value,
    role:AdminRole,
  } 
  setisFetching(true);
  const response = dispatch(AddingNewAdmin(updatedData));
  response.then(function(result) {
  if(result.success)
  {
    handleAddAdminModalClose();
    const response = dispatch(FetchingAdmins());
    response.then(function(result) {
    if(result.success)
    {
       setAdmins(result.data);
       setisFetching(false);
       }
    else 
    {
      const snackbarFailureDetails = {
       snackbarOpen:true, 
       snackbarType:"error",
         snackbarMessage:"Fetching Admin Failed",
        }
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        setisFetching(false);
      }
     })

     const snackbarFailureDetails = {
      snackbarOpen:true, 
      snackbarType:"success",
        snackbarMessage:"Admin Credentials have been sent to the admin",
       }
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 

  }
  else 
  {
   const snackbarFailureDetails = {
     snackbarOpen:true, 
     snackbarType:"error",
       snackbarMessage:"Adding Admin Failed",
      }
      dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      setisFetching(false);
    }
  })
 }

 const updateAdminRole = (e) => {
  setAdminRole(e.target.value);
 }
  return (
    <div className="datatable">
      <div className="titleHead">
      <div className="datatableTitle">
         Admins
         {
             isFetching && (<><span className="LoadingMessage">Adding The Admin Data   <CircularProgress  size="20px" style={{marginLeft:"10px",marginTop:"5px"}} /></span></>)
            }
         { 
              (admin.role ==="superadmin" || admin.role === "dataentry") && (
              <Button  className="link" onClick={handleAddAdminModal} >
                Add New Admin <FcPlus className="PlusIcon"/>
            </Button>)
       }
      </div>
      </div>
      <Dialog open={OpenModal} onClose={handleAddAdminModal} height={"xl"} >
            <DialogTitle>New Admin</DialogTitle>
              <DialogContent>
                  <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      label="Admin Name"
                      sx={{  marginBottom: 3 }}
                      type="text"
                      fullWidth
                      inputRef={AdminNameRef}
                      variant="standard"
                    />
                  <TextField
                     id="outlined-required"
                     variant="standard"
                     label="Admin Email"
                     fullWidth
                     inputRef={AdminEmailRef}
                     type="email"
                     sx={{  marginBottom: 3 }}
                     /> 
                  <TextField
                     id="outlined-required"
                     variant="standard"
                     label="Admin Password"
                     inputRef={AdminPasswordRef}
                     type={passwordType}
                     sx={{  marginBottom: 3,width:"80%" }}
                     />

                     {PasswordShow ? <AiFillEye onClick={togglePassword} className="ShowPassword"/> : <AiFillEyeInvisible onClick={togglePassword} className="ShowPassword"/> }

                     <FormControl  fullWidth>
                        <InputLabel id="demo-simple-select-label">Admin Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Admin Role"
                          value={AdminRole}
                          onChange={updateAdminRole}
                        >
                          <MenuItem value={"superadmin"}>Super Admin</MenuItem>
                          <MenuItem value={"dataentry"}>Data Entry</MenuItem>
                          <MenuItem value={"subadmin"}>Sub Admin</MenuItem>
                        </Select>
                      </FormControl>
              </DialogContent>
            <DialogActions>
          <Button onClick={handleAddAdminModalClose} style={{color:'grey'}} >Cancel</Button>
            <Button onClick = {addAdmin} disabled={isFetching}> Add </Button>
          </DialogActions>
        </Dialog>
     <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable title="Admins"
         icons={tableIcons}
         isLoading={TableLoading}
         data={Admins}
         columns={AdminColumns} 
         editable={{
        }}
         options={{
           exportButton:true,
           pageSize: 10,
         }}
         />
       </ThemeProvider>
    </div>
  );
}

export default AllAdminsTable