import React,{useState,useEffect,useRef} from 'react'
import TimingsTableContent from '../MasjidTimings/TimingsTableContentComponent/TimingsTableContent.js';
import ManageTimingsTableContent from '../MasjidTimings/ManageTimingsComponent/ManageTimingsTableContent.js';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { FetchingTimingsByDateRange } from '../../../Redux/Actions/TimingActions/FetchingTimingsByDateRange.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import axios from 'axios'
import {MasjidTimes} from '../MasjidTimings/TimingsTableContentComponent/DemoContent.js';

const ManageTimingsComponent = ({Masjid}) => {

  const [ManageTimings, setManageTimings] = useState(false);
  const [TimingsData, setTimingsData] = useState([]);
  const [TwoMonthTimingsData, setTwoMonthTimingsData] = useState([]);
  const [TodaysTimings, setTodaysTimings] = useState([]);
  const [APITimings, setAPITimings] = useState([]);
  const dispatch = useDispatch();
  const todayDate = moment().format('YYYY-MM-DD');
  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate   = moment().endOf('month').format('YYYY-MM-DD');
  const endNextMonth = moment().add(1, 'M').endOf("month").format('YYYY-MM-DD');
  const today =   new Date();

   useEffect(() => {
    
    let Timings = [];

    if(Masjid?.address){

      axios.get("https://api.aladhan.com/v1/timingsByAddress", {
        params:{
          address:Masjid.address,    
         }
       }
      ).then(res => { 

        Timings.push(moment(res.data.data.timings.Fajr,'h:mm a').format("YYYY-MM-DD  hh:mm a"))
        Timings.push(moment(res.data.data.timings.Dhuhr,'h:mm a').format("YYYY-MM-DD  hh:mm a"))
        Timings.push(moment(res.data.data.timings.Asr,'h:mm a').format("YYYY-MM-DD  hh:mm a"))
        Timings.push(moment(res.data.data.timings.Maghrib,'h:mm a').format("YYYY-MM-DD  hh:mm a"))
        Timings.push(moment(res.data.data.timings.Isha,'h:mm a').format("YYYY-MM-DD  hh:mm a"))
       
        let MasjidTimes = [
          {
            namazName:"Fajr",
            azaanTime:moment(Timings[0]).unix(),
            jamaatTime:moment(Timings[0]).unix()
          },
          {
            namazName:"Dhur",
            azaanTime:moment(Timings[1]).unix(),
            jamaatTime:moment(Timings[1]).unix()
          },
          {
            namazName:"Asar",
            azaanTime:moment(Timings[2]).unix(),
            jamaatTime:moment(Timings[2]).unix()
          },
          {
            namazName:"Maghrib",
            azaanTime:moment(Timings[3]).unix(),
            jamaatTime:moment(Timings[3]).unix()
          },
          {
            namazName:"Isha",
            azaanTime:moment(Timings[4]).unix(),
            jamaatTime:moment(Timings[4]).unix()
          },
        ];
        
        setAPITimings(MasjidTimes);
        
      }).catch(error => {
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"warning",
          snackbarMessage:"Couldn't Get Prefilled Timings for the location",
        }
        
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        setAPITimings(MasjidTimes);
      });
     }
  }, [Masjid])

  

  useEffect(() => {
    if(Masjid?.masjidName)
    {
         let currentDateFormat = (moment(today).format('YYYY-MM-DD')) + "T00:00:00.000Z";

          const res = dispatch(FetchingTimingsByDateRange(startDate,endDate,Masjid._id));

          res.then((result) => {

           if(result.success){

            setTimingsData(result.data);

            setTodaysTimings(result.data.filter((item) => item.date === currentDateFormat))
              
            }
           else{

            //  const snackbarFailureDetails = {
            //    snackbarOpen:true, 
            //     snackbarType:"warning",
            //      snackbarMessage:`No Timings Found For Current Month For the Masjid`
            //   }
 
            //   setTimingsData([]);
            //   setTodaysTimings([]);
            //   setTimeout(() => {
            //   dispatch(ChangeSnackbar(snackbarFailureDetails));
            //   }, 1000);   
             
           }
          })
        }
   }, [ManageTimings,Masjid]); 



  return (
   <> 
      <div className="ManageTimingsHeadComponent">
      { ManageTimings ? <ManageTimingsTableContent setManageTimings={setManageTimings} TodaysTimings={TodaysTimings} today={today} Masjid={Masjid}  APITimings={APITimings} />  : <TimingsTableContent  setManageTimings={setManageTimings} today={today} CurrentMonthTimings={TimingsData} TodaysTimings={TodaysTimings} Masjid={Masjid} />}
      </div>
   </>
  )
}

export default ManageTimingsComponent;