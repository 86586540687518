import { FETCH_MASJID_BY_NAME } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const FetchMasjidByName = (masjidName) => async(dispatch) => {


    try{
    
      const { data } = await api.fetchMasjidByName(masjidName);

      
      if(data.success)
      {
         
         dispatch({type:"FETCH_MASJID_BY_NAME" , payload: data.data})
      
         return data;
       }
     return data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:"Fetching Masjid Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 

      return error.response;

   }
}