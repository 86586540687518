import React from 'react';
import {useState,useEffect,useRef,useMemo} from 'react'
import './ManageTimingsTableContent.css';
import { CircularProgress } from "@material-ui/core";
import { DateRange } from 'react-date-range';
import { addDays } from 'date-fns';
import { DefinedRange } from 'react-date-range';
import {defaultStaticRanges} from './defaultStaticRanges';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {MasjidTimes} from './DemoContent';
import moment from 'moment';
import { IoIosAddCircleOutline } from "react-icons/io";
import { CiCircleRemove } from "react-icons/ci";
import { useDispatch } from 'react-redux';
import {UpdateBulkTiming} from '../../../../Redux/Actions/TimingActions/UpdateBulkTimings.js';
import { FetchingTimingsByDateRange } from '../../../../Redux/Actions/TimingActions/FetchingTimingsByDateRange.js';
import { deleteAllJummah } from '../../../../Redux/Actions/TimingActions/deleteAllJummah.js';
import { updateAllJummah } from '../../../../Redux/Actions/TimingActions/updateAllJummah.js';
import { addNewNamaz } from '../../../../Redux/Actions/TimingActions/addNewNamaz.js';
import {ChangeSnackbar} from '../../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import { Badge } from "@material-ui/core";
import { format, isWeekend } from 'date-fns';
import Switch from '@mui/material/Switch';
import { KeyboardTimePicker } from "@material-ui/pickers";
import Checkbox from '@mui/material/Checkbox';
import { differenceInDays } from 'date-fns'
import {addTiming} from '../../../../Redux/Actions/TimingActions/AddTiming.js';
import {DeletingTimingsByDateRange} from '../../../../Redux/Actions/TimingActions/DeleteTimingsByRange.js';
import {TimingsUpdateNotification } from '../../../../Redux/Actions/NotificationActions/TimingsUpdateNotification.js'
const label = { inputProps: { 'aria-label': 'Switch demo' } };




const ManageTimingsTableContent = ({ setManageTimings,TodaysTimings,Masjid, InitialTimings, today,APITimings }) => {
  const dispatch = useDispatch();
  const [MasjidTimings, setMasjidTimings] = useState(APITimings);
  const [Clear, setClear ] = useState(false);
  const [Update, setUpdate ] = useState(false);
  const [RangeHasEmpty, setRangeHasEmpty ] = useState(false);
  const [InitialStartTimings, setInitialStartTimings] = useState([])
  const [CurrentSelectedDayTimings, setCurrentSelectedDayTimings] = useState(APITimings);
  const [TotalLength, setTotalLength] = useState(CurrentSelectedDayTimings?.length);
  const [SelectedNamazes, setSelectedNamazes] = useState([]);  
  const [selectedMonth, setselectedMonth] = useState(today.getMonth()+1)
  const [NextMonth, setNextMonth] = useState(today.getMonth()+2)
  const [FirstMonthSelectedDays, setFirstMonthSelectedDays] = useState([])
  const [FirstMonthIncompleteDays, setFirstMonthIncompleteDays] = useState([])
  const [SecondMonthSelectedDays, setSecondMonthSelectedDays] = useState([])
  const [SecondMonthIncompleteDays, setSecondMonthIncompleteDays] = useState([])
  const [FajrPrefilled, setFajrPrefilled] = useState(false)
  const [MaghribPrefilled, setMaghribPrefilled] = useState(false)
  const admin = JSON.parse(localStorage.getItem("admin"));
  const [JummahsNoTimings,setJummahsNoTimings] = useState([]);
  const [JummahsPreTimings,setJummahsPreTimings] = useState([]);
  const [JummahsToRemoveData,setJummahsToRemoveData] = useState([]);
  const [IncompleteDays,setIncompleteDays] = useState([]);
  const [JummahAdding,setJummahAdding] = useState(false);
  const [JummahUpdating,setJummahUpdating] = useState(false);
  const [isUpdating, setisUpdating] = useState(false);
  const [isAdding, setisAdding] = useState(false);
  const [isDeleting, setisDeleting] = useState(false);
  const [isDeletingNamazes, setisDeletingNamazes] = useState(false);
  const [isAddingNewNamaz, setisAddingNewNamaz] = useState(false);
  const [isSingle, setisSingle] = useState(false);

  
  let AllNamazSelectedRef = useRef();
  let NewJummahRef = useRef();
  let PreJummahRef = useRef();
  
  let FajrNameRef = useRef();
  let DhurNameRef = useRef();
  let AsarNameRef = useRef();
  let MaghribNameRef = useRef();
  let IshaNameRef = useRef();
  let JummahNameRef = useRef();
  let TaraweehNameRef = useRef();
  let WitrNameRef = useRef();
  let SalatulNameRef = useRef();

  let FajrAzaanRef = useRef();
  let DhurAzaanRef = useRef();
  let AsarAzaanRef = useRef();
  let MaghribAzaanRef = useRef();
  let IshaAzaanRef = useRef();
  let JummahAzaanRef = useRef();
  let TaraweehAzaanRef = useRef();
  let SalatulAzaanRef= useRef();
  let WitrAzaanRef= useRef();

  let FajrJamaatRef = useRef();
  let DhurJamaatRef = useRef();
  let AsarJamaatRef = useRef();
  let MaghribJamaatRef = useRef();
  let IshaJamaatRef = useRef();
  let JummahJamaatRef = useRef();
  let TaraweehJamaatRef= useRef();
  let SalatulJamaatRef = useRef();
  let WitrJamaatRef = useRef();
  
  
  const [TimingUpdates, setTimingUpdates] = useState([]);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const handleNamazIncrease = (e) => {
    setTotalLength(prevLength => prevLength + 1)
  }

  const handleNamazDecrease = (e) => {
    setTotalLength(prevLength => prevLength - 1)
  }

  const hanldeManageTimings = (e) => {
    setManageTimings(false);
  }

  useEffect(() => {
    let currentDateFormat = (moment(state[0].startDate).format('YYYY-MM-DD')) + "T00:00:00.000Z";

    let timingDate = InitialStartTimings.filter((item) => item.date === currentDateFormat);
    if(timingDate.length>0)
    {
      setCurrentSelectedDayTimings(timingDate[0]?.timings);
    }
    else{
      setCurrentSelectedDayTimings(APITimings);
    }
  }, [state])



  useEffect(() => {
    if(CurrentSelectedDayTimings.length>0){

      FajrNameRef.current.value = CurrentSelectedDayTimings[0]?.namazName
      FajrAzaanRef.current.value =  moment(CurrentSelectedDayTimings[0]?.azaanTime * 1000).format('HH:mm');
      FajrJamaatRef.current.value =   moment(CurrentSelectedDayTimings[0]?.jamaatTime * 1000).format('HH:mm');


      if(CurrentSelectedDayTimings.length > 1){
     
        DhurNameRef.current.value = CurrentSelectedDayTimings[1]?.namazName
        DhurAzaanRef.current.value =  moment(CurrentSelectedDayTimings[1]?.azaanTime * 1000).format('HH:mm');
       DhurJamaatRef.current.value =  moment(CurrentSelectedDayTimings[1]?.jamaatTime * 1000).format('HH:mm');
      }

      if(CurrentSelectedDayTimings.length > 2){
        
        AsarNameRef.current.value = CurrentSelectedDayTimings[2]?.namazName
        AsarAzaanRef.current.value =  moment(CurrentSelectedDayTimings[2]?.azaanTime * 1000).format('HH:mm');
        AsarJamaatRef.current.value =  moment(CurrentSelectedDayTimings[2]?.jamaatTime * 1000).format('HH:mm');
      }

      if(CurrentSelectedDayTimings.length >3){
      
         MaghribNameRef.current.value =  CurrentSelectedDayTimings[3]?.namazName
         MaghribAzaanRef.current.value =   moment(CurrentSelectedDayTimings[3]?.azaanTime * 1000).format('HH:mm');
         MaghribJamaatRef.current.value =   moment(CurrentSelectedDayTimings[3]?.jamaatTime * 1000).format('HH:mm');
      }

      if(CurrentSelectedDayTimings.length >4){
       
        IshaNameRef.current.value = CurrentSelectedDayTimings[4]?.namazName
        IshaAzaanRef.current.value =  moment(CurrentSelectedDayTimings[4]?.azaanTime * 1000).format('HH:mm');
        IshaJamaatRef.current.value =  moment(CurrentSelectedDayTimings[4]?.jamaatTime * 1000).format('HH:mm');
      }
      
      if(CurrentSelectedDayTimings.length == 6){

        JummahNameRef.current.value = CurrentSelectedDayTimings[5]?.namazName
        JummahAzaanRef.current.value =  moment(CurrentSelectedDayTimings[5]?.azaanTime * 1000).format('HH:mm');
        JummahJamaatRef.current.value =  moment(CurrentSelectedDayTimings[5]?.jamaatTime * 1000).format('HH:mm');
        
        
      }else if(CurrentSelectedDayTimings.length == 7){
        
        TaraweehNameRef.current.value = CurrentSelectedDayTimings[6]?.namazName
        TaraweehAzaanRef.current.value =  moment(CurrentSelectedDayTimings[6]?.azaanTime * 1000).format('HH:mm');
        TaraweehJamaatRef.current.value =  moment(CurrentSelectedDayTimings[6]?.jamaatTime * 1000).format('HH:mm');
        
      }else if(CurrentSelectedDayTimings.length == 8){
        
        WitrNameRef.current.value = CurrentSelectedDayTimings[7]?.namazName
        WitrAzaanRef.current.value =  moment(CurrentSelectedDayTimings[7]?.azaanTime * 1000).format('HH:mm');
        WitrJamaatRef.current.value =  moment(CurrentSelectedDayTimings[7]?.jamaatTime * 1000).format('HH:mm');
        
      }else if(CurrentSelectedDayTimings.length == 9){
        
        SalatulNameRef.current.value = CurrentSelectedDayTimings[8]?.namazName
        SalatulAzaanRef.current.value =  moment(CurrentSelectedDayTimings[8]?.azaanTime * 1000).format('HH:mm');
        SalatulJamaatRef.current.value =  moment(CurrentSelectedDayTimings[8]?.jamaatTime * 1000).format('HH:mm');
    }
   }
  }, [CurrentSelectedDayTimings])


  useEffect(() => {
     const todayDate = moment().format('YYYY-MM-DD');
     const endNextMonth = moment().add(1, 'M').endOf("month").format('YYYY-MM-DD');

     const res = dispatch(FetchingTimingsByDateRange(todayDate,endNextMonth,Masjid._id));        
     res.then((result) => {
       if(result.success){
         setInitialStartTimings(result.data);
         handlingSelectedDays(result.data);
       }
       else{
         const snackbarFailureDetails = {
           snackbarOpen:true, 
           snackbarType:"warning",
           snackbarMessage:`No Timings Found For Current Month For the Masjid`
         }
         
         setInitialStartTimings([]); 
         setTimeout(() => {
           dispatch(ChangeSnackbar(snackbarFailureDetails));
         }, 1000);    
       }
     })
    }, []);
   

     const getTimings = () => {
      const todayDate = moment().format('YYYY-MM-DD');
      const endNextMonth = moment().add(1, 'M').endOf("month").format('YYYY-MM-DD');
 
      const res = dispatch(FetchingTimingsByDateRange(todayDate,endNextMonth,Masjid._id));
      res.then((result) => {
        if(result.success){
          setInitialStartTimings(result.data);
          handlingSelectedDays(result.data);
        }
        else{
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"warning",
            snackbarMessage:`No Timings Found For Current Month For the Masjid`
          }
          
          setInitialStartTimings([]);
          setTimeout(() => {
            dispatch(ChangeSnackbar(snackbarFailureDetails));
          }, 1000);    
        }
      })
     }

    const handlingSelectedDays = (APITimings) => {
    let CurrentMonthDays = [];
    let CurrentMonthIncompleteDays = [];
    let NextMonthDays = [];
    let NextMonthIncompleteDays = [];
    let Day ;
  
      if(selectedMonth === 12){
        setNextMonth(1);
       }

       APITimings?.map((item) => { 
        Day = new Date(item.date);

        if(selectedMonth < 12){
          if(Day.getMonth()+1 === selectedMonth){ 
            if(item.timings.length<5){
              CurrentMonthIncompleteDays.push(Day.getDate());
            }
            CurrentMonthDays.push(Day.getDate());
          }else if(Day.getMonth()+1 === NextMonth ){
            if(item.timings.length<5){
              NextMonthIncompleteDays.push(Day.getDate());
            }
            NextMonthDays.push(Day.getDate());
          }
          setFirstMonthSelectedDays(CurrentMonthDays);
          setSecondMonthSelectedDays(NextMonthDays);

          if(CurrentMonthIncompleteDays.length>0){
            setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
          }
          if(NextMonthIncompleteDays.length>0){
            setSecondMonthIncompleteDays(NextMonthIncompleteDays);
          }
        }else{

          if(Day.getMonth()+1 === selectedMonth){ 
            if(item.timings.length<5){
              CurrentMonthIncompleteDays.push(Day.getDate());
            }
            CurrentMonthDays.push(Day.getDate());
            
          }else if(Day.getMonth()+1 === 1 ){
  
            if(item.timings.length<5){
              NextMonthIncompleteDays.push(Day.getDate());
            }
            NextMonthDays.push(Day.getDate());

          }
          setFirstMonthSelectedDays(CurrentMonthDays);
          setSecondMonthSelectedDays(NextMonthDays);
  
          if(CurrentMonthIncompleteDays.length>0){
            setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
          }
  
          if(NextMonthIncompleteDays.length>0){
            setSecondMonthIncompleteDays(NextMonthIncompleteDays);
          }
         }
       });
    }

    const handlingIncompleteDays = (APITimings) => {
      let CurrentMonthIncompleteDays = [];
      let NextMonthIncompleteDays = [];
      let Day ;

        if(selectedMonth === 12){
          setNextMonth(1);
         }

        //  console.log(APITimings);
         
         APITimings?.map((item) => { 
          Day = new Date(item.date);
  
          if(selectedMonth < 12){
  
            if(Day.getMonth()+1 === selectedMonth){ 
                CurrentMonthIncompleteDays.push(Day.getDate());
            }
            else if(Day.getMonth()+1 === NextMonth )
            {
                NextMonthIncompleteDays.push(Day.getDate());
            }
  
            if(CurrentMonthIncompleteDays.length>0){
              setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
            }
            if(NextMonthIncompleteDays.length>0){
              setSecondMonthIncompleteDays(NextMonthIncompleteDays);
            }
  
          }else{
  
            if(Day.getMonth()+1 === selectedMonth){ 
                CurrentMonthIncompleteDays.push(Day.getDate());
            }else if(Day.getMonth()+1 === 1 ){
                NextMonthIncompleteDays.push(Day.getDate());
            }
            if(CurrentMonthIncompleteDays.length>0){
              setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
            }
    
            if(NextMonthIncompleteDays.length>0){
              setSecondMonthIncompleteDays(NextMonthIncompleteDays);
            }
           }
         });
      }
   
      const handlingIncompleteDeleteDays = (APITimings) => {

        let CurrentMonthIncompleteDays = [];
        let NextMonthIncompleteDays = [];
        let Day ;
  
          if(selectedMonth === 12){
            setNextMonth(1);
           }
           
           APITimings?.map((item) => { 
            Day = new Date(item.date);
    
            if(selectedMonth < 12){
    
              if(Day.getMonth()+1 === selectedMonth){ 
                  CurrentMonthIncompleteDays.push(Day.getDate());
              }
              else if(Day.getMonth()+1 === NextMonth )
              {
                  NextMonthIncompleteDays.push(Day.getDate());
              }
    
              if(CurrentMonthIncompleteDays.length>0){
            
                setFirstMonthIncompleteDays(FirstMonthIncompleteDays.filter(item => !CurrentMonthIncompleteDays.includes(item)))

              }
              if(NextMonthIncompleteDays.length>0){
              
                setSecondMonthIncompleteDays(SecondMonthIncompleteDays.filter(item => !NextMonthIncompleteDays.includes(item)))
              }
    
            }else{
    
              if(Day.getMonth()+1 === selectedMonth){ 
                setFirstMonthIncompleteDays(FirstMonthIncompleteDays.filter(item => !CurrentMonthIncompleteDays.includes(item)))
              }else if(Day.getMonth()+1 === 1 ){
                  NextMonthIncompleteDays.push(Day.getDate());
              }
              if(CurrentMonthIncompleteDays.length>0){
                // setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
                setFirstMonthIncompleteDays(FirstMonthIncompleteDays.filter(item => !CurrentMonthIncompleteDays.includes(item)))
              }
      
              if(NextMonthIncompleteDays.length>0){
                // setSecondMonthIncompleteDays(SecondMonthIncompleteDays.filter(item => !NextMonthIncompleteDays.includes(item)))
                console.log(SecondMonthIncompleteDays.filter(item => !NextMonthIncompleteDays.includes(item)))
  
              }
             }
           });
        }
  



  const handleSelectNamaz = (NamazName,AzaanTime,JamaatTime,e) => {

    const newInfo = {
      namazName:NamazName.current.value,
      azaanTime:  moment(AzaanTime.current.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss") , 
      jamaatTime: moment(JamaatTime.current.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss")
    }
    
    const namazobj = {
      namazName: NamazName.current.value,
      azaanTime: moment(newInfo.azaanTime).unix(), 
      jamaatTime: moment(newInfo.jamaatTime).unix()
    }

    if(NamazName.current.value && AzaanTime.current.value && JamaatTime.current.value){
    
      const response = SelectedNamazes.filter((item) => item.namazName === NamazName.current.value);
      if(!response.length>0){
        setSelectedNamazes([...SelectedNamazes,namazobj]);
      }else{
        setSelectedNamazes(SelectedNamazes.filter((item) => item.namazName !== NamazName.current.value));
      }
    }
    else{
      setClear(false);
    }
  }  


  useEffect(() => {
   if(TotalLength === SelectedNamazes.length)
   {
    AllNamazSelectedRef.current.checked = true;

  }else if(TotalLength>SelectedNamazes.length){
 
    AllNamazSelectedRef.current.checked = false;
   }

   if(SelectedNamazes.length > 0){
    setClear(true);
   }
  }, [SelectedNamazes]);

  
  
  const handleAllNamazSelect = (e) => {
   
    if(e.target.checked){

      let cityCheck=document.getElementsByName("namaz");
	    let cityCheckLen=cityCheck.length;

      for(var x=0;x<cityCheckLen;x++){
   		cityCheck[x].checked=true;
	   }

     handleAllSelectedNamazTimings();
    }
    else{
       
      let cityCheck=document.getElementsByName("namaz");
      let cityCheckLen=cityCheck.length;

      for(var x=0;x<cityCheckLen;x++){
        cityCheck[x].checked=false;
      }
      setSelectedNamazes([]);
    }
  }

  const LaunchSnackBar = () => {
  
    const snackbarFailureDetails = {
      snackbarOpen:true, 
       snackbarType:"warning",
        snackbarMessage:`Please Fill Complete details before Saving`
     }

     setTimeout(() => {
     dispatch(ChangeSnackbar(snackbarFailureDetails));
     }, 1000);  
  }


   const handleAllSelectedNamazTimings = () => {

    const newFajrInfo = {
        namazName:FajrJamaatRef.current?.value,
        azaanTime:  moment(FajrAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(FajrJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
    const newDhurInfo = {
        namazName:DhurNameRef.current?.value,
        azaanTime:  moment(DhurAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(DhurJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
    const newAsarInfo = {
        namazName:AsarNameRef.current?.value,
        azaanTime:  moment(AsarAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(AsarJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }


    const newMaghribInfo = {
        namazName:MaghribNameRef.current?.value,
        azaanTime:  moment(MaghribAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(MaghribJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
    const newIshaInfo = {
        namazName:IshaNameRef.current?.value,
        azaanTime:  moment(IshaAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(IshaJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
    const newJummahInfo = {
        namazName:JummahNameRef.current?.value,
        azaanTime:  moment(JummahAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(JummahJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
    const newTaraweehInfo = {
        namazName:TaraweehNameRef.current?.value,
        azaanTime:  moment(TaraweehAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(TaraweehJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
    const newSalatulInfo = {
        namazName:SalatulNameRef.current?.value,
        azaanTime:  moment(SalatulAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(SalatulJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }

    const newWitrInfo = {
        namazName:WitrNameRef.current?.value,
        azaanTime:  moment(WitrAzaanRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a") , 
        jamaatTime: moment(WitrJamaatRef.current?.value, ["HH:mm"]).format("YYYY-MM-DD  hh:mm:ss a")
      }
      
      const FajrObj = {
          namazName: FajrNameRef.current?.value,
          azaanTime: moment(newFajrInfo?.azaanTime).unix(), 
          jamaatTime: moment(newFajrInfo?.jamaatTime).unix()
        }
      
      const DhurObj = {
          namazName: DhurNameRef.current?.value,
          azaanTime: moment(newDhurInfo?.azaanTime).unix(), 
          jamaatTime: moment(newDhurInfo?.jamaatTime).unix()
        }
      
      const AsarObj = {
          namazName: AsarNameRef.current?.value,
          azaanTime: moment(newAsarInfo?.azaanTime).unix(), 
          jamaatTime: moment(newAsarInfo?.jamaatTime).unix()
        }
      
      const MaghribObj = {
          namazName: MaghribNameRef.current?.value,
          azaanTime: moment(newMaghribInfo?.azaanTime).unix(), 
          jamaatTime: moment(newMaghribInfo?.jamaatTime).unix()
        }
      
      const IshaObj = {
          namazName: IshaNameRef.current?.value,
          azaanTime: moment(newIshaInfo?.azaanTime).unix(), 
          jamaatTime: moment(newIshaInfo?.jamaatTime).unix()
        }
      
      const JummahObj = {
          namazName: JummahNameRef.current?.value,
          azaanTime: moment(newJummahInfo?.azaanTime).unix(), 
          jamaatTime: moment(newJummahInfo?.jamaatTime).unix()
        }
      
      const TaraweehObj = {
          namazName: TaraweehNameRef.current?.value,
          azaanTime: moment(newTaraweehInfo?.azaanTime).unix(), 
          jamaatTime: moment(newTaraweehInfo?.jamaatTime).unix()
        }
      
      const SalatulNameObj = {
          namazName:SalatulNameRef.current?.value,
          azaanTime: moment(newSalatulInfo?.azaanTime).unix(), 
          jamaatTime: moment(newSalatulInfo?.jamaatTime).unix()
        }

      const WitrNameObj = {
          namazName:WitrNameRef.current?.value,
          azaanTime: moment(newWitrInfo?.azaanTime).unix(), 
          jamaatTime: moment(newWitrInfo?.jamaatTime).unix()
        }
        
        
        if(TotalLength === 5){
          
          setClear(true);
          setSelectedNamazes([FajrObj,DhurObj,AsarObj,MaghribObj,IshaObj]);
          
        }else if(TotalLength === 6){
          
          if(JummahObj.namazName && JummahObj.azaanTime && JummahObj.jamaatTime)
          {
            setClear(true);
            setSelectedNamazes([FajrObj,DhurObj,AsarObj,MaghribObj,IshaObj,JummahObj]);
          }
          else{
            Clear && setClear(false);
            LaunchSnackBar();
          }
          
        }else if(TotalLength === 7){

          if(JummahObj.namazName && JummahObj.azaanTime && JummahObj.jamaatTime && TaraweehObj.namazName && TaraweehObj.azaanTime && TaraweehObj.jamaatTime )
          {
            setClear(true);
            setSelectedNamazes([FajrObj,DhurObj,AsarObj,MaghribObj,IshaObj,JummahObj,TaraweehObj]);
          }
          else{
            Clear && setClear(false);
            LaunchSnackBar();
          }
        
          
        }else if(TotalLength === 8){
          
          if(JummahObj.namazName && JummahObj.azaanTime && JummahObj.jamaatTime
             && TaraweehObj.namazName && TaraweehObj.azaanTime && TaraweehObj.jamaatTime
             && SalatulNameObj.namazName && SalatulNameObj.azaanTime && SalatulNameObj.jamaatTime)
          {
            setClear(true);
            setSelectedNamazes([FajrObj,DhurObj,AsarObj,MaghribObj,IshaObj,JummahObj,TaraweehObj,SalatulNameObj]);
          }
          else{
            Clear && setClear(false);
            LaunchSnackBar();
          }
        

        }else if(TotalLength === 9){
          
          if(JummahObj.namazName && JummahObj.azaanTime && JummahObj.jamaatTime
            && TaraweehObj.namazName && TaraweehObj.azaanTime && TaraweehObj.jamaatTime
            && SalatulNameObj.namazName && SalatulNameObj.azaanTime && SalatulNameObj.jamaatTime
            && WitrNameObj.namazName && WitrNameObj.azaanTime && WitrNameObj.jamaatTime)
         {
           setClear(true);
           setSelectedNamazes([FajrObj,DhurObj,AsarObj,MaghribObj,IshaObj,JummahObj,TaraweehObj,SalatulNameObj,WitrNameObj]);
         }
         else{
           Clear && setClear(false);
           LaunchSnackBar();
         }
         

        }
      }
     

      function customDayContent(day) {
        let extraDot = null;
          if(today.getMonth()+1 === (day.getMonth() + 1)){
         
              if( FirstMonthSelectedDays?.includes(day.getDate())){
                    extraDot = (
                      <div
                        style={{
                          height: "5px",
                          width: "5px",
                          borderRadius: "100%",
                          background: "green",
                          position: "absolute",
                          top: 1,
                          right: 1,
                        }}
                      />
                    )
               }
              if( FirstMonthIncompleteDays?.includes(day.getDate())){
                    extraDot = (
                      <div
                        style={{
                          height: "5px",
                          width: "5px",
                          borderRadius: "100%",
                          background: "orange",
                          position: "absolute",
                          top: 1,
                          right: 1,
                        }}
                      />
                    )
               }
           }
          else if((day.getMonth() + 1 === 1)){

            if( SecondMonthSelectedDays?.includes(day.getDate())){
              
              extraDot = (
                <div
                style={{
                  height: "5px",
                  width: "5px",
                  borderRadius: "100%",
                  background: "green",
                  position: "absolute",
                  top: 1,
                  right: 1,
                }}
                />
                )
            }
            if( SecondMonthIncompleteDays?.includes(day.getDate())){
              extraDot = (
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    borderRadius: "100%",
                    background: "orange",
                    position: "absolute",
                    top: 1,
                    right: 1,
                  }}
                />
              )
            }
           }
        return (
          <div>
            {extraDot}
            <span>{format(day, "d")}</span>
          </div>
        )
      }

      const handleFajrPrefilled = () => {

        if(FajrPrefilled){
          setFajrPrefilled(false);
        }else{
          setFajrPrefilled(true);
         }
      }
        
        const  handleMaghribPrefilled = () => {
          if(MaghribPrefilled){
            setMaghribPrefilled(false);
          }else{
            setMaghribPrefilled(true);
          }
      }

      const handleAdditionNetworkCall = (UploadData) => {
        let CurrentMonthDays = [];
        let NextMonthDays = [];
        let Day ;
    
        if(selectedMonth === 12){
          setNextMonth(1);
         }

        const res =  dispatch(addTiming(UploadData));
        res.then((result) => {

          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`New Timings Added`
            }  
          dispatch(ChangeSnackbar(snackbarDetails));  

          InitialStartTimings.push(...result.data);
          
          result.data.map((item) => {
    
            Day = new Date(item.date);

            if(selectedMonth < 12){
      
              if(Day.getMonth()+1 === selectedMonth){ 
                
                CurrentMonthDays.push(Day.getDate());
                FirstMonthSelectedDays.push(Day.getDate())
                
              }else if(Day.getMonth()+1 === NextMonth ){
      
                NextMonthDays.push(Day.getDate());
                SecondMonthSelectedDays.push(Day.getDate())
              }
            }
            else{
              if(Day.getMonth()+1 === selectedMonth){ 
                
                 CurrentMonthDays.push(Day.getDate());
                FirstMonthSelectedDays.push(Day.getDate())
                
              }else if(Day.getMonth()+1 === 1 ){
                
               NextMonthDays.push(Day.getDate());
               SecondMonthSelectedDays.push(Day.getDate())
              }
             }
           });
           setisAdding(false); 
        }
          else
          {
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:`Failed To Add Timings: Network or Server Issue`
            }  

            setisAdding(false);
            dispatch(ChangeSnackbar(snackbarDetails));     
          }
        })
      }


      const handleUpdationNetworkCall = (UploadData) => {

        if(selectedMonth === 12){
          setNextMonth(1);
         }
        const res =  dispatch(UpdateBulkTiming(UploadData,Masjid?._id));

        res.then((result) => {

          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Timings Updated`
            } 

          dispatch(ChangeSnackbar(snackbarDetails));  
               setisUpdating(false);

        }
          else
          {
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:`Failed To Add Timings: Network or Server Issue`
            }  

            setisUpdating(false);
            dispatch(ChangeSnackbar(snackbarDetails));     
          }
        })
      }

      const handleSingleUpdationNetworkCall = (UploadData,id) => {
        if(selectedMonth === 12){
          setNextMonth(1);
         }
        const res =  dispatch(UpdateBulkTiming(UploadData,id));
        
        res.then((result) => {
          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Timings Updated`
            } 
            
            let timings = {
              timings:result.data.timings,
              id:result.data._id
            }

            setisSingle('single');
            setTimingUpdates(timings);
            
            dispatch(ChangeSnackbar(snackbarDetails));  
          setisUpdating(false);
        }
        else
        {
          const snackbarDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:`Failed To Add Timings: Network or Server Issue`
          }  
          dispatch(ChangeSnackbar(snackbarDetails));   
          setisUpdating(false);
          
        }
      })
    }
    


      const handleDeletionNetworkCall = () => {
        let startDate = moment(state[0].startDate).format("YYYY/MM/DD");
        let endDate = moment(state[0].endDate).format("YYYY/MM/DD");

        const res = dispatch(DeletingTimingsByDateRange(startDate,endDate,Masjid._id,admin.adminName));

        res.then((result) => {
          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Deleted the Timings `
            }  

            setisDeleting(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
          else
          {
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:`Failed To Delete Timings: ${result.message}`
            }  
            setisDeleting(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
        })
      }


      const handleDeletionRandomRangeCall = (UploadData,times) => {
        const res = dispatch(deleteAllJummah(UploadData));
        res.then((result) => {

          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Deleted the Timings `
            }  
             setisDeleting(false);
            isDeletingNamazes && setisDeletingNamazes(false);
            dispatch(ChangeSnackbar(snackbarDetails));
            getTimings();
          }
          else
          {
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:`Failed To Delete Timings: ${result.message}`
            }  
            setisDeleting(false);
            setisDeletingNamazes(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
        })
      }



      const handleUpdationRandomRangeCall = (UploadData) => {
        const res = dispatch(updateAllJummah(UploadData));

        res.then((result) => {
          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Updated the Timings for the Range`
            }  
            setisUpdating(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
          else
          {
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:`Failed To Update Timings: ${result.message}`
            }  
            setisUpdating(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
        })
      }


      const handleAdditionCall = (UploadData) => {
        const res = dispatch(addNewNamaz(UploadData));

        res.then((result) => {
          if(result.success){
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Added the Namazes`
            }  
            getTimings();
            setisAddingNewNamaz(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
          else
          {
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"error",
              snackbarMessage:`Failed To Add Timings: ${result.message}`
            }  
            setisAddingNewNamaz(false);
            dispatch(ChangeSnackbar(snackbarDetails));   
          }
        })
      }


      const handleDeletionNamaz  = () => {
        setisDeleting(true);
        if(JummahsPreTimings?.length){

          let Timings = [...InitialStartTimings];
          let DeleteTimings = [...InitialStartTimings];
          let Times = [];
          let initialState={
            "type":"all",
            data:JummahsPreTimings
          }

          for (let i = 0; i < JummahsPreTimings.length; i++) {
            Times.push(...(DeleteTimings.filter((item) => item._id === JummahsPreTimings[i])));
            Timings = Timings.filter((item) => item._id !== JummahsPreTimings[i]);
          } 
     
          handleDeletionRandomRangeCall(initialState);
          setInitialStartTimings(Timings);
          handlingSelectedDays(Timings);
          handlingIncompleteDeleteDays(Times);
        }else{
          let selectedStartDate = moment(state[0].startDate);
          let selectedEndDate = moment(state[0].endDate);
          let difference = selectedEndDate.diff(selectedStartDate, 'days') + 1;
          let Times = [];
          let Timings = [...InitialStartTimings];

        if(state[0].startDate && state[0].endDate ){
          
         if(SelectedNamazes.length === TotalLength){
           handleDeletionNetworkCall();
          }

          for (let i = 0; i < difference; i++) {
            let date = moment(state[0].startDate).add('days', i);
            let removal = moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z";
            Times.push(...(Timings.filter((item) => item.date === removal)))
            Timings = Timings.filter((item) => item.date !== removal);
          } 
        }
        setInitialStartTimings(Timings);
        handlingSelectedDays(Timings);
        handlingIncompleteDeleteDays(Times);
      }
    }
    

    
      const handleAdditionNamaz = () => {
        setisAdding(true);
       if(JummahsNoTimings.length){

         let UploadData = [];
        let initialState= {};

        JummahsNoTimings.map((item) => {

            initialState.masjidId = Masjid?._id
            initialState.timings = [...SelectedNamazes];
            initialState.lastEditor = admin.adminName;
            initialState.date = moment(item).format("MM/DD/YYYY") + "Z";

              UploadData.push({...initialState});
        })

        handleAdditionNetworkCall(UploadData);
        
      }else{

        let UploadData = [];
        let initialState= {};
        let selectedStartDate = moment(state[0].startDate);
        let selectedEndDate = moment(state[0].endDate);
        let difference = selectedEndDate.diff(selectedStartDate, 'days') + 1;

        
        for (let i = 0; i < difference; i++) {
          
          let date = moment(state[0].startDate).add('days', i);

          initialState.masjidId = Masjid?._id
          initialState.timings = [...SelectedNamazes];
          initialState.lastEditor = admin.adminName;
          initialState.date = moment(date).format("MM/DD/YYYY") + "Z";
         
          UploadData.push({...initialState});
        } 
        handleAdditionNetworkCall(UploadData);
      }
    }
      
      
      
      const handleUpdationNamaz = () => {
        setisUpdating(true);

        if(JummahsPreTimings?.length){
          let UploadData = [];
          let NamazNames = [];
          SelectedNamazes.map((item) => {
            NamazNames.push(item);
          })

          JummahsPreTimings.map((item) => {
             UploadData.push({"id":item,"timings":[...NamazNames]})
          })

          setisSingle('multiple');

          handleUpdationRandomRangeCall(UploadData);

       }else{
        let RangeHasEmptyDay = false;
        let selectedStartDate = moment(state[0].startDate);
        let selectedEndDate = moment(state[0].endDate);
        let difference = selectedEndDate.diff(selectedStartDate, 'days') + 1;

        for (let i = 0; i < difference; i++) {
          let date = moment(state[0].startDate).add('days', i);
           if(!(InitialStartTimings.filter((item) => item.date === moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z").length > 0)){
            RangeHasEmptyDay =  true;
            break;
           }
        }

        if(RangeHasEmptyDay)
        {
          const snackbarDetails = {
            snackbarOpen:true, 
            snackbarType:"warning",
            snackbarMessage:`Please Select DAYS that Already has Timings to Update`
          }
          dispatch(ChangeSnackbar(snackbarDetails));  
          setisUpdating(false);
        }else{
          let initialState= {};
          const timings = InitialStartTimings.filter((item) => item.date === moment(selectedEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z")

          if(selectedStartDate.isSame(selectedEndDate))
          {
             if(SelectedNamazes.length === timings[0].timings.length)
             {
               initialState.masjidId = timings[0]?._id
               initialState.timings = [...SelectedNamazes];
               initialState.lastEditor = admin.adminName;
               initialState.date = moment(selectedEndDate).format("YYYY-MM-DD") + "Z";

               setisSingle('single');
               handleSingleUpdationNetworkCall(initialState, timings[0]?._id);

              }else{
               
               let UploadData = [];
               initialState.id = timings[0]?._id
               initialState.timings = [...SelectedNamazes];
               UploadData[0] = initialState;
               
               let timing = {
                timing:initialState.timings,
                id:timings[0]?._id
              }
              setTimingUpdates(timing);
              setisSingle('single');
              
               handleUpdationRandomRangeCall(UploadData);
                setisUpdating(false);
              }
          }
          else{
            
            let NamazNames = [];
            let UploadData = [];
           let difference = selectedEndDate.diff(selectedStartDate, 'days') + 1;
           
           SelectedNamazes.map((item) => {
             NamazNames.push(item);
           })
           
          for (let i = 0; i < difference; i++) {
            let date = moment(state[0].startDate).add('days', i);
            let timings = InitialStartTimings.filter((item) => item.date === moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z")
            UploadData.push({"id":timings[0]._id,"timings":[...NamazNames]})
         }

          let initialState={
            data:UploadData
          }

          setisSingle('multiple');
          handleUpdationRandomRangeCall(UploadData);
          }
        }
       }
      }


       
      const handleDeletingSelectedNamaz = () => {
        setisDeletingNamazes(true);

        if(JummahsPreTimings?.length){
          let UploadData = [];
          let times = []
          let NamazNames = [];
          SelectedNamazes.map((item) => {
            NamazNames.push(item.namazName);
          })

          JummahsPreTimings.map((item) => {
             UploadData.push({"id":item,"timings":[...NamazNames]})
          })
          let initialState={
            "type":"partial",
            data:UploadData
          }
          handleDeletionRandomRangeCall(initialState,JummahsToRemoveData);

       }else{
        let selectedStartDate = moment(state[0].startDate);
        let selectedEndDate = moment(state[0].endDate);

        if(selectedStartDate.isSame(selectedEndDate))
        {
          let times = [];
          let UploadData = [];
          let NamazNames = [];
          const timings = InitialStartTimings.filter((item) => item.date === moment(selectedEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z")

          times.push(...timings)
          SelectedNamazes.map((item) => {
            NamazNames.push(item.namazName);
          }) 
          UploadData.push({"id":timings[0]._id,"timings":[...NamazNames]})

          let initialState={
            "type":"partial",
            data:UploadData
          }
          handleDeletionRandomRangeCall(initialState,times);
        }
        else{
          let UploadData = [];
          let NamazNames = [];
          let times = [];
           let difference = selectedEndDate.diff(selectedStartDate, 'days') + 1;
           
           SelectedNamazes.map((item) => {
             NamazNames.push(item.namazName);
           })
           
          for (let i = 0; i < difference; i++) {
            let date = moment(state[0].startDate).add('days', i);
            let timings = InitialStartTimings.filter((item) => item.date === moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z")
            times.push(...timings);
            UploadData.push({"id":timings[0]._id,"timings":[...NamazNames]})
         }

          let initialState={
            "type":"partial",
            data:UploadData
          }
        
          handleDeletionRandomRangeCall(initialState,times);
        }
       }
      }
       


      const handleAddingSelectedNamaz = () => {
        setisAddingNewNamaz(true);
        if(JummahsPreTimings?.length){
          let UploadData = [];
          let times = []
          let NamazNames = [];
          SelectedNamazes.map((item) => {
            NamazNames.push(item);
          })

          JummahsPreTimings.map((item) => {
             UploadData.push({"id":item,"timings":[...NamazNames]})
          })

          handleAdditionCall(UploadData);

       }else{
        let selectedStartDate = moment(state[0].startDate);
        let selectedEndDate = moment(state[0].endDate);

        if(selectedStartDate.isSame(selectedEndDate))
        {
          let times = [];
          let UploadData = [];
          let NamazNames = [];
          const timings = InitialStartTimings.filter((item) => item.date === moment(selectedEndDate).format("YYYY-MM-DD") + "T00:00:00.000Z")

          times.push(...timings)
          SelectedNamazes.map((item) => {
            NamazNames.push(item);
          }) 
          UploadData.push({"id":timings[0]._id,"timings":[...NamazNames]})
    
          handleAdditionCall(UploadData);
        }
        else{
          let UploadData = [];
          let NamazNames = [];
          let times = [];
           let difference = selectedEndDate.diff(selectedStartDate, 'days') + 1;
           
           SelectedNamazes.map((item) => {
             NamazNames.push(item);
           })
           
          for (let i = 0; i < difference; i++) {

            let date = moment(state[0].startDate).add('days', i);
            let timings = InitialStartTimings.filter((item) => item.date === moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z")
            times.push(...timings);
            UploadData.push({"id":timings[0]._id,"timings":[...NamazNames]})
         }
          handleAdditionCall(UploadData);
        }
       }
      }



    const handleRangeChange = (item) => {
      setState([item.selection])
      let d1 = moment(item.selection.startDate).format("YYYY-MM-DD") + "T00:00:00.000Z";
      let d2 = moment(item.selection.endDate).format("YYYY-MM-DD") + "T00:00:00.000Z";

      if(InitialStartTimings.filter((item) => item.date === d1 ).length>0 || InitialStartTimings.filter((item) => item.date === d2 ).length>0){
         setUpdate(true);        
        }
        else{
        setUpdate(false);
      }
    }


    
    const handleEmptyJummahSelection = (e) => {
      let jummahsEmptyTimings = [];
      function isFriday(date = new Date()) {
        return date.getDay() === 5;
      }

      const endNextMonth = moment().add(1, 'M').endOf("month");
      const todayDate = moment();

      if(e.target.checked){
        setJummahAdding(true);
        let difference = endNextMonth.diff(todayDate, 'days') + 1;

        for (let i = 0; i < difference; i++) {
            let date = moment(todayDate).add('days', i);
                        
           if(isFriday(new Date(date))){
            if(InitialStartTimings.filter((item) =>  moment(item.date).isSameOrAfter(todayDate)  && item.date === moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z").length>0){
              continue;
            }
            else if( moment(date).isSameOrAfter(todayDate)){
              jummahsEmptyTimings.push(moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z")
            }
           }
        }
        setJummahsNoTimings(jummahsEmptyTimings);
      }
      else{
        setJummahsNoTimings([]);
        setJummahAdding(false);
      }
    } 


    const handleUpdateJummahSelection = (e) => {
      let jummahsFilledTimings = [];
      let jummahsTimings = [];
      function isFriday(date = new Date()) {
        return date.getDay() === 5;
      }
      const endNextMonth = moment().add(1, 'M').endOf("month");
      const todayDate = moment();

      if(e.target.checked){
        setJummahUpdating(true);
        let difference = endNextMonth.diff(todayDate, 'days') + 1;

        for (let i = 0; i < difference; i++) {
            let date = moment(todayDate).add('days', i);
           if(isFriday(new Date(date))){
            let timing = InitialStartTimings.filter((item) =>  moment(item.date).isSameOrAfter(todayDate)  && item.date === moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z");

            if(timing.length>0){
              jummahsFilledTimings.push(timing[0]._id);
              jummahsTimings.push(...timing)
              IncompleteDays.push(timing[0].date)
            }
           }
        }
        setJummahsPreTimings(jummahsFilledTimings);
        setJummahsToRemoveData(jummahsTimings);
      }else{
        setJummahsPreTimings([]);
        setJummahUpdating(false);
        setJummahsToRemoveData([]);
      }
    } 


    const TriggerNotification = () => {
     

      if(isSingle){
         
        if(isSingle ==="single"){

          let uploadData={
            TimingUpdates
          }

          const data =   dispatch(TimingsUpdateNotification(uploadData,TimingUpdates.id,"single",Masjid._id));
          data.then(function(result) {
              console.log(result);
              if(result.success){
                  const snackbarDetails = {
                    snackbarOpen:true, 
                      snackbarType:"success",
                     snackbarMessage:"Sent the Notifications to all the Masjid Users"
                   }   
                   dispatch(ChangeSnackbar(snackbarDetails)); 
                 }
                 else{
                  const snackbarDetails = {
                    snackbarOpen:true, 
                      snackbarType:"warning",
                     snackbarMessage:"Failed to Send the Notification to the Application"
                   }   
                   dispatch(ChangeSnackbar(snackbarDetails)); 
                 }
          })

        }
          else if(isSingle ==="multiple"){
          let uploadData=[]

          const data =   dispatch(TimingsUpdateNotification(uploadData,Masjid._id,"multiple",Masjid._id));
          data.then(function(result) {
              console.log(result);
              if(result.success){
                  const snackbarDetails = {
                    snackbarOpen:true, 
                      snackbarType:"success",
                     snackbarMessage:"Sent the Notifications to all the Masjid Users"
                   }   
                   dispatch(ChangeSnackbar(snackbarDetails)); 
                 }
                 else{
                  const snackbarDetails = {
                    snackbarOpen:true, 
                      snackbarType:"warning",
                     snackbarMessage:"Failed to Send the Notification to the Application"
                   }   
                   dispatch(ChangeSnackbar(snackbarDetails)); 
                 }
          })
          console.log(TimingUpdates);
          console.log(isSingle);
        }
      }else{
        const snackbarDetails = {
          snackbarOpen:true, 
          snackbarType:"warning",
          snackbarMessage:`Cannot Notify Users Without Timings Change`
        }  
       
        dispatch(ChangeSnackbar(snackbarDetails));
      }
    }


    
  return (
    <>
      <div className="ManageTableHeaderComponent">
       <div className="ManageTableTopComponent">
        <h3 className="ManageTableTitle">Manage Timings</h3>
       </div>
       <div className="ManageTableCalendarContainer">
       <DefinedRange
          onChange={item => handleRangeChange(item)}
          ranges={state}
          staticRanges={defaultStaticRanges}
        /> 
             <DateRange
                onChange={item => handleRangeChange(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                fullWidth
                editableDateInputs={true}
                dayContentRenderer={customDayContent}
                minDate={new Date()}
                maxDate = {new Date(new Date().getTime()+(45*24*60*60*1000))}
                ranges={state}
                direction="horizontal"
                preventSnapRefocus={true}
                calendarFocus="forward"
                style={{ width: '32rem' }}
              />
       </div>

       <div className="ManageTableSwitchContainer" style={{ marginLeft: '30px'}}>
       {

        (<button className="ButtonNotifications" onClick={TriggerNotification} >
            <span class="text">
              { false ? (
                <CircularProgress  size="20px" />
                ) : ("Notify Timings Change to Users")}
              </span>
          </button>)
        }
         <h4 >Prefilled Times For Selected Range </h4>
         <div className="detailItem">
         <span className="itemKey">Maghirb</span>
              <Switch checked={MaghribPrefilled} onChange={handleMaghribPrefilled} name="loading" color="primary" />
          </div>
          <h4 >For Managing Timings of All Jummah</h4> 
           <div className="detailItemJummah">
          {
            !JummahUpdating && (
              <>
                <span className="itemKey">Select All Jummah's To Add Timings </span>
              <Checkbox color="success" onChange={handleEmptyJummahSelection} ref={NewJummahRef}/>
              <br/>
              </>
            )

          }
         
          {!JummahAdding && ( 
            <>
            <span className="itemKey">Select All Jummah's To Update Timings </span>
            <Checkbox color="success" onChange={handleUpdateJummahSelection} ref={PreJummahRef} />
            <br/>
            </>
            )
          }
          </div>
       </div> 

       

       <table className="ManageTable" >
          <thead>
            <tr className="ManageTableHead">
            <th className="tableHeaderPrayerName">
              <span className="ManageTableHeaderContent">
              <input type="checkbox" className="ManageTableClassName" value="SelectAll" onClick={handleAllNamazSelect} ref={AllNamazSelectedRef}  />Prayer Name
              </span>
            </th>
              <th className="ManageTableHeaderAzaan"><span className="tableHeaderContent">Azaan Time</span></th>
              <th  className="ManageTableHeaderJamaat">
                <span className="ManageTableHeaderJamaatContent">
                  Jamaat Time
                 </span> 
                <button onClick={ handleNamazIncrease} style={{ outline: 'none',border:"none",background:"white"}}  disabled={TotalLength>=9}>
                  <IoIosAddCircleOutline className="IncreaseButton" />
                </button >
              </th>
            </tr>
          </thead>
 
          <tbody  className="ManageTableBody">
             {
           (CurrentSelectedDayTimings.length>0)&& (
               <tr>
                 <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName" name="namaz" id="allCheck" value={CurrentSelectedDayTimings[0]?.namazName} onClick={(e) =>  handleSelectNamaz(FajrNameRef,FajrAzaanRef,FajrJamaatRef,e)}/> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[0]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[0]?.namazName} placeholder={CurrentSelectedDayTimings[0].namazName} ref={FajrNameRef} sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                        />
                      </span>
                      { FajrPrefilled && <span className="APiInformation">Ⓘ Fajr timings are prefilled by location of the masjid</span>}
                   </td>
                  <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt"   name="namaz" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}} 
                    ref={FajrAzaanRef}
                    className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[0].azaanTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                     ref={FajrJamaatRef}
                     className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[0].jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                   </td>
              </tr>)
                  }

         {
           (CurrentSelectedDayTimings.length>1)&& (
              <tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[1]?.namazName} onClick={(e) => handleSelectNamaz(DhurNameRef,DhurAzaanRef,DhurJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[1]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[1]?.namazName} placeholder={CurrentSelectedDayTimings[1]?.namazName}
                         ref={DhurNameRef}  sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    ref={DhurAzaanRef}
                    className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[1].azaanTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    ref={DhurJamaatRef}
                    className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[1].jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                   </td>
              </tr>)}

              {
           (CurrentSelectedDayTimings.length>2)&& (
              <tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[2]?.namazName} onClick={(e) => handleSelectNamaz(AsarNameRef,AsarAzaanRef,AsarJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[2]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[2]?.namazName} placeholder={CurrentSelectedDayTimings[2]?.namazName}  
                        ref={AsarNameRef} sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    ref={AsarAzaanRef}
                    className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[2].azaanTime * 1000).format('HH:mm')}  
                    type="time" 
                    />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    ref={AsarJamaatRef}
                    className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[2].jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                   </td>
              </tr>)}


              {
           (CurrentSelectedDayTimings.length>3)&& (
              <tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[3]?.namazName} onClick={(e) => handleSelectNamaz(MaghribNameRef,MaghribAzaanRef,MaghribJamaatRef,e)}  /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[3]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[3]?.namazName} placeholder={CurrentSelectedDayTimings[3]?.namazName}
                         ref={MaghribNameRef} sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                       />
                      </span>
                        { MaghribPrefilled && <span className="APiInformation">Ⓘ Maghrib timings are prefilled by location of the masjid</span>}
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                     ref={MaghribAzaanRef}
                     className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[3].azaanTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                     ref={MaghribJamaatRef}
                     className="TimingsInput"
                    // value={moment(CurrentSelectedDayTimings[3].jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                   </td>
              </tr>
              )}


         {
           (CurrentSelectedDayTimings.length>4)&& (
              <tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[4]?.namazName} onClick={(e) => handleSelectNamaz(IshaNameRef,IshaAzaanRef,IshaJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[4]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[4]?.namazName} placeholder={CurrentSelectedDayTimings[4]?.namazName}
                         ref={IshaNameRef} 
                         
                         sx={{  marginBottom: 3 ,height: '30px'}} type="text" 
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    // value={moment(CurrentSelectedDayTimings[4].azaanTime * 1000).format('HH:mm')}  
                    ref={IshaAzaanRef}
                    className="TimingsInput"
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    // value={moment(CurrentSelectedDayTimings[4].jamaatTime * 1000).format('HH:mm')} 
                    ref={IshaJamaatRef}
                    className="TimingsInput"
                    type="time" />
                    </span>
                   </td>
              </tr>
           )}

            {
               (TotalLength>5 || CurrentSelectedDayTimings.length>5)&& (
                <><tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[5]?.namazName} onClick={(e) => handleSelectNamaz(JummahNameRef,JummahAzaanRef,JummahJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[5]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[5]?.namazName} placeholder={CurrentSelectedDayTimings[5]?.namazName}
                         ref={JummahNameRef}  sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                        
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    // value={moment(CurrentSelectedDayTimings[5]?.azaanTime * 1000).format('HH:mm')}  
                     ref={JummahAzaanRef}
                     className="TimingsInput"
                    type="time" />

                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    // value={moment(CurrentSelectedDayTimings[5]?.jamaatTime * 1000).format('HH:mm')} 
                    ref={JummahJamaatRef}
                    className="TimingsInput" 
                    type="time" />
                     <CiCircleRemove className="RemoveNamazButton" onClick={handleNamazDecrease}/>
                    </span>
                   </td>
                   
              </tr></>)
               }

              {
               (TotalLength>6 || CurrentSelectedDayTimings.length>6)&& (<tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[6]?.namazName} onClick={(e) => handleSelectNamaz(TaraweehNameRef,TaraweehAzaanRef,TaraweehJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[6]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[6]?.namazName} placeholder={CurrentSelectedDayTimings[6]?.namazName}
                         ref={TaraweehNameRef}  sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                     ref={TaraweehAzaanRef}
                     className="TimingsInput" 
                      // value={moment(CurrentSelectedDayTimings[6]?.azaanTime * 1000).format('HH:mm')}  
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                     ref={TaraweehJamaatRef}
                     className="TimingsInput"
                      // value={moment(CurrentSelectedDayTimings[6]?.jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                     <CiCircleRemove className="RemoveNamazButton" onClick={handleNamazDecrease}/>
                    </span>
                   </td>
              </tr>)
              } 
             {
               (TotalLength>7 || CurrentSelectedDayTimings.length>7) && (
              <tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[7]?.namazName} onClick={(e) => handleSelectNamaz(SalatulNameRef,SalatulAzaanRef,SalatulJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[7]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[7]?.namazName} placeholder={CurrentSelectedDayTimings[7]?.namazName} ref={SalatulNameRef}  sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    // value={moment(CurrentSelectedDayTimings[7]?.azaanTime * 1000).format('HH:mm')}  
                    className="TimingsInput" ref={SalatulAzaanRef}
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                       className="TimingsInput" ref={SalatulJamaatRef}
                    // value={moment(CurrentSelectedDayTimings[7]?.jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                     <CiCircleRemove className="RemoveNamazButton" onClick={handleNamazDecrease}/>
                    </span>
                   </td>
              </tr>)
              }
             {
               (TotalLength>8 || CurrentSelectedDayTimings.length>8)&& (
              <tr>
                  <td className="tableRow" >
                     <span className="tableHeaderContentBodyName">
                      <input type="checkbox" className="ManageTableClassName"  name="namaz" value={CurrentSelectedDayTimings[8]?.namazName} onClick={(e) => handleSelectNamaz(WitrNameRef,WitrAzaanRef,WitrJamaatRef,e)} /> 
                        <input fullWidth margin="dense" name={CurrentSelectedDayTimings[8]?.namazName} className="prayerName" defaultValue={CurrentSelectedDayTimings[8]?.namazName} placeholder={CurrentSelectedDayTimings[8]?.namazName} ref={WitrNameRef}  sx={{  marginBottom: 3 ,height: '30px'}} 
                        type="text" 
                       />
                      </span>
                   </td>
                   <td className="tableRow" >
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                    // value={moment(CurrentSelectedDayTimings[8]?.azaanTime * 1000).format('HH:mm')}  
                    className="TimingsInput" ref={WitrAzaanRef}
                    type="time" />
                    </span>
                  </td>
                  <td className="tableRow">
                    <span className="tableHeaderContentBody">
                    <input  id="outlined-required" disableunderline name="appt-time" sx={{border: '1.5px solid grey',padding:'10px' , borderRadius: 1}}
                       className="TimingsInput" ref={WitrJamaatRef}
                    // value={moment(CurrentSelectedDayTimings[8]?.jamaatTime * 1000).format('HH:mm')}  
                    type="time" />
                     <CiCircleRemove className="RemoveNamazButton" onClick={handleNamazDecrease}/>
                    </span>
                   </td>
              </tr>)
              }
 


          </tbody>

        </table>
        <div className="BottomTableComponent">
          <div className="BottomTableButtons">

          <button class="BottomTableCancelButton" onClick={hanldeManageTimings} ><span class="text">Cancel</span></button>

           { SelectedNamazes.length === TotalLength &&  <button class="BottomTableDeleteButton" onClick={handleDeletionNamaz} disabled={isDeleting}><span class="text"> 
             {   isDeleting ? (
                    <CircularProgress  size="20px" />
                  ) : ("Delete All Timings")
                  }</span></button> }

          {  
          Update ?
             <button class={(SelectedNamazes.length>0 && Clear) ? "BottomTableSaveButtonActive": "BottomTableSaveButtonDeactive"} onClick={handleUpdationNamaz}  disabled={SelectedNamazes.length<1 && Clear && isUpdating}>
              <span class="text">
                  {isUpdating ? (
                    <CircularProgress  size="20px" />
                  ) : ("Updating Timings")
                  }
              </span>
            </button>
            :<button class={(SelectedNamazes.length>0 && Clear) ? "BottomTableSaveButtonActive": "BottomTableSaveButtonDeactive"}  onClick={handleAdditionNamaz}  disabled={SelectedNamazes.length<1 && Clear && isAdding}>
            <span class="text">
                  {isAdding ? (
                    <CircularProgress  size="20px" />
                  ) : ("Save Timings")
                  }
              </span>
            </button>
            }
            {
              Update &&  <button class={(SelectedNamazes.length>0 && Clear) ? "BottomTableDeleteButton": "BottomTableSaveButtonDeactive"} onClick={handleDeletingSelectedNamaz}  disabled={SelectedNamazes.length<1 && Clear && isDeletingNamazes}>
              <span class="text">
                  {isDeleting ? (
                    <CircularProgress  size="20px" />
                  ) : ("Delete Namaz")
                  }
              </span>
            </button>
            }
            {
              Update &&  <button class={(SelectedNamazes.length>0 && Clear) ? "BottomTableSaveButtonActive": "BottomTableSaveButtonDeactive"} onClick={handleAddingSelectedNamaz}  disabled={SelectedNamazes.length<1 && Clear && isAddingNewNamaz}>
              <span class="text">
                  {isAddingNewNamaz ? (
                    <CircularProgress  size="20px" />
                  ) : ("Add Namaz")
                  }
              </span>
            </button>
            }
          </div>
        </div>

     </div>

    </>
  )
}

export default ManageTimingsTableContent