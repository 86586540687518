import React,{useState,useEffect,useRef} from 'react';
import './EventsComponent.scss';
import { GrMapLocation } from "react-icons/gr";
import { Link} from 'react-router-dom';
import moment from 'moment';
import { AiTwotoneDelete } from "react-icons/ai";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from "@material-ui/core";
import {deleteEvent} from '../../../Redux/Actions/EventsActions/DeletingEvents.js';
import { useDispatch } from 'react-redux';


const EventCard = ({item,setMasjidEventsData,MasjidEventsData}) => {
   
  const [OpenDeleteEventModal, setOpenDeleteEventModal] = useState(false);
  const [isUnDeleting, setisUnDeleting] = useState(false);
  const dispatch = useDispatch();
  
      
    const OpenGoogleMaps = (Latitude,Longitude) => {
        window.open(`https://maps.google.com?q=${Latitude},${Longitude}`);
      }; 

      const handleOpenDeleteModal = () => {
        
        setOpenDeleteEventModal(true);
      }
      
      const handleCloseDeleteModal = () => {
        setOpenDeleteEventModal(false);
      }
      
      
      const handleDeleteEvent = () => {
        
        setisUnDeleting(true);
        let res = dispatch(deleteEvent(item._id));
        
        res.then(function(result) {
          if(result.success)
          {
            setisUnDeleting(false);
            setOpenDeleteEventModal(false);
            setMasjidEventsData( MasjidEventsData.filter((event) => event._id !== item._id));
          }    
          else{
            setisUnDeleting(false);
          }
        })
      }
      
    return (
        <div className="EventsCarouselCard">
           <Dialog open={OpenDeleteEventModal}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to Delete the Event ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={handleDeleteEvent} variant="outlined"  >  { isUnDeleting ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                 <Button onClick={handleCloseDeleteModal} variant="outlined"  disabled={isUnDeleting}>No</Button>
              </DialogActions>
           </Dialog>
                  <Link to={"/events/" + item._id} style={{ textDecoration: "none" }}>
                        <span className="cardTitle"> {item.eventName}  </span>
                        </Link>
                        <span className="timings"> { moment(item.timings[0].startDate).format("DD/MM/YYYY") } - { moment(item.timings[0].endDate).format("DD/MM/YYYY")}</span>
                        <span className="timings"> { moment(item.timings[0].startTime).format("hh:mm A") } - {  moment(item.timings[0].endTime).format("hh:mm A")}</span>
                        <div className="EventsLocation"> 
                          <span className="locTitle">Location: </span>
                            <span className="masjidTitle"> {item.masjidName} </span>
                              <span className="masjidTitle"><GrMapLocation size={'1rem'} style={{cursor:"pointer",}} onClick={(e) => OpenGoogleMaps(item.location.coordinates[0],item.location.coordinates[1])}/>   </span>
                        </div>
                        <span className="addressTitle">  {item.description} </span>
                <AiTwotoneDelete style={{color:'red',height:'20px',cursor:'pointer'}} onClick={handleOpenDeleteModal} />        
        </div>
    )
}

export default EventCard
