import { CHANGE_SIDEBAR } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const SidebarOpenReducer =  (sidebarState =[], action) => {

   switch (action.type) {

    case CHANGE_SIDEBAR:

      return !action.payload;

    default:
      return sidebarState;
  }
};

export default SidebarOpenReducer;