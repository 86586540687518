import React,{useState} from 'react';
import './TopBar.css';
import { BsFillBellFill } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import { GoThreeBars } from "react-icons/go";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import {useSelector} from 'react-redux';
import {ChangeSidebar} from '../../../Redux/Actions/SidebarActions/ChangeSidebar.js';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";


const TopBar = () => {
 
  const dispatch = useDispatch();
  const admin = JSON.parse(localStorage.getItem('admin'));
  const sidebarState = useSelector((state) => state.sidebarState);
  const [Open, setOpen] = useState(true)


  const handleClick = () => {
      
    dispatch(ChangeSidebar(Open));
    setOpen(!Open);

  }

 

  return (
    <>
    <div className="TopBarHomeContainer">
               <div className="TopBarLeft">
                {
                  sidebarState ? <AiOutlineArrowLeft className="SidebarIcon" onClick={handleClick}/>: <GoThreeBars className="SidebarIcon" onClick={handleClick}/>
                  
                } 
               </div>
               <div className="TopBarRight"> 
               <Link to={"/admins/" + admin.adminName} style={{ textDecoration: "none" }}>
                  <img src="https://raw.githubusercontent.com/rehaanredkar15/SMILE-Social-Media/master/public/Assets/person/user.webp"  className="topbarImg" />
                </Link>
               <Link to={"/admins/" + admin.adminName} style={{ textDecoration: "none" }}>
                  <div className="TopUserName">{admin && admin.adminName}</div>
                </Link>
               </div>
    </div>
    </>
  )
}

export default TopBar