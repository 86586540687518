import { AUTH_LOGIN } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const authLogin = (formData,navigate) => async(dispatch) => {

    try{

  
      const { data } = await api.LoginAdmin(formData);

      if(data.success){
         console.log(data.data);
         if(data.data.isTwoFactorAuthentication || data.data.twoFactorAuthentication){

            let isTwoFAUser ={
               success:true,
               TwoFAUser:true,
               adminId:data.data.id, 
            }; 
            return isTwoFAUser;
         }else{
          
            let isTwoFAUser ={
               success:true,
               TwoFAUser:false,
               adminId:data.data._id, 
            };

            let authTokens = {
               "accessToken":data.data.accessToken,
               "refreshToken":data.data.refreshToken
            }

            
            if(authTokens.length>0){
               
               console.log(authTokens);
            }

            localStorage.setItem("authTokens", JSON.stringify(authTokens));
         
            dispatch({type:"AUTH_LOGIN" , payload:data.data});
   
            window.location.reload();

            return isTwoFAUser;
         }
      }
      return data;
    }    
    catch(error)
   {
      // if(error.message){
      //    const snackbarDetails = {
      //       snackbarOpen:true, 
      //        snackbarType:"error",
      //        snackbarMessage:`Failed To Log in: ${error.message}`
      //     }  
      //     setTimeout(() => {
             
      //        dispatch(ChangeSnackbar(snackbarDetails));
      //     }, 1000);  
      //   }

      let isTwoFAUser ={
         success:false,
         TwoFAUser:false,
         error:`Failed to Login`,
         message:error.message
      }; 
      return isTwoFAUser;


   }
}