import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from "react-redux";
import Store from "./Redux/Store";
import { StateContextProvider } from './contexts/StateContextProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
     <StateContextProvider>
       <Provider store={Store}>
         <App />
       </Provider>
     </StateContextProvider>
    </MuiPickersUtilsProvider>
);

