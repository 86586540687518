import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const UpdateAdminMasjidById = (upadatedData) => async(dispatch) => {

  try{

    const { data } = await api.updateAdminMasjidById(upadatedData);

    if(data.success)
    {
      const snackbarDetails = {
          snackbarOpen:true, 
           snackbarType:"success",
           snackbarMessage:"Updated Admin",
         }
    
        dispatch(ChangeSnackbar(snackbarDetails));
       return data;
     }
   return data;
  }    
  catch(error)
 {
    const snackbarFailureDetails = {
       snackbarOpen:true, 
        snackbarType:"error",
        snackbarMessage:error.response.data ?error.response.data.message:"Updating Admin Failed",
      }
 
     dispatch(ChangeSnackbar(snackbarFailureDetails)); 
    console.log(error);

 }
}