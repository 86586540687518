import { FETCH_MASJIDS } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const getMasjids = (sortParam,sortOrder) => async(dispatch) => {


    try{
    
      const { data } = await api.fetchMasjids(sortParam,sortOrder);

      if(data.success)
      {
         
         dispatch({type:"FETCH_MASJIDS" , payload: data.data})
         return data;
       }
     return data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response.data ?error.response.data.message:"Fetching Masjid Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      return error.response.data;

   }
}