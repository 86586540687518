import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import { FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN } from '../../ActionType';


export const FetchingLatestUpdatedEventsById = (id) => async(dispatch) => {

    try{
    
      const { data } = await api.fetchLatestUpdatedEventsByAdminId(id);

      if(data.success)
      {
         dispatch({type:"FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN" , payload: data.data})

         return data;
       }

     return data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response?.data ?error.response?.data.message:"Fetching Event Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 

      return error.response;

   }
}