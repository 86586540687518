import { FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN } from '../../ActionType';


// eslint-disable-next-line import/no-anonymous-default-export
const FetchLatestEventsByAdminReducer =  (LatestAdminEvents = [] , action) => {
    switch (action.type) {
  
      case FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN:
  
        return action.payload;
  
      default:
        return LatestAdminEvents;
    }
  };
  
  export default FetchLatestEventsByAdminReducer;