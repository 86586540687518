import { GET_ALL_ADMIN } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchingAdminReducer =  (admins = [] , action) => {
  switch (action.type) {

    case GET_ALL_ADMIN:

      return action.payload;

    default:
      return admins;
  }
};

export default FetchingAdminReducer;