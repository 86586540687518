export const MasjidTimes = [
    {
      namazName:"Fajr",
      azaanTime:1663803600,
      jamaatTime:1663804800
    },
    {
      namazName:"Dhur",
      azaanTime:1663790400,
      jamaatTime:1663792200
    },
    {
      namazName:"Asar",
      azaanTime:1663843200,
      jamaatTime:1663845000
    },
    {
      namazName:"Maghrib",
      azaanTime:1663854300,
      jamaatTime:1663856100
    },
    {
      namazName:"Isha",
      azaanTime:1663862400,
      jamaatTime:1663863600
    },
    
  ];