import React,{useEffect,useState,useRef} from 'react'
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';
import { Link } from "react-router-dom";
import { MasjidColumns } from "./MasjidColumns.js"
import {useSelector} from 'react-redux';
import {getMasjids} from '../../../Redux/Actions/MasjidActions/FetchingMasjids.js'
import {getAdminMasjids} from '../../../Redux/Actions/MasjidActions/FetchingMasjidByAdmin.js'
import { useDispatch } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { FcPlus } from "react-icons/fc";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import './MasjidTablle.scss';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import { useDebounce } from 'use-debounce';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useStateContext } from '../../../contexts/StateContextProvider';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import { useNavigate } from "react-router-dom";
import {addNewMasjid} from '../../../Redux/Actions/MasjidActions/AddingMasjids.js';


//   useEffect(() => {
//     if(admin.role ==="subadmin"){
//       const response =  dispatch(getAdminMasjids(admin._id));
//       response.then(function(result) {
//       if(result.success){
//            setAdminMasjidData(result.data);
//            }
//       });
//     }
//  }, [])


const MasjidTable = () => {
  let navigate = useNavigate();
  const admin = JSON.parse(localStorage.getItem("admin"));
  const currentAdmin  = useSelector((state) => state.currentAdmin);
  const AdminMasjids  = useSelector((state) => state.AdminMasjids);
  const masjids  = useSelector((state) => state.masjids);
  const [MasjidData, setMasjidData] = useState([]);
  const [MasjidNames, setMasjidNames] = useState([]);
  const [AdminMasjidData, setAdminMasjidData] = useState(AdminMasjids);
  const dispatch = useDispatch();
  const [AddModalOpen, setAddModalOpen] = useState(false);
  const [country, setCountry] = React.useState('');
  const [MasjidAddress, setMasjidAddress] = useState('');
  const [MasjidName, setMasjidName] = useState('');
  const [MasjidDesc, setMasjidDesc] = useState('');
  const [MasjidLatitude, setMasjidLatitude] = useState('');
  const [MasjidLongitude, setMasjidLongitude] = useState('');
  const [MasjidContact, setMasjidContact] = useState('');
  const [MasjidCountry, setMasjidCountry] = useState('');
  const [ MasjidAddressError, setMasjidAddressError] = useState('');
  const [MasjidNameError, setMasjidNameError] = useState('');
  const [MasjidBracketError, setMasjidBracketError] = useState('');
  const [MasjidDescError, setMasjidDescError] = useState('');
  const [MasjidLatitudeError, setMasjidLatitudeError] = useState('');
  const [MasjidLongitudeError, setMasjidLongitudeError] = useState('');
  const [MasjidCountryError, setMasjidCountryError] = useState('');
  const [MasjidPhoneError, setMasjidPhoneError] = useState('');
  const [MasjidDuplicationDropDown, setMasjidDuplicationDropDown] = useState(false);
  const [DropDownOpen, setDropDownOpen] = useState(false);
  const [LatPaste, setLatPaste] = useState('')
  const [LonPaste, setLonPaste] = useState('')

  
  useEffect(() => {
    
    if( admin.role ==="superadmin" || admin.role === "dataentry"){
     masjids.length === 0 && dispatch(getMasjids("createdAt","asc"));
    }else{

      AdminMasjids.length === 0 && dispatch(getAdminMasjids(admin._id));
    }
  }, [])

  let MasjidNameRef = useRef(null);
  let masjidNameInputErrorRef = useRef(null);
  let CountryRef = useRef();
  let ContactValueRef = useRef();
  let MasjidDescRef = useRef(null);
  let MasjidAddressRef = useRef(null);
  let MasjidLatitudeRef = useRef(null);
  let MasjidLongitudeRef = useRef(null);
  let MasjidContactRef = useRef(null);
  let MasjidCountryRef = useRef(null);
  const [text, setText] = useState('');
  const { setSearchTerm } = useStateContext();
  const { results } = useStateContext();
  const [debouncedValue] = useDebounce(text, 1600);
  const { loading, getResults, searchTerm } = useStateContext();
  const [selectedValue, setSelectedValue] = useState(null);

  const handleModalUpdateClose = () => {
    setAddModalOpen(false);
    setMasjidBracketError(false);
    setMasjidAddressError(false);
    setMasjidNameError(false);
    setMasjidDescError(false);
    setMasjidLatitudeError(false);
    setMasjidLongitudeError(false);
    setMasjidPhoneError(false);
    setMasjidCountryError(false);
    setCountry("");
  }

  const handleAddMasjid = (data) => {

    const response = dispatch(addNewMasjid(data,navigate));

    response.then(function(result) {

      if(result?.success)
      {
        setAddModalOpen(false);
      //  getMasjidData();
       setSelectedValue(null);
      }
      else 
      {
        const snackbarFailureDetails = {
          snackbarOpen:true, 
           snackbarType:"error",
           snackbarMessage:result.message ?result.message:"Adding Masjid Failed",
         }
    
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      }            
    })
  }

  const handleNewMasjid = () => {
    // setAddModalOpen(false);
    // setMasjidBracketError(false);
    let coordinates = [parseFloat(MasjidLatitudeRef.current?.value),parseFloat(MasjidLongitudeRef.current?.value)];

    if(text.length<=0){
      setMasjidAddressError(true);
    }else if(MasjidAddressRef.current?.value){
      setMasjidAddressError(false);
    }

    if(!MasjidNameRef.current?.value){
      setMasjidNameError(true);
    }else if(MasjidNameRef.current?.value){
      setMasjidNameError(false);
    }

    if(!MasjidDescRef.current?.value){
      setMasjidDescError(true);
    }else if(MasjidDescRef.current?.value){
      setMasjidDescError(false);
    }

    if(!MasjidLatitudeRef.current?.value){
      setMasjidLatitudeError(true);
    }else if(MasjidLatitudeRef.current?.value){
      setMasjidLatitudeError(false);
    }

    if(!MasjidLongitudeRef.current?.value){
      setMasjidLongitudeError(true);
    }else if(MasjidLongitudeRef.current?.value){
      setMasjidLongitudeError(false);
    }

    // if(!country){
    //   setMasjidCountryError(true);
    // }else if(MasjidLongitudeRef.current?.value){
    //   setMasjidCountryError(false);
    // }
    
    if(!(MasjidContactRef.current?.value)){
      setMasjidPhoneError(true);
    }else if(MasjidLongitudeRef.current?.value){
      setMasjidPhoneError(false);
    }

      
      let updatedData = {
        address:text,
        masjidName:MasjidNameRef.current?.value,
        description:MasjidDescRef.current?.value,
        country:country,
        contact:MasjidContactRef.current?.value,
        location: { "type": "Point","coordinates": [coordinates[1],coordinates[0]]}
      } 
          
      if(coordinates[0] && coordinates[1] && MasjidDescRef.current?.value &&  MasjidNameRef.current?.value && text && country && !MasjidBracketError){
        handleAddMasjid(updatedData);
        
      }
       else{
        const snackbarDetails = {
          snackbarOpen:true, 
            snackbarType:"warning",
           snackbarMessage:"Please enter correct details before trying again"
         }   

       dispatch(ChangeSnackbar(snackbarDetails)); 
       }
  }
   




  const handleAddMasjidModal = () => {
    setAddModalOpen(true);
  }
   

  function validate(field, regex) {
    if (regex.test(field.value)) {
      field.className = "correctInput";
      if(MasjidBracketError){
        setMasjidBracketError(false);
      }
    } else {
      if(!MasjidBracketError){
        setMasjidBracketError(true);
      }
      field.className = "errorInput";
      
     }
   }

   const checkBrackets = (field) => {
    const stack = [];
    const bracketLookup = {
      '{': '}','(':')','[': ']'
    };
    for (const key of field.value) {
      if(Object.keys(bracketLookup).includes(key)) { // matches open brackets
        stack.push(key);
      } else if(Object.values(bracketLookup).includes(key)) { //matches closed brackets
        const lastBracket = stack.pop();
        if(bracketLookup[lastBracket] !== key) {
          return false;
        }
      }
    }
    if (stack.length>0) {
      if(!MasjidBracketError){
        setMasjidBracketError(true);
      }
      field.className = "errorInput";
    }
    else if(MasjidBracketError){
        setMasjidBracketError(false);
    }
    return stack.length === 0;
  } 


  const checkDuplications = (name) => {
    if(name.value?.length >= 5 && name.value?.length <= 35){
      let masjidNames = [];
      let Names = masjids.filter((item) => { 
        let masjidName = item.masjidName.toLowerCase().trim();
        return masjidName.includes(name.value.toLowerCase().trim())
      })
      
      if(Names.length>0){
        !MasjidDuplicationDropDown && setMasjidDuplicationDropDown(true)
        Names.map((item) => masjidNames.push(item.masjidName))
        setMasjidNames(masjidNames)
      }else{
        setMasjidNames([]) 
        setMasjidDuplicationDropDown(false)
      }
    }else{
      if(name.value.length<4){
        MasjidDuplicationDropDown && setMasjidDuplicationDropDown(false)
      }
    }
  }
   
  const patterns = {
    masjidName: /^[a-zA-Z ,()-]+$/,
  };

  const handleInputCheckChange = (e) => {
     validate(e.target, patterns[e.target.attributes.name.value]);
     checkBrackets(e.target);
     checkDuplications(e.target);
  }

  const handleCountryChange = (country) => {
    CountryRef.current = country; 
    setCountry(country);
};
    
    const handlePhoneChange = (event) => {
      if(1 === 2){
        
      if(!MasjidAddressRef.current?.value){
        setMasjidAddressError(true);
      }else if(MasjidAddressRef.current?.value){
        setMasjidNameError(false);
      }

      if(!MasjidNameRef.current?.value){
        setMasjidNameError(true);
      }else if(MasjidNameRef.current?.value){
        setMasjidNameError(false);
      }


      if(!MasjidDescRef.current?.value){
        setMasjidDescError(true);
      }else if(MasjidDescRef.current?.value){
        setMasjidDescError(false);
      }

      if(!MasjidLatitudeRef.current?.value){
        setMasjidLatitudeError(true);
      }else if(MasjidLatitudeRef.current?.value){
        setMasjidLatitudeError(false);
      }

      if(!MasjidLongitudeRef.current?.value){
        setMasjidLongitudeError(true);
      }else if(MasjidLongitudeRef.current?.value){
        setMasjidLongitudeError(false);
      }
      }
    };

    const handleDropDown = () => {
      setDropDownOpen(false);
    }
 
    const onChoose = (searchTerm) => {
      setText(searchTerm.display_address);
      setSelectedValue(searchTerm);
      setDropDownOpen(false);
    };

    const handleTextChange = (event) => {
      setText(event.target.value)
      if(event.target?.value.length<=0){
        setDropDownOpen(false);
      }
      
      // if(!FocusRef.current){ FocusRef.current = true }
    };

    const handleLatPaste = (e) => {
      let pasted = e.clipboardData.getData('Text')
      let string1  = `${pasted}`;
     if(string1.includes(',')){
    
       let locOfDivide = string1.indexOf(',');
       let lat = string1.substr(0,locOfDivide);
       let lon = string1.substr(locOfDivide +2);
       setLatPaste(parseFloat(lat));
       console.log(parseFloat(lat));
       MasjidLatitudeRef.current.value = parseFloat(lat);
       setLonPaste(parseFloat(lon));
       MasjidLongitudeRef.current.value = parseFloat(lon);
      }
      else{
        setLatPaste(parseFloat(string1));
        MasjidLatitudeRef.current.value = parseFloat(string1);
      }
    };
    
    const handleLongPaste = (e) => {
      let pasted = e.clipboardData.getData('Text')
      let string1  = `${pasted}`;
      if(string1.includes(',')){
        let locOfDivide = string1.indexOf(',');
        let lat = string1.substr(0,locOfDivide);
        let lon = string1.substr(locOfDivide +2);
        setLatPaste(parseFloat(lat));
        MasjidLatitudeRef.current.value = parseFloat(lat);
       setLonPaste(parseFloat(lon));
       MasjidLongitudeRef.current.value = parseFloat(lon);
      }
      else{
        setLonPaste(parseFloat(string1));
        MasjidLongitudeRef.current.value = parseFloat(string1);
      }
    };
  

    useEffect(() => {
      if(searchTerm === ''){
        setSelectedValue(null);
      }
      if (searchTerm !== '') {

          if(searchTerm.length < 28)
          {
            getResults(searchTerm);
            if(!(results.error) && results.length>0){
              setDropDownOpen(true);
            }else{
              setDropDownOpen(false);
            }
          }
        }
    }, [searchTerm]);

    useEffect(() => {
      if(text.length > 28)
      {
        DropDownOpen && setDropDownOpen(false);
        if(text.length > 35)
        {
          // const res = masjids.filter((item) => item.address === text);
  
          // if(res.length>0)
          // {
          //   !AddrressInputError && setAddrressInputError(true);
          //   const snackbarFailureDetails = {
          //     snackbarOpen:true, 
          //     snackbarType:"error",
          //     snackbarMessage:"This Address Already Exists",
          //   }
          //   dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          // }
          // else{
          //   AddrressInputError && setAddrressInputError(false);
  
          // }
        }
      } 
      if(debouncedValue) setSearchTerm(debouncedValue);
    }, [debouncedValue]);

    
  const handleEmptyPaste = (e) => {
    if(text.length<1)
    {
      const snackbarFailureDetails = {
        snackbarOpen:true, 
         snackbarType:"warning",
         snackbarMessage:"Please Add the Address to paste the longitude and latitude",
       }
      dispatch(ChangeSnackbar(snackbarFailureDetails)); 
    }
 };

 
  
  
    return (
        <div className="StaleMasjidTableContainer">
         <Dialog open={AddModalOpen} height={"xl"} >
           <DialogTitle>Add New Masjid</DialogTitle>
            <DialogContent >
                <input
                 id="outlined-required"
                  fullWidth
                  margin="dense"
                  name="masjidName"
                  className="defaultNameInput"
                  placeholder="Enter the masjid Name"
                  sx={{  marginBottom: '20px' ,height: '30px',width:'80%'}}
                  type="text"
                  min={6}
                  autoComplete="Off"
                  required
                  pattern="/^[a-zA-Z ,()-]+$/"
                  onChange={handleInputCheckChange}
                  ref={MasjidNameRef}
                  defaultValue={MasjidNameRef.current?.value && MasjidNameRef.current?.value} 
                  />
               { MasjidBracketError || MasjidNameError ? <p className="helperTextInput" ref={masjidNameInputErrorRef}>Masjid Name  must have letters and circular brackets only  and contain at least 6 characters </p> : <div className="BottomForName"> </div> }
               {
                 MasjidDuplicationDropDown && (<div className="MasjidNamesDropdown">
                <div className="MasjidNamesDropdownContent">
                  {
                    MasjidDuplicationDropDown && MasjidNames.slice(0, 5).map((item) => (
                      <Link to={"/masjid/" + item} className="MasjidNamesDropdownElement" >
                        {item}
                      </Link>
                      )
                      )
                    }
                  </div>
                </div>)
                    }
                      <br/>
                <div style={{display:'flex',flexDirection:'row'}}>
                  <TextField
                     key="address"
                     value={text}
                     error={MasjidAddressError}
                     helperText={MasjidAddressError?"Manditory field":""}
                     autoComplete="Off"
                     type="text"
                     placeholder="🔎 Enter Masjid Address or Name"
                        onChange={(e) => {
                          handleTextChange(e)
                        }}
                        inputProps={{style: {fontSize: 13}}}
                        fullWidth   /> 
                     { DropDownOpen && <AiOutlineCloseCircle className="CloseIcon" onClick={handleDropDown}/>}
                     </div>
                     { !DropDownOpen &&  <div style={{marginBottom:'1.2rem'}}></div>} 
                  {DropDownOpen && results.length>0 && (!(results.error)) && (<div className="MasjidNamesDropdown">
                <div className="MasjidNamesDropdownContent">
                    {
                      results.length>0 && (!(results.error))&& results.slice(0, 15).map((item) => (
                        <div onClick={() => onChoose(item)} className="dropdownElement">
                          {item.display_name}
                        </div>
                        )
                      )
                      }
                 </div>
                </div>)
                  }
              {
                text.length>10 && !(selectedValue) ? 
                    <TextField 
                    required
                    id="outlined-required"
                    autoComplete='off'
                    error={MasjidLatitudeError}
                    helperText={MasjidLatitudeError?"Manditory field":""}
                    sx={{  marginBottom: 3,marginTop:2}}
                    placeholder="Latitude"
                    defaultValue={LatPaste && LatPaste} 
                    onPaste={(e)=>{
                      // e.persist();
                      e.target.blur()
                      handleLatPaste(e)
                     }}
                    //  onChange={(e) => {
                    //    console.log('changes')
                    //  }}
                    inputRef={MasjidLatitudeRef}
                    type="number"
                  /> :
                    <TextField
                    type="number"
                    required
                    error={MasjidLatitudeError}
                    helperText={MasjidLatitudeError?"Manditory field":""}
                    id="outlined-required"
                    autoComplete='off'
                    inputRef={MasjidLatitudeRef}
                    onChange={(e) => {
                      if(text.length<10){
                        setSelectedValue(null)
                      }
                    }}
                    sx={{  marginBottom: 3,marginTop:2}}
                    onPaste={(e)=>{
                      e.preventDefault();
                      handleEmptyPaste(e)
                      return false;
                    }
                  }
                  placeholder="Latitude"
                    value = {selectedValue && selectedValue.lat}
                  />}
                  {
                    text.length>18 && !selectedValue ? 
                    <TextField 
                    required
                    id="outlined-required"
                    autoComplete='Off'
                    error={MasjidLongitudeError}
                    helperText={MasjidLongitudeError?"Manditory field":""}
                    sx={{  marginBottom: 3 , marginLeft: 5,marginTop:2}}
                    placeholder="Longitude"
                    defaultValue={LonPaste && LonPaste} 
                    onPaste={(e)=>{
                      // e.persist();
                      e.target.blur()
                      handleLongPaste(e);
                    }}
                    inputRef={MasjidLongitudeRef}
                    type="number"
                    
                  /> :
                      <TextField
                      required
                      type="number"
                      autoComplete='Off'
                      error={MasjidLongitudeError}
                      helperText={MasjidLongitudeError?"Manditory field":""}
                      sx={{  marginBottom: 3 , marginLeft: 7,marginTop:2}}
                      placeholder="Longitude"
                      id="outlined-required"
                      inputRef={MasjidLongitudeRef}
                      onPaste={(e)=>{
                        e.preventDefault();
                       handleEmptyPaste(e)
                       return false;
                      }}
                      value = {selectedValue && selectedValue.lon}
                    />
                  }

                 <div className="phoneInputContainer">
                      <PhoneInput
                      placeholder="Enter phone number"
                      onCountryChange={(e) => handleCountryChange(e) }
                      ref={MasjidContactRef}
                      style={{'width':'100%'}}
                      onChange={(e) => handlePhoneChange(e)
                      }/>
                    </div>

                  <TextField
                     id="outlined-required"
                     variant="outlined"
                     error={MasjidDescError}
                     helperText={MasjidDescError?"Manditory field":""}
                     fullWidth
                     multiline
                     rows={4}
                     inputRef={MasjidDescRef}
                     type="text"
                     autoComplete="Off"
                     sx={{  marginTop: 3,height:'50%' }}
                     placeholder="Enter the Description of the masjid"
                     /> 
                <div className="bottomWarning">
                    {/* <p className="bottomWarningContent"> Manditory Field </p> */}
                  </div>
                  </DialogContent>
             <DialogActions>
            <Button onClick={handleModalUpdateClose} style={{color:'grey'}} >Cancel</Button>
          <Button onClick = {handleNewMasjid}> Add  </Button>
          </DialogActions>
        </Dialog>
         <div className="AddMasjidContainer">
         { 
              (admin.role ==="superadmin" || admin.role === "dataentry") && (
              <Button  className="link" onClick={handleAddMasjidModal} variant="outlined" style={{ marginBottom:'10px',marginTop:'15px',color:'Green'}} >
                Add New Masjid <FcPlus className="PlusIcon"/>
            </Button>)
       }
         </div>

         <div style={{ height: 800, width: '100%',backgroundColor:'white' }}>
          <DataGrid
            rows={admin.role ==="superadmin" || admin.role === "dataentry" ? masjids : AdminMasjidData}
            columns={MasjidColumns}
            pageSize={12}
            getRowId={(row) => row._id}
            rowsPerPageOptions={[5]}
            disableSelectionOnClick
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </div>
        </div>
      )
}

export default MasjidTable
