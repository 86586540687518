import React,{useState,useEffect,useRef} from 'react'
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';
import AllAdminsTable from '../../../Components/PageComponents/AllAdminsTable/AllAdminsTable';
import {useSelector} from 'react-redux';
import './Announcement.css';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import { CircularProgress } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import {AnnouncementNotification } from '../../../Redux/Actions/NotificationActions/AnnouncementNotificationAction.js'
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, masjidName, theme) {
  return {
    fontWeight:
      masjidName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const Announcement = () => {

    const sidebarState = useSelector((state) => state.sidebarState);
    let AnnouncementTitleRef = useRef(null);
    let AnnouncementBodyRef = useRef(null);
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [masjidName, setMasjidName] = React.useState([]);
    const masjids  = useSelector((state) => state.masjids);
    const theme = useTheme();
    const [isTriggering, setisTriggering] = useState(false);
    const [SelectedAll, setSelectedAll] = useState(false);
    const [AnnouncementTitleError, setAnnouncementTitleError] = useState(false);
    const [AnnouncementBodyError, setAnnouncementBodyError] = useState(false);
    const [AnnouncementMasjidError, setAnnouncementMasjidError] = useState(false);
    const dispatch = useDispatch();

 

    const handleChange = (event) => {
      if(AnnouncementMasjidError){

        if(masjidName.length<=0){
          setAnnouncementMasjidError(true);
        }else if(masjidName.length>0){
          setAnnouncementMasjidError(false);
        }
      }
      setMasjidName(typeof value === 'string' ? event.target.value.split(',') : event.target.value);
    };

    const handleMasjidToggle= () => {
      if(SelectedAll){
        setSelectedAll(false)
      }else{
        setSelectedAll(true)
        setAnnouncementMasjidError(false);
      }
    }

    const handleTriggerNotification= () => {
       
      if(!AnnouncementTitleRef.current?.value){
        setAnnouncementTitleError(true);
      }else if(AnnouncementTitleRef.current?.value){
        setAnnouncementTitleError(false);
      }

      if(!AnnouncementBodyRef.current?.value){
        setAnnouncementBodyError(true);
      }else if(AnnouncementBodyRef.current?.value){
        setAnnouncementBodyError(false);
      }

      if(masjidName.length<=0){
        setAnnouncementMasjidError(true);
      }else if(masjidName.length>0){
        setAnnouncementMasjidError(false)
      }

      if(AnnouncementTitleRef.current.value && AnnouncementBodyRef.current?.value && masjidName.length>0){
        let Ids = [];

        masjidName.map((masjidNme) => {          
          let masjid = masjids.filter((item) => item.masjidName === masjidNme);
          Ids.push(masjid[0]._id)
        });

        let uploadData = {
          title:AnnouncementTitleRef.current.value,
          body:AnnouncementBodyRef.current.value,
          masjidIds:Ids
        }


        const data =   dispatch(AnnouncementNotification(uploadData));
        data.then(function(result) {
            if(result.success){
                const snackbarDetails = {
                  snackbarOpen:true, 
                    snackbarType:"success",
                   snackbarMessage:"Sent the Notifications to all the Masjid Users"
                 }   
                 dispatch(ChangeSnackbar(snackbarDetails)); 
               }
               else{
                const snackbarDetails = {
                  snackbarOpen:true, 
                    snackbarType:"warning",
                   snackbarMessage:"Failed to Send the Notification to the Application"
                 }   
                 dispatch(ChangeSnackbar(snackbarDetails)); 
               }
        })

      }

    }

    
    return (
        <>
        <div className="HomeContainer">
        {
          sidebarState ?(<div className="LeftContainer">
            <SideBar/>
          </div>):
           (<div className="LeftContainerClose">
           <SideBar/>
         </div>)
        } 
        <div className="RightContainer">
         <div className="RightTopContainer">
         <TopBar/>
         </div>
         <div className="RightFeedContainer">
           <div className="AnnouncementContainer">
              <div className="AnnouncementContainerBody">
                     <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        autoComplete="Off"
                        error={AnnouncementTitleError}
                        helperText={AnnouncementTitleError? "Manditory field":" "}
                        label="Announcement Title"
                        onChange ={ (e) => {
                          if(AnnouncementTitleError){
                            if(!AnnouncementTitleRef.current?.value){
                              setAnnouncementTitleError(true);
                            }else if(AnnouncementTitleRef.current?.value){
                              setAnnouncementTitleError(false);
                            }                  
                          }
                        }}
                        sx={{  marginBottom: 3 }}
                        inputRef={AnnouncementTitleRef}
                        type="text"
                        fullWidth
                        variant="outlined"
                      />
                    <TextField
                      id="outlined-required"
                      error={AnnouncementBodyError}
                      helperText={AnnouncementBodyError? "Manditory field":" "}
                      variant="outlined"
                      autoComplete="Off"
                      onChange ={ (e) => {
                        if(AnnouncementBodyError){
                          if(!AnnouncementBodyRef.current?.value){
                            setAnnouncementBodyError(true);
                          }else if(AnnouncementBodyRef.current?.value){
                            setAnnouncementBodyError(false);
                          }                  
                        }
                      }}
                      label="Announcement Body"
                      fullWidth
                      inputRef={AnnouncementBodyRef}
                      type="email"
                      sx={{  marginBottom: 3 }}
                      />
 
                     <span className="itemKey">Choose All Masjids
                      <Switch checked={SelectedAll} onChange={handleMasjidToggle} name="loading" color="primary" />
                      </span>
                  {
                   !SelectedAll && (<FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label">Masjid</InputLabel>
                    <Select
                      labelId="demo-multiple-chip-label"
                      id="demo-multiple-chip"
                      multiple
                      error={AnnouncementMasjidError}
                      helperText={AnnouncementMasjidError? "Manditory field":" "}
                      placeholder="Select the Masjids"
                      value={masjidName}
                      onChange={handleChange}
                      input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {masjids?.map((name) => (
                        <MenuItem
                          key={name._id}
                          value={name.masjidName}
                          style={getStyles(name, masjidName, theme)}
                          >
                          {name.masjidName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>)
                  }
               {AnnouncementMasjidError ? (<span style={{ color:'red'}}>Choose Masjids Please Before triggering</span>):(<> </>)}
                  <button className="ButtonToggle" onClick={handleTriggerNotification} >
                    <span class="text">
                    {isTriggering ? (
                    <CircularProgress  size="20px" />
                  ) : ("Triggering Announcement")
                  }
                    </span>
                  </button>

                 </div>
                 
           </div>
         </div>
        </div>
       </div>
   
       </>
    )
}

export default Announcement
