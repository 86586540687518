import { CHANGE_SIDEBAR } from '../../ActionType';
import * as api from '../../../APICalls/index.js';


export const ChangeSidebar = (sidebarState) => async(dispatch) => {


    try{

      dispatch({type:"CHANGE_SIDEBAR" , payload: sidebarState})
      
    }    
    catch(error)
   {
      return error.message;

   }
}