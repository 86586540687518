import React, {useState,useEffect} from 'react'
import './PictureCarouselComponent.css'
import BtnSlider from './BtnSlider'
import { GoTrashcan } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import {deleteEventMedia} from '../../../Redux/Actions/EventsActions/DeletingEventMedia.js';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const PictureCarouselComponent = ({eventPhotos,EventId,setUploadAllow}) => {
    const [EventPhotosState, setEventPhotosState] = useState(eventPhotos);
    const [slideIndex, setSlideIndex] = useState(1);
    const [DeleteModalOpen, setDeleteModalOpen] = React.useState(false);
    let imagesLength = eventPhotos ? eventPhotos?.length : 3;
    const dispatch = useDispatch();
   const {eventName } =useParams();
   const [isLoading, setisLoading] = useState(false)

    const nextSlide = () => {
        if(slideIndex !== eventPhotos.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === eventPhotos.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(eventPhotos.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)

    }

    let Photos = eventPhotos;

     useEffect(() => {
        setEventPhotosState(eventPhotos);
     }, [eventPhotos]);


     const handleDeleteModal = () => {

        setDeleteModalOpen(false);

     }

     const handleDeleteModalOpen = () => {

        setDeleteModalOpen(true);

     }


    const handleDeleteImage = (photo,index) => {

        setisLoading(true);
        let EventImage = EventPhotosState[slideIndex-1];

        let start = EventImage.indexOf("Event");
        let string = EventImage.substr(start);
        let end = string.indexOf(".jpg");
        let Id = EventImage.substr(start,end);


        const formData = {
            "imageId":Id,
            "url":EventImage
          }

         const response =  dispatch(deleteEventMedia(eventName,formData));
         response.then(function(result) {

           if(result.success){
            EventPhotosState.splice(slideIndex-1,1);
            setSlideIndex(slideIndex - 1);
            setisLoading(false);
            setDeleteModalOpen(false);

            }
            else{
                const snackbarFailureDetails = {
                    snackbarOpen:true, 
                    snackbarType:"error",
                    snackbarMessage:"Couldn't delete the event Image : " + result.message
                 }       
                 
                 dispatch(ChangeSnackbar(snackbarFailureDetails)); 
                 setisLoading(false);
                 setDeleteModalOpen(false);


            }
          })

       };

       if(EventPhotosState.length>=3){
           setUploadAllow(true);
        }else{
            setUploadAllow(false);
        }


  return (
    <div>
      <Dialog open={DeleteModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to delete the Event Image ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={handleDeleteImage} variant="outlined"  >  { isLoading ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                     <Button onClick={handleDeleteModal} variant="outlined"  disabled={isLoading}>No</Button>
              </DialogActions>
             </Dialog>
    <div className="container-slider2">
         
        {
            EventPhotosState.length>0? (EventPhotosState.map((item, index) => {
                return (
                    <div key={index} className={slideIndex === index + 1 ? "slide active-anim" : "slide"} >
                     <img src={item}   alt="The Event " className="eventImageCar" />
                     {
                       EventPhotosState.length>0 && (isLoading ?  <div className="delete-icon"><CircularProgress color="black" size="15px" className="loadingIcon" /></div> : <MdDelete className="delete-icon"  onClick={handleDeleteModalOpen} disabled={true} />)
                        }
                    </div>
                )
            })):(
                
                 <>
                  <img src="https://www.maxed.in/wp-content/themes/consultix/images/no-image-found-360x250.png"  alt="The Event " className="eventImage2" />
                 </>

)


}
    { EventPhotosState.length>1 && <BtnSlider moveSlide={nextSlide} direction={"next"} /> }
    { EventPhotosState.length>1 &&   <BtnSlider moveSlide={prevSlide} direction={"prev"}/>}
      
        <div className="container-dots">
            {
                EventPhotosState.length>1 &&  Array.from({length: imagesLength? imagesLength : 3}).map((item, index) => (
                    <div 
                    onClick={() => moveDot(index + 1)}
                    className={slideIndex === index + 1 ? "dot active" : "dot"}
                    ></div>
                    ))
                }
        </div>
    </div>
  </div>
  )
}

export default PictureCarouselComponent