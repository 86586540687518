import React,{useRef,useContext,useState} from 'react'
import './ForgotPassword.css';
import { useNavigate,useParams } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import {forgotPassword} from '../../../Redux/Actions/AuthActions/ForgotPasswordAction.js';
import { useDispatch } from 'react-redux';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';



const ForgotPassword = () => {

  const navigate = useNavigate();
  const email = useRef();
  const dispatch = useDispatch();
  const [isFetching, setisFetching] = useState(false);
  
   const handleSubmit = (e) => {
 
    setisFetching(true);
    e.preventDefault();
     
    const res = dispatch(forgotPassword({email:email.current.value},navigate));
    res.then((result) => {
      if(result.success){
      setisFetching(false);
    }else if(!result.success)
      {
        const snackbarDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:`Failed To Reset Password`
        }  
        dispatch(ChangeSnackbar(snackbarDetails));
        setisFetching(false);
     }
    })
   }


    return (
      <div className="ForgotPassword">
      <div className="loginWrapper">
       <div className="loginLeft">
           <h3 className="loginLogo">Masjid App Admin Portal</h3>
           <span className="loginDesc">
             The Admin Portal of Masjid App to work with  masjid's and Events.
           </span>
       </div>
       <div className="loginRight">
     <div className="loginBox">

       <form onSubmit={handleSubmit} className="ForgotPasswordBox">
       <input placeholder="Email" type="email" ref={email} required className="ForgotPasswordInput" />
       <button className="ForgotPasswordBtn" type="submit" disabled={isFetching}> 
           {isFetching ? (
             <CircularProgress color="white" size="20px" />
           ) : (
             "Forgot Password"
           )
           }
        </button>
       <span className="BackToLogin"> 
       <Link to="/login">
       Back to Login 
       </Link>
       </span>

       </form>
     </div>
   </div>
     </div>  
   </div>
    )
}

export default ForgotPassword