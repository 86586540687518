import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const deleteMasjidMedia = (id,formData) => async(dispatch) => {
    try{

     const { data } = await api.deleteMasjidMedia(id,formData);

     console.log(data);
     if(!data.success){
         return data;
      }

    return data;    
   }    
   catch(error)
   {
      console.log(error);
      const snackbarFailureDetails = {
         snackbarOpen:true, 
         snackbarType:"error",
         snackbarMessage:"Couldn't masjid the event Image : " + error.message
      }       
      
      dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      
      
      return error.response.data;
      
      
   }

}