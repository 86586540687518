import React from 'react';
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';
import AllAdminsTable from '../../../Components/PageComponents/AllAdminsTable/AllAdminsTable';
// import AllEventsTable from '../../../PageComponents/AllEventsTable/AllEventsTable';
import {useSelector} from 'react-redux';


const Admins = () => {

  const sidebarState = useSelector((state) => state.sidebarState);


  return (
    <>
     <div className="HomeContainer">
     {
       sidebarState ?
     
       (<div className="LeftContainer">
         <SideBar/>
       </div>):
        (<div className="LeftContainerClose">
        <SideBar/>
      </div>)

     } 
     <div className="RightContainer">
      <div className="RightTopContainer">
      <TopBar/>
      </div>
      <div className="RightFeedContainer">
      <AllAdminsTable/>
      </div>
     </div>
    </div>

    </>
  )
}

export default Admins