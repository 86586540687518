import { UPDATE_TIMING } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const UpdateTimingReducer =  (updatedTiming = [] , action) => {

   switch (action.type) {

    case UPDATE_TIMING:

      return action.payload;

    default:
      return updatedTiming
  }
};

export default UpdateTimingReducer;