import React from 'react'
import "./DataBox.css";



const DataBox = ({ data,name }) => {
    return (
        <>
          <div className="DataBoxContainer">
              <div className="NumberDetails">
              {data.length}
              </div>
             <div className="TitleDetails">
                 {name}
             </div>
          </div> 
        </>
    )
}

export default DataBox
