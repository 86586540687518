import { GET_TIMINGS_DATE } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchingTimingByDateReducer =  (dateTimings = [] , action) => {
  switch (action.type) {

    case GET_TIMINGS_DATE:

      return action.payload;

    default:
      return dateTimings;
  }
};

export default FetchingTimingByDateReducer;