import { UPDATE_TIMING } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const UpdateBulkTiming = (formData,id) => async(dispatch) => {

    try{

      const { data } = await api.updateBulkTiming(formData,id);
      
      if(data.success)
      {
         // dispatch({type:"UPDATE_TIMING" , payload: data.data})
         
         console.log(data);

         
         return data;
      }

      return data;
    }    
    catch(error)
   {
      if(error.message){
         const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:`Failed To Add Timings: ${error}`
          }  
          setTimeout(() => {
             
             dispatch(ChangeSnackbar(snackbarDetails));
          }, 1000);  
        }
        return error.response.data;

   }
}