import React from 'react';
import SideBar from '../../../Components/FeedComponents/SideBar/SideBar.jsx';
import TopBar from '../../../Components/FeedComponents/TopBar/TopBar.jsx';
import AllMasjidTable from '../../../Components/PageComponents/AllMasjidTable/AllMasjidTable';
import MasjidTable from '../../../Components/PageComponents/MasjidTable/MasjidTable';
// import AllMasjidTable from '../../../Components/PageComponents/AllMasjidTable/AllMasjidTable';
import {useSelector} from 'react-redux';

const Masjids = () => {

  const sidebarState = useSelector((state) => state.sidebarState);


  return (
    <>
     <div className="HomeContainer">
     {
       sidebarState ?
     
       (<div className="LeftContainer">
         <SideBar/>
       </div>):
        (<div className="LeftContainerClose">
        <SideBar/>
      </div>)

     }
     <div className="RightContainer">
      <div className="RightTopContainer">
      <TopBar/>
      </div>
      <div className="RightFeedContainer">
      {/* <AllMasjidTable/> */}
      <MasjidTable/>
      </div>
     </div>
    </div>

    </>
  )
}

export default Masjids