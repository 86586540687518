import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const deleteEventMedia = (id,formData) => async(dispatch) => {
    try{

      const { data } = await api.deleteEventMedia(id,formData);
 
     if(data.success)
     {
         const snackbarDetails = {
             snackbarOpen:true, 
             snackbarType:"success",
             snackbarMessage:"Deleted Event Image Successfully"

            }
            dispatch(ChangeSnackbar(snackbarDetails));
  
         return data;
      }

    return data;
    
   }    
   catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
         snackbarType:"error",
         snackbarMessage:"Couldn't delete the event Image : " + error.message
      }       
      
      dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      
      
      return error.response.data;
      
      
   }

}