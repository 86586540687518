import React,{useState,useRef,useEffect} from 'react';
import './TimingsTableContent.css';
import {MasjidTimes} from './DemoContent';
import { DatePicker,KeyboardDatePicker } from "@material-ui/pickers";
import Button from '@material-ui/core/Button';
import moment from "moment";
import { ImCircleRight } from "react-icons/im";
import { ImCircleLeft } from "react-icons/im";
import { useDispatch } from 'react-redux';
import { FetchingTimingsByDateRange } from '../../../../Redux/Actions/TimingActions/FetchingTimingsByDateRange.js';
import {ChangeSnackbar} from '../../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import { Badge } from "@material-ui/core";


const TimingsTableContent = ({ setManageTimings ,today, CurrentMonthTimings , TodaysTimings,Masjid}) => {
  
    const [MasjidTimings, setMasjidTimings] = useState(MasjidTimes)
    const [BulkEditState, setBulkEditState] = useState(false)
    const [valueDate, setValueDate] = useState(new Date());
    const [CurrentDayTimings, setCurrentDayTimings] = useState([TodaysTimings]);
    const [FirstMonthIncompleteDays, setFirstMonthIncompleteDays] = useState([])
    const [SecondMonthIncompleteDays, setSecondMonthIncompleteDays] = useState([])
    const [MonthTimings, setMonthTimings] = useState([CurrentMonthTimings]);
    const [TimeExists, setTimeExists] = useState(false)
    const [SelectedDays, setSelectedDays] = useState([])
    const [selectedMonth, setselectedMonth] = useState(today.getMonth()+1)
    const dispatch = useDispatch();
    let d1 = moment(today).format("DD/MM/yyyy")
    let d2 = moment(valueDate).format("DD/MM/yyyy")

    // console.log(CurrentMonthTimings);

    useEffect(() => {
    let days = [];
    let Day ;
    let CurrentMonthIncompleteDays = [];

    setMonthTimings(CurrentMonthTimings);

    CurrentMonthTimings.map((item) => {

      Day = new Date(item.date);
     if(Day.getMonth()+1 === selectedMonth){ 

      if(item.timings.length<5){
        CurrentMonthIncompleteDays.push(Day.getDate());
      }
        days.push(Day.getDate());
       }
     });

     if(CurrentMonthIncompleteDays.length>0){
      setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
     }

     setSelectedDays(days);
    }, [CurrentMonthTimings]);

   
    useEffect(() => {
      let currentDateFormat = (moment(valueDate).format('YYYY-MM-DD')) + "T00:00:00.000Z";
      setCurrentDayTimings(MonthTimings.filter((item) => item.date === currentDateFormat));

      if(CurrentDayTimings[0]?.length>0){        
        setTimeExists(true);
      }else{
        setTimeExists(false);
      }
     }, [valueDate])

     

    const handleBulkEditState = () => {
      setBulkEditState(!BulkEditState);
    }

    const handleMonthChange = (e) => {

      const startDate = moment(e).startOf('month').format('YYYY-MM-DD');
      const endDate   = moment(e).endOf('month').format('YYYY-MM-DD');
     
      setselectedMonth(e.getMonth()+1);

      if(Masjid?.masjidName)
      {
            const res = dispatch(FetchingTimingsByDateRange(startDate,endDate,Masjid._id));
  
            res.then((result) => {  

             if(result.success){
              setMonthTimings(result.data);

              let days = [];
              let Day ;
              let CurrentMonthIncompleteDays = [];
              
              result.data.map((item) => {
                Day = new Date(item.date);
              if(Day.getMonth()+1 === e.getMonth()+1){ 

                if(item.timings.length<5){
                  CurrentMonthIncompleteDays.push(Day.getDate());
                }
                  days.push(Day.getDate());
                }
              });

              if(CurrentMonthIncompleteDays.length>0){
                setFirstMonthIncompleteDays(CurrentMonthIncompleteDays)
               }

              setSelectedDays(days);
              }
  
             else{
              //  const snackbarFailureDetails = {
              //    snackbarOpen:true, 
              //     snackbarType:"warning",
              //      snackbarMessage:`No Timings Found For Current Month For the Masjid`
              //   }
   
              //   setTimeout(() => {
              //   dispatch(ChangeSnackbar(snackbarFailureDetails));
              //   }, 1000);

               SelectedDays.length>0 && setSelectedDays([]);  
              }
            })
          }
    }
    
    var options = { hour12: false,timeStyle:'short' };
  
  return (
  <>
     <div className="TableHeaderComponent">
       <div className="TableTopComponent">
        <h3 className="TableTitle">Prayer Timings</h3>
         <div className="CalendarHeadContainer">
          <div  className="CalendarContainer">
           <KeyboardDatePicker
              autoOk
              fullWidth
              variant="inline"
              format="MM/dd/yyyy"
              allowKeyboardControl={true}
              value={valueDate}
              inputVariant="outlined"
              onMonthChange={(item) => {
                handleMonthChange(item);
              }}
              onChange={(newValue,isInCurrentMonth) => {

                setValueDate(newValue);
               }}

               renderDay={(day, selectedDate, isInCurrentMonth, dayComponent,days) => {
               
                const isSelected =  isInCurrentMonth && SelectedDays?.includes(day.getDate());
                const isIncompleteSelected =  isInCurrentMonth && FirstMonthIncompleteDays?.includes(day.getDate());
                
                // const isIncomplete =  isInCurrentMonth && SelectedIncompleteDays?.includes(day.getDate());
                 
                // ✔
              //   return <Badge key={day.toString()} overlap="'circular'" badgeContent={isSelected ?  ( isIncomplete ? <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "orange"}} /> : <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "green"}} />) : " " }>
              //     {dayComponent}
              //     </Badge>;
              // }}
              return <Badge  key={day.toString()} overlap="'circular'"  badgeContent={isSelected ?( isIncompleteSelected ? <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "orange"}} /> : <div style={{ height: "5px",width: "5px",borderRadius: "100%", background: "green"}} />) : undefined}>{dayComponent}</Badge>;
               }}
              sx={{width: '50%'}} 
              />
          </div>
           <div className="ManageButtonHeadContainer">
           <button className="button-3" onClick={(e) => { e.preventDefault(); setManageTimings(true)}} >Manage Timings</button>
           </div>
         </div>
       </div>
       <table className="table" >
          <thead>
            <tr className="tableHead">
              <th className="tableHeaderPrayerName"><span className="tableHeaderContent">Prayer Name</span></th>
              <th className="tableHeaderAzaan"><span className="tableHeaderContent">Azaan Time</span></th>
              <th  className="tableHeaderJamaat"><span className="tableHeaderContent">Jamaat Time</span></th>
            </tr>
          </thead>

          <tbody  className="tableBody">
          { 
            CurrentDayTimings.length>0 ?  (CurrentDayTimings[0]?.timings?.map((item) => {
   
              
              let azaan = new Date(item.azaanTime*1000);

              let jamaat = new Date(item.jamaatTime*1000);


           return ( 
               <>
                <tr>
                  <td className="tableRow" ><span className="tableHeaderContentBodyName">{item.namazName}</span></td>
                  <td className="tableRow" ><span className="tableHeaderContentBody">{item.azaanTime && azaan.toLocaleString([], {timeStyle: 'short'}) }</span></td>
                  <td className="tableRow"><span className="tableHeaderContentBody">{item.jamaatTime && jamaat.toLocaleString([], {timeStyle: 'short'}) }</span></td>
                 </tr>
               </>
              )
             })
            ):(
               (d1 === d2  && TodaysTimings[0]?.timings.length>0 )  ? (

                (TodaysTimings[0]?.timings.map((item) => {
   
              
                  let azaan = new Date(item.azaanTime*1000);
    
                  let jamaat = new Date(item.jamaatTime*1000);
    
    
               return ( 
                   <>
                    <tr>
                      <td className="tableRow" ><span className="tableHeaderContentBodyName">{item.namazName}</span></td>
                      <td className="tableRow" ><span className="tableHeaderContentBody">{item.azaanTime && azaan.toLocaleString([], {timeStyle: 'short'}) }</span></td>
                      <td className="tableRow"><span className="tableHeaderContentBody">{item.jamaatTime && jamaat.toLocaleString([], {timeStyle: 'short'}) }</span></td>
                     </tr>
                   </>
                  )
                 }))
              ):(
                <> 
                <div className="NoTimingsAvailable">
                  No Timings Data Found
                </div>
                </>
              )
            )
           }
          </tbody>

        </table>
     </div>
  </>
  )
}

export default TimingsTableContent