import { FETCH_MASJID_BY_NAME } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchMasjidByNameReducer =  (masjid = [] , action) => {
  switch (action.type) {

    case FETCH_MASJID_BY_NAME:

      return action.payload;

    default:
      return masjid;
  }
};

export default FetchMasjidByNameReducer;