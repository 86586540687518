import { FETCH_MASJID_EVENTS } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchMasjidEventReducer =  (masjidEvents = { masjidEventData:null } , action) => {

   switch (action.type) {

    case FETCH_MASJID_EVENTS:

      return {...masjidEvents,masjidEventData:action?.payload};

    default:
      return masjidEvents;
  }
};

export default FetchMasjidEventReducer;