import { FETCH_STALE_MASJIDS } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchStaleMasjidReducer =  (staleMasjids = [] , action) => {
  switch (action.type) {

    case FETCH_STALE_MASJIDS:

      return action.payload;

    default:
      return staleMasjids;
  }
};

export default FetchStaleMasjidReducer;