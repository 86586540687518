import { ADD_MASJID } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const addEventMedia = (id,formData) => async(dispatch) => {


    try{
      const  { data }  = await api.addEventMedia(id,formData);
     
      console.log(formData);

      
      if(data.success)
      {
        const snackbarDetails = {
          snackbarOpen:true, 
          snackbarType:"success",
          snackbarMessage:"Added Image Successfully"

         }
         dispatch(ChangeSnackbar(snackbarDetails));

         return data;
      }
      return data;

   }    
    catch(error)
   {
    const snackbarFailureDetails = {
      snackbarOpen:true, 
       snackbarType:"error",
       snackbarMessage:error.response.data ?error.response.data.message:"Adding Masjid Media Failed",
     }

    dispatch(ChangeSnackbar(snackbarFailureDetails)); 

     return error.response.data;
   }
}