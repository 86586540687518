import React,{useEffect,useState} from 'react'
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { Link } from "react-router-dom";
import {useSelector} from 'react-redux';
import { getFreezedMasjids } from '../../../Redux/Actions/MasjidActions/FetchingFreedMasjid.js';
import { useDispatch } from 'react-redux';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import  './FreezeMasjidTable.scss';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from "@material-ui/core";
import { UnFreezingMasjidById } from '../../../Redux/Actions/MasjidActions/UnfreezingMasjid.js';
import { useNavigate } from "react-router-dom";
import {getMasjids} from '../../../Redux/Actions/MasjidActions/FetchingMasjids.js'


const today = moment();
  
 
const FreezeMasjidTable = ({masjids}) => {
  const currentAdmin  = useSelector((state) => state.currentAdmin);
  const dispatch = useDispatch();
  const [FreezedMasjids, setFreezedMasjids] = useState([]);
  const [FreezedMasjid, setFreezedMasjid] = useState([])
  const [isUnFreezing, setisUnFreezing] = useState(false)
  const [FreezeModalOpen, setFreezeModalOpen] = useState(false);
  const [UnfreezingMasjid, setUnfreezingMasjid] = useState([]);
  let navigate = useNavigate();



  const handleModelOpen = (adminDetails) => {
    setFreezeModalOpen(true);
    setUnfreezingMasjid(adminDetails.row)
  }

const userColumns = [
  {
    field: "masjidName",
    title: "Masjid Name",
    autoComplete: 'off',
    width: 280,
  },
  {
    field: "updatedAt",
    title: "Last Updated On",
    autoComplete: 'off',
    width: 220,
    renderCell: (params) => {
      return <div className="dataUpdatedAt">{moment(params.row.updatedAt).format("MM/DD/YYYY hh:mm a")}</div>;
    },
  },
  {
    field: "Days Before",
    title: "Days since last update",
    autoComplete: 'off',
    width: 100,
    renderCell: (params) => {
      return <div>{ moment(today.format()).diff((moment(params.row.updatedAt)),'days')}</div>;
    },
  },
  {
    field: "lastEditor",
    title: "Last Updated By",
    width: 200,  
    editable:"never",
  },
  {
    field: "contact",
    title: "Contact",
    autoComplete: 'off',
    width: 170,
  },
  {
    field: "address",
    title: "Address",
    width: 240,
    autoComplete: 'off',
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    renderCell: (params) => {
      return <Button onClick={(e) => {
                e.stopPropagation();
                handleModelOpen(params);
              }} 
               style={{ border:"1px solid green",color:"green",height:"20px",fontSize:"10px",cursor:"Pointer"}}>UnFreeze</Button>;
    }
  }
]




  useEffect(() => {
    const response =  dispatch(getFreezedMasjids());
    response.then(function(result) {
    if(!result.success){
        const snackbarFailureDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:result.message ? "Failed to Freeze Masjid Details : " + result.message : "Failed to Freeze Masjid Details  : Internet or Server Issue "
        }
        dispatch(ChangeSnackbar(snackbarFailureDetails)); 
      }
      else{
        setFreezedMasjids(result.masjids)        
       }
     });
    }, []);



    const getFreezed = () => {
      const response =  dispatch(getFreezedMasjids());
      response.then(function(result) {
      if(!result.success){
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:result.message ? "Failed to Freeze Masjid Details : " + result.message : "Failed to Freeze Masjid Details  : Internet or Server Issue "
          }
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
        }
        else{
          setFreezedMasjids(result.data)        
         }
       });
    }

    const handleUnFreezeModal= () => {
      setFreezeModalOpen(false);
     }
  
     const handleUnFreezeAdmin = () => {
      setisUnFreezing(true);
      const response =  dispatch(UnFreezingMasjidById(UnfreezingMasjid?._id));
      response.then(function(result) {
      if(!result.success){
          const snackbarFailureDetails = {
            snackbarOpen:true, 
            snackbarType:"error",
            snackbarMessage:result.message ? "Failed to  UnFreezed Masjid Details : " + result.message : "Failed to UnFreezed Masjid Details : Internet or Server Issue "
          }
          dispatch(ChangeSnackbar(snackbarFailureDetails)); 
          setisUnFreezing(false);
        }
        else{
          setFreezeModalOpen(false);
          setisUnFreezing(false);
          setFreezedMasjids( FreezedMasjids.filter((masjid) => masjid._id !== UnfreezingMasjid?._id));

         }
       });
  
     }
   
     console.log(FreezedMasjids);

  return (
    <div className="FreezedMasjidTableContainer">
      <span className="FreezeTableTitle">Freezed Masjids</span>
      <Dialog open={FreezeModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to Unfreeze the Masjid ?`}
            </DialogTitle>
              <DialogActions>
                 <Button onClick={handleUnFreezeAdmin} variant="outlined"  >  { isUnFreezing ? <CircularProgress color="black" size="15px"  /> : <span> Yes </span> } </Button>
                 <Button onClick={handleUnFreezeModal} variant="outlined"  disabled={isUnFreezing}>No</Button>
              </DialogActions>
          </Dialog>
     <div style={{ height: 700, width: '100%',marginTop:'1.2rem'}}>
      <DataGrid
        rows={FreezedMasjids?.length>0 ? FreezedMasjids:[]}
        columns={userColumns}
        pageSize={10}
        getRowId={(row) => row._id}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick 
      />
    </div>
    </div>
  )
}

export default FreezeMasjidTable