import { FETCH_ADMIN_MASJIDS } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchMasjidsByAdminReducer =  (AdminMasjids = [] , action) => {

  switch (action.type) {

    case FETCH_ADMIN_MASJIDS:
      return action.payload;

    default:
      return AdminMasjids;
  }
};

export default FetchMasjidsByAdminReducer;