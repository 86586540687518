import { DELETE_MASJID } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const deleteAllJummah = (formData) => async(dispatch) => {

    try{

      const data =await api.deleteAllJummah(formData);
      
      if(data.data.length>0)
    { 
      
       return data.data;
     }
     
      return data.data;
      
    }    
    catch(error)
   {
      if(error.message){
         const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:`Failed To Delete Timings: ${error.message}`
          }  
          setTimeout(() => {
             
             dispatch(ChangeSnackbar(snackbarDetails));
          }, 1000);  
        }

      return error.response.data;

   }
}