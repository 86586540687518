import * as api from '../../../APICalls/index.js';
import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const resetPassword = (formData) => async(dispatch) => {

    try{  

      const { data} = await api.ResetPassword(formData);
      
      if(data){
      
        const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"success",
             snackbarMessage:`The Email Has been Sent to the Registered Mail , Please Check Your Inbox`
          }  

        dispatch(ChangeSnackbar(snackbarDetails));

        return data;

      }

      return data;
    }    
    catch(error)
   {
      
      if(error.message){
         const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:`Failed To Add Timings: ${error}`
          }  

             dispatch(ChangeSnackbar(snackbarDetails));
      }
      return error.response.data;

   }
}