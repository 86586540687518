import { GET_TIMINGS_DATE } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const FetchingTimingsByDateRange = (startDate,endDate,masjidId) => async(dispatch) => {

    try{

      let { data } = await api.getTimingByDateRange(startDate,endDate,masjidId);
  
    //   console.log(data);

     if(data.data.length>0)
     {
        dispatch({type:"GET_TIMINGS_DATE" , payload: data.data});

   //   const snackbarDetails = {
   //       snackbarOpen:true, 
   //        snackbarType:"success",
   //        snackbarMessage:`Masjid Timings for ${date} Found`
   //     }  
   //     setTimeout(() => {
          
   //        dispatch(ChangeSnackbar(snackbarDetails));
   //     }, 1000);  
       
       
        return data;
      }
      
      return data = [];

    }    
    catch(error)
   {
        const snackbarFailureDetails = {
              snackbarOpen:true, 
               snackbarType:"error",
              snackbarMessage: error.message ? "Failed to Fetchig Masjid Timings : " + error.message : "Failed to Load Masjid Details : Internet or Server Issue "
             }

             setTimeout(() => {
             dispatch(ChangeSnackbar(snackbarFailureDetails)); 
            }, 1000);  
       
      return error.response.data;

   }
}