import React,{useRef,useState} from 'react'
import { Link } from "react-router-dom";
import './Login.css';
import {authLogin} from '../../../Redux/Actions/AuthActions/LoginAction.js';
import {VerifyingTwoFactorAuth} from '../../../Redux/Actions/AuthActions/VerifyTwoFactorAuth.js';
import { useDispatch } from 'react-redux';
import {useSelector} from 'react-redux';
import  {useNavigate} from 'react-router-dom';
import { CircularProgress } from "@material-ui/core";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';



const Login = () => {
  const email = useRef();
  const password = useRef();
  const token = useRef();
  const dispatch = useDispatch();
  const admin  = useSelector((state) => state.admin);
  let navigate = useNavigate();
  const [isFetching, setisFetching] = useState(false);
  const [adminId, setadminId] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [PasswordShow, setPasswordShow] = useState(true);
  const [OpenModal, setOpenModal] = useState(false);
  const [OpenQRModal, setOpenQRModal] = useState(false);
  const [QRCode,setQRCode] = useState('');

 
  const handleModalUpdate = () => {
    setOpenModal(false);
    setisFetching(false);
  }


   const handleSubmit = (e) => {

    setisFetching(true);
    e.preventDefault();
    
    if(email.current?.value && password.current?.value){
      const res = dispatch(authLogin({email:email.current.value,password:password.current.value},navigate));
      res.then((result) => {

        console.log(result);
        
        if(result.success){
          
          if(result.TwoFAUser){

            setadminId(result.adminId);
            setOpenModal(true);
            
          }else{
            setadminId(result.adminId);
            const snackbarDetails = {
              snackbarOpen:true, 
              snackbarType:"success",
              snackbarMessage:`Logged In Successfully`
            }  
            dispatch(ChangeSnackbar(snackbarDetails));  
          }
          setisFetching(false);

         
    }
      else
      {
        const snackbarDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:`Failed To LogIn `
        }  
        dispatch(ChangeSnackbar(snackbarDetails));
        setisFetching(false);
      }
     }) 
    }
    else{
      const snackbarDetails = {
        snackbarOpen:true, 
        snackbarType:"error",
        snackbarMessage:`Please Provide the Credentials to login`
      }  
      dispatch(ChangeSnackbar(snackbarDetails));
   }
  }
  
   const handleAdmin = (e) => {
    setisFetching(true);

    e.preventDefault();
    let formData = {
      token:token.current.value,
      password:password.current.value,
      userId:adminId
    }

    const res = dispatch(VerifyingTwoFactorAuth(formData));
    res.then((result) => {
      if(result?.success){
        if(result.TwoFAUser){
          setOpenModal(true);
        }else{
          const snackbarDetails = {
            snackbarOpen:true, 
            snackbarType:"success",
            snackbarMessage:`Logged In`
          }  
          dispatch(ChangeSnackbar(snackbarDetails));  
          setisFetching(false);
          setOpenModal(false);
        }
    }
      else
      {
        const snackbarDetails = {
          snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:`Failed To LogIn :Invalid token`
        }  
        dispatch(ChangeSnackbar(snackbarDetails));
        setisFetching(false);
     }
    })
   }

   
   const togglePassword =()=>{
    if(passwordType==="password")
    {
    setPasswordType("text")
    setPasswordShow(true); 
    return;
    }
    setPasswordType("password");
    setPasswordShow(false); 
  }

  return (
        <div className="login">
          <div className="loginWrapper">
            <div className="loginLeft">
                <h3 className="loginLogo">Masjid App Admin Portal</h3>
                <span className="loginDesc">
                  The Admin Portal of Masjid App to work with  masjid's and Events.
                </span>
            </div>
            <div className="loginRight">
              <div className="QrCode">
              </div>
          <div className="loginBox">
                    <Dialog open={OpenModal} height={"xl"} >
                        <DialogTitle> Enter the TOTP from your Google Auth App</DialogTitle>
                            <DialogContent>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="OTP"
                                sx={{ marginLeft:10,marginTop:2 }}
                                type="number"
                                inputRef={token}
                                variant="outlined" />
                              </DialogContent>
                            <DialogActions>
                          <Button onClick={handleModalUpdate} style={{color:'grey'}}>Cancel</Button>
                            <Button onClick = {handleAdmin}> Verify  </Button>
                          </DialogActions>
                        </Dialog>
            <form onSubmit={handleSubmit} className="loginBox">
            <input placeholder="Email" type="email" ref={email} required className="loginInput" />
            <div className='InputFields'>
                <input placeholder="Password"  type={passwordType}  ref={password} required className="ResetPasswordInput" />
                  {PasswordShow ? <AiFillEye onClick={togglePassword} className="ShowPasswordLogin"/> : <AiFillEyeInvisible onClick={togglePassword} className="ShowPasswordLogin"/> }
                </div>
            <button className="loginButton" type="submit" disabled={isFetching}> 
              {isFetching ? (
                <CircularProgress  size="20px" />
              ) : (
                "Log In"
              )
              }
            </button>
            <span className="loginForgot">
                <Link to="/forgotpassword">
                  Forgot Password?
              </Link>
              </span>
            </form>
            </div>
            </div>
          </div>  
        </div>
  )
}

export default Login