import React, {useState,useEffect} from 'react'
import './MasjidPictureCarouselComponent.css'
import BtnSlider from './BtnSlider'
import { GoTrashcan } from "react-icons/go";
import { MdDelete } from "react-icons/md";
import {deleteEventMedia} from '../../../Redux/Actions/EventsActions/DeletingEventMedia.js';
import {deleteMasjidMedia} from '../../../Redux/Actions/MasjidActions/DeletingMasjidMedia.js';
import { useDispatch } from 'react-redux';
import { useParams } from "react-router-dom";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { CircularProgress } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';



const MasjidPictureCarouselComponent = ({MasjidPhotos,MasjidId,setUploadAllow}) => {

    const [EventPhotosState, setEventPhotosState] = useState(MasjidPhotos);
    const [slideIndex, setSlideIndex] = useState(1)
    let imagesLength = MasjidPhotos? MasjidPhotos?.length : 3;
    const [isLoading, setisLoading] = useState(false)
    const [DeleteModalOpen, setDeleteModalOpen] = React.useState(false);

    const dispatch = useDispatch();
   const {eventName } =useParams();

    const nextSlide = () => {
        if(slideIndex !== MasjidPhotos?.length){
            setSlideIndex(slideIndex + 1)
        } 
        else if (slideIndex === MasjidPhotos?.length){
            setSlideIndex(1)
        }
    }

    const prevSlide = () => {
        if(slideIndex !== 1){
            setSlideIndex(slideIndex - 1)
        }
        else if (slideIndex === 1){
            setSlideIndex(MasjidPhotos?.length)
        }
    }

    const moveDot = index => {
        setSlideIndex(index)
    }

    let Photos = MasjidPhotos;

     useEffect(() => {
       
        setEventPhotosState( MasjidPhotos);

     }, [MasjidPhotos]);


     const handleDeleteModal = () => {

        setDeleteModalOpen(false);

     }

     const handleDeleteModalOpen = () => {

        setDeleteModalOpen(true);

     }


    const handleDeleteImage = (photo,index) => {
   
        setisLoading(true);
        let MasjidImage = EventPhotosState[slideIndex-1];

        let start = MasjidImage.indexOf("Masjid");
        let string = MasjidImage.substr(start);
        let end = string.indexOf(".jpg");
        let Id = MasjidImage.substr(start,end);

        const formData = {
            "imageId":Id,
            "url":MasjidImage
          }

        //   console.log(formData);

         const response =  dispatch(deleteMasjidMedia(MasjidId,formData));
         response.then(function(result) {

            EventPhotosState.splice(slideIndex-1,1);
            setSlideIndex(slideIndex - 1);
            setisLoading(false);
            setDeleteModalOpen(false);

        //    if(result.success){
           

        //     }
          })
       };


       if(EventPhotosState.length>=3){
        setUploadAllow(true);
     }else{
         setUploadAllow(false);
     }



  return (
    
    <div className="container-slider-masjid">
           <Dialog open={DeleteModalOpen}  aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                { `Are you sure you want to delete the Masjid Image ?`}
            </DialogTitle>
              <DialogActions>
              <Button onClick={handleDeleteImage} variant="outlined"  >  { isLoading ? <CircularProgress  size="15px"  /> : <span> Yes </span> } </Button>
                     <Button onClick={handleDeleteModal} variant="outlined"  disabled={isLoading}>No</Button>
              </DialogActions>
             </Dialog>
        {
            EventPhotosState.length>0 ? (EventPhotosState.map((item, index) => {
                return (
                    <div key={index} className={slideIndex === index + 1 ? "slide active-anim" : "slide"} >
                     <img src={item}   alt="The Event " className="eventImage2" />
                       {EventPhotosState.length>0 &&  (isLoading ?  <div className="delete-icon"><CircularProgress  size="15px" className="loadingIcon" /></div> : <MdDelete className="delete-icon"  onClick={handleDeleteModalOpen} disabled={true} />)}
                    </div>
                )
            })):(
                
                 <>
                  <img src="https://www.maxed.in/wp-content/themes/consultix/images/no-image-found-360x250.png"  alt="The Event " className="eventImage2" />
                 </>

            )


        }
    { EventPhotosState.length>1 && <BtnSlider moveSlide={nextSlide} direction={"next"} /> }
    { EventPhotosState.length>1 &&   <BtnSlider moveSlide={prevSlide} direction={"prev"}/>}

        <div className="container-dots">
            {
            EventPhotosState.length>1 &&  Array.from({length: imagesLength? imagesLength : 3}).map((item, index) => (
                <div 
                onClick={() => moveDot(index + 1)}
                
                className={slideIndex === index + 1 ? "dot active" : "dot"}
                ></div>
            ))
            }
        </div>
    </div>
  )
}

export default MasjidPictureCarouselComponent