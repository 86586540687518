import { AUTH_LOGIN } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const VerifyingTwoFactorAuth = (formData) => async(dispatch) => {

    try{

  
      const { data } = await api.VerifyingTwoFactorAuth(formData);

      console.log(data.data);

      if(data.success){

         localStorage.setItem("authTokens", JSON.stringify(data.authTokens));
         
         dispatch({type:"AUTH_LOGIN" , payload:data.data});

         // window.location.reload();

         return data;
         
      }
      return data;

    }    
    catch(error)
   {
    
      if(error.message){
         const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:`Failed To Log in: ${error.message}`
          }  
          setTimeout(() => {
             
             dispatch(ChangeSnackbar(snackbarDetails));
          }, 1000);  
        }

      return error.response
   }
}