import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js'


export const FetchEventById = (eventId) => async(dispatch) => {


    try{
    
      const { data } = await api.fetchEventId(eventId);

      
      if(data.success)
      {
        // const snackbarDetails = {
        //     snackbarOpen:true, 
        //      snackbarType:"success",
        //      snackbarMessage:data.message
        //    }
      
        //   dispatch(ChangeSnackbar(snackbarDetails)); 

         return data;
       }
     return data;
    }    
    catch(error)
   {
      const snackbarFailureDetails = {
         snackbarOpen:true, 
          snackbarType:"error",
          snackbarMessage:error.response?.data ?error.response?.data.message:"Fetching Event Failed",
        }
   
       dispatch(ChangeSnackbar(snackbarFailureDetails)); 

      return error.response;

   }
}