import { UPDATE_MASJID } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const UpdateMasjidReducer =  (updatedMasjid = [] , action) => {

   switch (action.type) {

    case UPDATE_MASJID:

      return action.payload;;

    default:
      return updatedMasjid;
  }
};

export default UpdateMasjidReducer;