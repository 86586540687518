export const FETCH_MASJIDS = 'FETCH_MASJIDS';
export const FETCH_STALE_MASJIDS = 'FETCH_STALE_MASJIDS';
export const FETCH_EVENTS = 'FETCH_EVENTS';
export const FETCH_MASJID_EVENTS='FETCH_MASJID_EVENTS';
export const FETCH_MASJID_BY_NAME='FETCH_MASJID_BY_NAME';
export const FETCH_ADMIN_MASJIDS='FETCH_ADMIN_MASJIDS';
export const FETCH_ADMIN_BY_NAME='FETCH_ADMIN_BY_NAME';


export const ADD_MASJID='ADD_MASJID';
export const DELETE_MASJID='DELETE_MASJID';
export const UPDATE_MASJID='UPDATE_MASJID';

export const ADD_EVENT='ADD_EVENT';
export const DELETE_EVENT='DELETE_EVENT';
export const UPDATE_EVENT='UPDATE_EVENT';
export const FETCH_LATEST_UPDATED_EVENTS='FETCH_LATEST_UPDATED_EVENTS';
export const FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN='FETCH_LATEST_UPDATED_EVENTS_BY_ADMIN';



export const AUTH_LOGIN='AUTH_LOGIN';
export const AUTH_LOGOUT='AUTH_LOGOUT';
export const AUTH_REGISTER='AUTH_REGISTER';
export const GET_ALL_ADMIN='GET_ALL_ADMIN';


export const CHANGE_SIDEBAR='CHANGE_SIDEBAR';
export const CHANGE_SNACKBAR='CHANGE_SNACKBAR';



export const GET_TIMINGS_MASJID_ID='GET_TIMINGS_MASJID_ID';
export const GET_TIMINGS_ID='GET_TIMINGS_ID';
export const GET_TIMINGS_DATE ='GET_TIMINGS_DATE';
export const  ADD_TIMING='ADD_TIMING';
export const  UPDATE_TIMING='UPDATE_TIMING';
export const  UPDATE_NAMAAZ='UPDATE_NAMAAZ';
export const  DELETE_TIMING='DELETE_TIMING';
export const  DELETE_NAMAAZ='DELETE_NAMAAZ';
