import { ADD_MASJID } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const AddMasjidReducer =  (newMasjid = [] , action) => {

   switch (action.type) {

    case ADD_MASJID:

      return action.payload;

    default:
      return newMasjid;
  }
};

export default AddMasjidReducer;