import * as api from '../../../APICalls/index.js';
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const MasjidUpdateNotification = (formData,id) => async(dispatch) => {

    try{
      const { data } = await api.updateNotification(formData,"masjid",id);
     
      console.log(data);
      if(data.success)
      {
        const snackbarDetails = {
            snackbarOpen:true, 
              snackbarType:"success",
             snackbarMessage:"Sent the Notifications to all the Masjid Users"
           }   
           dispatch(ChangeSnackbar(snackbarDetails)); 
        return data;
      }
      return data;
    }    
    catch(error)
     {
        const snackbarDetails = {
            snackbarOpen:true, 
              snackbarType:"error",
             snackbarMessage:"Failed to Send the Notification to the Users"
           }   
           dispatch(ChangeSnackbar(snackbarDetails)); 

      return error;
   }
}