import { AUTH_LOGIN } from '../../ActionType';
import * as api from '../../../APICalls/index.js';
import {Redirect }from  'react-router-dom';
import jwt_decode from "jwt-decode";
import {ChangeSnackbar} from '../../../Redux/Actions/SnackbarActions/SnackbarActions.js';


export const ActivatingTwoFactorAuth = (formData,id) => async(dispatch) => {

    try{

  
      const { data } = await api.ActivatingTwoFactorAuth(formData,id);

      if(data.success){
  
         localStorage.setItem("admin", JSON.stringify(data.data));
         
         dispatch({type:"AUTH_LOGIN" , payload:data.data});

         return data;
      }
      return data;
    }    
    catch(error)
   {
    
      
      if(error.message){
         const snackbarDetails = {
            snackbarOpen:true, 
             snackbarType:"error",
             snackbarMessage:`Failed To Log in: ${error}`
          }  
          setTimeout(() => {
             
             dispatch(ChangeSnackbar(snackbarDetails));
          }, 1000);  
        }

      return error.response.data;

   }
}