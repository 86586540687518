import React from 'react'
import './SideBar.css';
import { FaMosque} from "react-icons/fa";
import { BsFillBellFill } from "react-icons/bs";
import { MdGroups } from "react-icons/md";
import { Link} from 'react-router-dom';
import {authLogout} from '../../../Redux/Actions/AuthActions/LogoutAction.js';
import { AiOutlineLogout } from "react-icons/ai";
import { BsFillPersonFill } from "react-icons/bs";
import {useSelector} from 'react-redux';
import { CgFormatLineHeight } from "react-icons/cg";
import { MdOutlineFontDownloadOff } from "react-icons/md";
import { CgPlayListRemove } from "react-icons/cg";
import { GrAnnounce } from "react-icons/gr";


const SideBar = ({sidebarOpen}) => {
  
  const sidebarState = useSelector((state) => state.sidebarState);

  const handleLogout = () => {

    authLogout();
    localStorage.clear();
    window.location.reload();
  }
  const admin = JSON.parse(localStorage.getItem("admin"));


  return (
   <>
     <div className="SideBarContainer">
       <div className="SideBarTop"> 
     <Link to="/" className="LinkMasjid">
         Logo
      </Link>
       </div>

       <div className="SideBarBottom">
         {
           sidebarState ? (
             <>
           <ul className="SideBarMenu">
           <li className="SideBarItem">
           <FaMosque  className="MosqueIcon2"/>
           <Link to="/masjids" className="LinkMasjid" >
              Masjids
            </Link>
             </li>

             { 
             admin.role ==="superadmin" || admin.role === "dataentry" ?(
             
             <>
             <li className="SideBarItem">
           <BsFillPersonFill  className="MosqueIcon"/>
           <Link to="/admins" className="LinkMasjid" style={{textDecoration:"none"}} >
              Admins
             </Link>
             </li>

              <li className="SideBarItem">
              <CgPlayListRemove  className="StaleMosqueIcon2"/>
              <Link to="/freezeMasjids" className="sidebarItemFreezed" >
                Freezed Masjids 
              </Link>
             </li>

             <li className="SideBarItem">
              <MdOutlineFontDownloadOff  className="StaleMosqueIcon"/>
              <Link to="/freezeAdmins" className="sidebarItemFreezed" >
                Freezed Admin 
              </Link>
             </li>
            </>
            ):(
              <>
              </>
            )
          }

             <li className="SideBarItem">
              <GrAnnounce  className="StaleMosqueIcon"/>
              <Link to="/announcements" className="AnnouncementFreezed" >
                Announcement
              </Link>
             </li> 
           
           <li className="SideBarItem" onClick={handleLogout}>
             <AiOutlineLogout  className="MosqueIcon"/>
              Logout
             </li>      
         </ul>
         </>
             ):(
              <>
                 <ul className="SideBarMenuClose">
                       <li className="SideBarItemClose">
                       <Link to="/masjids" className="LinkMasjidClose" >
                         <FaMosque  className="MosqueIcon2"/>
                        </Link>
                       </li>
                       
                  { 
                    admin.role ==="superadmin" || admin.role === "dataentry" ?(
                       <>
                       <li  className="SideBarItemClose">
                       <Link to="/admins" className="LinkMasjidClose" style={{textDecoration:"none"}} >
                       <BsFillPersonFill  className="MosqueIcon" style={{textDecoration:"none"}} />
                        </Link>
                       </li>
                         <li className="SideBarItemClose">
                         <Link to="/freezeMasjids" className="LinkMasjidClose" >
                           <CgPlayListRemove  className="StaleMosqueIcon3"/>
                          </Link>
                         </li>
                         <li className="SideBarItemClose">
                         <Link to="/freezeAdmins" className="LinkMasjidClose" >
                           <MdOutlineFontDownloadOff  className="MosqueIcon2"/>
                          </Link>
                         </li>
                        </>
                        ):(
                          <>
                          </>
                        )
                      }
                     
                       <li className="SideBarItemClose" >
                       <Link to="/announcements" className="LinkMasjidClose" >
                         <GrAnnounce  className="MosqueIcon"/>
                        </Link>
                        </li>    
                     
                       <li className="SideBarItemClose" onClick={handleLogout}>
                        <AiOutlineLogout  className="MosqueIcon"/>
                        </li>    
                  </ul>
              </>
             )
          }
       </div>
     </div>
   </>
  )
}

export default SideBar