import { GET_TIMINGS_MASJID_ID } from '../../ActionType';

// eslint-disable-next-line import/no-anonymous-default-export
const FetchingTimingByMasjidReducer =  (masjidTimings = [] , action) => {
  switch (action.type) {

    case GET_TIMINGS_MASJID_ID:

      return action.payload;

    default:
      return masjidTimings;
  }
};

export default FetchingTimingByMasjidReducer;