import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from './Pages/AuthPages/Login/Login.jsx';
import Register from './Pages/AuthPages/Register/Register.jsx';
import ForgotPassword from './Pages/AuthPages/ForgotPassword/ForgotPassword.jsx';
import ResetPassword from './Pages/AuthPages/ResetPassword/ResetPassword.jsx';
import ResetPasswordInitial from './Pages/AuthPages/ResetPasswordInitial/ResetPasswordInitial.jsx';
import Dashboard from './Pages/Dashboard/Dashboard.jsx';
import Snackbar from './Components/SnackbarComponents/Snackbar.js';
import  FreezeMasjids from './Pages/DetailPages/FreezeMasjids/FreezeMasjids.jsx';
import  StaleMasjids from './Pages/DetailPages/StaleMasjids/StaleMasjids.jsx';
import  FreezeAdmins from './Pages/DetailPages/FreezeAdmins/FreezeAdmins.jsx';
import Masjids from './Pages/DetailPages/Masjids/Masjids.jsx';
import Announcement from './Pages/DetailPages/Announcement/Announcement.jsx';
import Admins from './Pages/DetailPages/Admins/Admins.jsx';
import MasjidProfile from './Pages/ProfilePages/MasjidProfile/MasjidProfile.jsx';
import EventProfile from './Pages/ProfilePages/EventProfile/EventProfile.jsx';
import AdminProfile from './Pages/ProfilePages/AdminProfile/AdminProfile.jsx';
// import EditMasjid from './Pages/EditPages/EditMasjid/EditMasjid.jsx';
// import EditEvent from './Pages/EditPages/EditEvent/EditEvent.jsx';
import { MasjidInputs } from "./formSource";             





function App() {

  const admin = JSON.parse(localStorage.getItem("admin"));
  
  return (
    <>
    <Snackbar/>
     <BrowserRouter>
      <Routes>
        <Route path="/login" element={admin ? (admin.isVerified ? <Navigate to="/"/> : <Login />): <Login />}/>
        <Route path="/forgotpassword" element={admin ? (admin.isVerified ? <Navigate to="/"/> : <ForgotPassword /> ): <ForgotPassword /> }/>
        <Route path="/reset-password/:token" element={admin ? (admin.isVerified ?  <Navigate to="/"/> : <ResetPassword /> ): <ResetPassword /> }/>
        <Route path="/account/initial" element={admin ? (admin.isVerified ?  <Navigate to="/"/> : <ResetPasswordInitial /> ): <ResetPasswordInitial /> }/>
        {/* <Route path="/register" element={admin.isVerified? <Navigate  to="/"/> :<Register/>} /> */}

        <Route path="/" element={admin ? (admin.isVerified ? <Dashboard admin={admin}/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/masjids" element={admin ? (admin.isVerified ? <Masjids/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/announcements" element={admin ? (admin.isVerified ?<Announcement/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/staleMasjids" element={admin ? (admin.isVerified ? <StaleMasjids/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/freezeMasjids" element={admin ? (admin.isVerified ? <FreezeMasjids/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/admins" element={admin ? (admin.isVerified ? <Admins/>: <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/freezeAdmins" element={admin ? (admin.isVerified ? <FreezeAdmins/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/masjid/:masjidName" element={admin ? (admin.isVerified ?<MasjidProfile /> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/events/:eventName" element={admin ? (admin.isVerified ?<EventProfile/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
        <Route path="/admins/:adminName" element={admin ? (admin.isVerified ?<AdminProfile/> : <Navigate to="/login"/>):<Navigate to="/login"/>} />
      </Routes>
    </BrowserRouter>
  </>
  );
}

export default App;
